export const content = {
  h1: {
    en: "Mission",
    ar: "مهمة",
    ku: "ئەرک",
  },
  h2: {
    en: "We solve Iraq's decades long electricity problem by making high quality solar solutions accessible.",
    ar: "نحن نحل مشكلة الكهرباء في العراق منذ عقود بجودة عالية حلول الطاقة الشمسية يمكن الوصول إليها.",
    ku: "ئێمە کێشەی کارەبای دەیان ساڵەی عێراق بە دەستڕاگەیشتن بە گیراوەی خۆر بە کوالێتی بەرز چارەسەر دەکەین.",
  },
};
