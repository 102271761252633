import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../config";
import {
  setAvgDailyOutputPerPanel,
  setDaySizeAmperes,
  setDaySizeHours,
  setNightSizeAmperes,
  setNightSizeHours,
  setResidentialPanel,
  setSystem,
} from "../slices/cpq/residential";

// Create the middleware instance and methods
export const residentialSystemTrigger = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
residentialSystemTrigger.startListening({
  matcher: isAnyOf(
    setResidentialPanel,
    setDaySizeAmperes,
    setDaySizeHours,
    setNightSizeAmperes,
    setNightSizeHours,
    setAvgDailyOutputPerPanel
  ),
  effect: (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    const state = listenerApi.getState() as RootState;

    const supplyVoltage = state.location.data?.supplyVoltage;
    const panel = state.cpq.residential.config.panel;
    const daySize = state.cpq.residential.config.daySize;
    const nightSize = state.cpq.residential.config.nightSize;
    const avgDailyOutputPerPanel = state.cpq.residential.avgDailyOutputPerPanel;

    if (supplyVoltage && panel && avgDailyOutputPerPanel) {
      const panelWatt = panel.maxPowerWattage;

      // Array Size
      const panelQty =
        (daySize.amperes * daySize.hours +
          nightSize.amperes * nightSize.hours) /
        avgDailyOutputPerPanel;
      const arraySizeW = panelQty * panelWatt;

      // ESS Size
      const batterySizeWH = nightSize.amperes * nightSize.hours * supplyVoltage;

      // First panel in the offering list
      const system = {
        panelWatt: panel.maxPowerWattage,
        panelQty: panelQty,
        arraySizeW: arraySizeW,
        batterySizeWH: batterySizeWH,
      };

      listenerApi.dispatch(setSystem({ system }));
    }
  },
});
