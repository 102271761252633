import { useRef, useState } from "react";

export const InputText = ({
  label,
  value,
  onChange,
  onSubmit,
  className,
}: {
  label: string;
  value: string;
  onChange: (val: string) => void;
  onSubmit?: (val: string) => void;
  className?: string;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!inputRef.current?.value) return;
    if (e.key === "Enter") {
      onChange(inputRef.current.value);
      if (onSubmit) onSubmit(inputRef.current.value);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!inputRef.current?.value) return;
    onChange(inputRef.current.value);
  };

  const handleFocus = () => {
    inputRef.current?.focus();
  };

  return (
    <div
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onClick={handleFocus}
      className={`py-2 px-3 flex flex-col rounded-xl outline  ${
        focus
          ? "ring-[6px] outline-2 outline-blue-600"
          : "outline-[1.5px] outline-gray-200 hover:outline-gray-300"
      } ${className}`}
    >
      <label className="text-xs font-normal text-slate-500">{label}</label>
      <input
        ref={inputRef}
        defaultValue={value}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        type="text"
        className="outline-none"
      />
    </div>
  );
};
