import { Combobox as ComboboxHUI } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useState } from "react";

export const Combobox = ({
  label,
  data,
  selected,
  onChange,
  className,
}: {
  label: string;
  data: { name: string }[];
  selected: string;
  onChange: (val: string) => void;
  className: string;
}) => {
  const [query, setQuery] = useState("");

  const handleChange = (val: string) => {
    if (onChange) onChange(val);
  };

  const filteredData =
    query === ""
      ? data
      : data.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div
      className={`${className} ring-1 focus-within:ring-2 ring-gray-400 hover:ring-gray-500 
    focus-within:hover:ring-blue-600 focus-within:ring-blue-600 rounded-xl`}
    >
      <ComboboxHUI value={selected} onChange={handleChange}>
        {({ open }) => (
          <div className="flex flex-grow focus-within:ring-[6px] rounded-xl">
            <div
              className={`w-full flex py-2 px-3 ltr:pr-0 rtl:pl-0 text-gray-900 bg-white rounded-xl`}
            >
              <div className="flex flex-col flex-grow">
                <ComboboxHUI.Label className="text-xs font-normal text-slate-500 relative">
                  {label}
                </ComboboxHUI.Label>
                <ComboboxHUI.Input
                  onChange={(event) => setQuery(event.target.value)}
                  displayValue={(item: any) => item}
                  type="search"
                  className="flex-grow text-sm capitalize border-none outline-none relative bg-transparent focus:bg-transparent"
                />
              </div>
              <ComboboxHUI.Button className="inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </ComboboxHUI.Button>
            </div>
            <ComboboxHUI.Options className="absolute z-10 w-full max-h-56 mt-1 top-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredData.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-slate-700">
                  Nothing found.
                </div>
              ) : (
                filteredData.map((item, cIdx) => (
                  <ComboboxHUI.Option
                    key={cIdx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-blue-100 text-blue-900" : "text-slate-900"
                      }`
                    }
                    value={item.name}
                  >
                    {({ selected }) => (
                      <>
                        <span className="block truncate capitalize">
                          {item.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ComboboxHUI.Option>
                ))
              )}
            </ComboboxHUI.Options>
          </div>
        )}
      </ComboboxHUI>
    </div>
  );
};
