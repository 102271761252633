import { useState } from "react";

type Props = {
  title: string;
  subtitle: string;
  content: string;
  img1920: string;
  img1080: string;
  img720: string;
  imgAlt: string;
  href: string;
  className?: string;
  contentClassName?: string;
};

export const SolutionsCard: React.FC<Props> = ({
  title = "",
  subtitle = "",
  content = "",
  img1920 = "",
  img1080 = "",
  img720 = "",
  imgAlt = "",
  href = "",
  className = "",
  contentClassName = "",
}) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={`${className} bg-white shadow-sm rounded-2xl overflow-hidden group`}
    >
      <div className="aspect-video overflow-hidden relative">
        <picture className={`w-full h-full object-cover`}>
          <source srcSet={img720} media="(max-width: 720px)" />
          <source srcSet={img1080} media="(min-width: 720px)" />
          <source srcSet={img1920} media="(min-width: 1080px)" />
          <img
            className="w-full h-full object-cover group-hover:scale-105 transition-all duration-200"
            alt={imgAlt}
          />
        </picture>

        <div
          className={`opacity-0 group-hover:opacity-5 absolute bg-black top-0 left-0 right-0 bottom-0 transition-all duration-200`}
        />
      </div>

      <div className="p-6">
        <p className="mb-1 uppercase text-xs font-bold ltr:tracking-wider text-gray-500">
          {title}
        </p>
        <p
          className={`${contentClassName} md:text-xl font-semibold md:font-bold`}
        >
          {content}
        </p>
        <p className="mt-3 text-sm font-bold ltr:tracking-wider text-gray-500">
          {subtitle}
        </p>
      </div>
    </a>
  );
};
