import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import { About } from "../pages/about";
import { Index } from "../pages/index";
import { Support } from "../pages/support";
import { Services } from "../pages/services";
import { Products } from "../pages/products";
import { CPQResidential } from "../pages/cpq/residential";
import { CPQCommercial } from "../pages/cpq/commercial";
import { CPQCommercialLoader } from "../pages/cpq/commercial/loader";
import { CPQResidentialLoader } from "../pages/cpq/residential/loader";
import { Proposal } from "../pages/proposal";
export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/:lang/" element={<Index />} />
      <Route path="/:lang/products" element={<Products />} />
      <Route path="/:lang/services" element={<Services />} />
      <Route path="/:lang/about" element={<About />} />
      <Route path="/:lang/support" element={<Support />} />
      <Route path="/:lang/cpq/commercial" element={<CPQCommercial />} />
      <Route path="/:lang/cpq/residential" element={<CPQResidential />} />
      <Route
        path="/:lang/cpq/commercial/:uuid"
        element={<CPQCommercialLoader />}
      />
      <Route
        path="/:lang/cpq/residential/:uuid"
        element={<CPQResidentialLoader />}
      />
      <Route path="/:lang/proposal/:uuid" element={<Proposal />} />
      <Route path="*" element={<Navigate to="/en" replace />} />
    </>
  )
);
