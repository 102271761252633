export const content = {
  chat: {
    en: "Get support",
    ar: "احصل على الدعم",
    ku: "پشتگیرى وەرگره",
  },
  deliveryTime: {
    en: "1-2 weeks",
    ar: "من أسبوع إلى أسبوعين",
    ku: "1-2 هەفتە",
  },
  deliveryText: {
    en: " delivery (free)",
    ar: "توصيل (مجاني) ",
    ku: "گەیاندن (بەخۆڕایی) ",
  },
  coupon: {
    label: { en: "Use coupon", ar: "استخدم قسيمة", ku: "کووپن بەکاربهێنە" },
    title: {
      en: "Enter coupon code",
      ar: "أدخل رمز القسيمة",
      ku: "کۆدی کووپنەکە داخڵ بکە",
    },
    inputLabel: { en: "Coupon Code", ar: "رمز الكوبون", ku: "کۆدی کووپن" },
    button: {
      en: "Get discount",
      ar: "احصل على خصم",
      ku: "داشکاندن بەدەست بهێنە",
    },
    messages: {
      approved: {
        en: "Coupon Approved",
        ar: "تمت الموافقة على القسيمة",
        ku: "کووپن پەسەند کراوە",
      },
      empty: {
        en: "No Coupon Provided",
        ar: "لم يتم تقديم قسيمة",
        ku: "هیچ کووپنێک دابین نەکراوە",
      },
    },
  },
};
