export const content = {
  title: {
    en: "Why TopTaqa",
    ar: "لماذا نحن",
    ku: "بۆ ێما هەڵبژێریت",
  },
  qualityTitle: {
    en: "Top quality",
    ar: "أعلى جودة",
    ku: "کوالێتی بەرز",
  },
  qualityDescription: {
    en: "We provide the highest quality products and services to ensure that your solar system runs for a long time to come.",
    ar: "نحن نقدم منتجات وخدمات عالية الجودة لضمان تشغيل نظامك الشمسي لفترة طويلة قادمة.",
    ku: "ئێمە بەرزترین کوالێتی بەرهەم و خزمەتگوزاری پێشکەش دەکەین بۆ ئەوەی دڵنیا بین لەوەی کە سیستەمی خۆرەکەت بۆ ماوەیەکی زۆر کاردەکات.",
  },
  customizationTitle: {
    en: "Customization",
    ar: "الدقة",
    ku: "دانان",
  },
  customizationDescription: {
    en: "Our solar experts helps you to customize a solution that perfectly matches your specific needs.",
    ar: "يساعدك خبراؤنا في مجال الطاقة الشمسية على اختيار حل يتوافق تماماً مع احتياجاتك الخاصة.",
    ku: "شارەزایانمان لە وزەی خۆردا یارمەتیت دەدەن بۆ ئەوەی چارەسەرێک بە خواستی خۆت دابنێیت کە بە تەواوی لەگەڵ پێداویستییە تایبەتەکانتدا بگونجێت.",
  },
  customerTitle: {
    en: "Customer first",
    ar: "العميل أولا",
    ku: "کريار پئش هە مووشتئکە",
  },
  customerDescription: {
    en: "We're not satisfied until our customers are satisfied. Customer satisfaction is a top priority for us.",
    ar: "نحن غير راضي حتى يرضى عملاؤنا. إرضاء العملاء هو أولوية قصوى بالنسبة لنا.",
    ku: "ئێمە ڕازی نین تا کڕیارەکانمان ڕازی نەبن.  ڕازیبوونی کڕیار لە پێشینەی کارەکانی ئێمەیە.",
  },
};
