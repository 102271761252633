import { useSelector } from "react-redux";
import wNumb from "wnumb";
import { RootState } from "../../../../store/config";
import { content } from "../content";

export const SummaryVisualSection = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  const panel = useSelector(
    (state: RootState) => state.cpq.residential.config.panel
  );

  const system = useSelector(
    (state: RootState) => state.cpq.residential.config.system
  );

  const daySize = useSelector(
    (state: RootState) => state.cpq.residential.config.daySize
  );

  const nightSize = useSelector(
    (state: RootState) => state.cpq.residential.config.nightSize
  );

  const price = useSelector(
    (state: RootState) => state.cpq.residential.config.price
  );

  const formatter_wh = wNumb({ suffix: " WH", thousand: ",", decimals: 0 });
  const formatter_usd = wNumb({ prefix: "$", thousand: ",", decimals: 0 });
  const formatter_pcs = wNumb({ suffix: " pcs", thousand: ",", decimals: 0 });
  return (
    <div className="md:flex font-numbers">
      <div className="md:flex-1 ltr:md:pr-20 rtl:md:pl-20">
        <div className="px-4 py-6 md:p-20 bg-gray-50 rounded-2xl flex justify-center">
          <div className="w-full max-w-md h-full">
            <div className="py-2">
              <div className="flex justify-between font-semibold">
                <p>{content.summary.system.panels[locale]}</p>
                <p>{formatter_usd.to(price.array)}</p>
              </div>
              <div className="pl-2 mt-1.5 w-full flex justify-between text-sm text-slate-500">
                <p>{content.summary.system.quantity[locale]}</p>
                <p>{formatter_pcs.to(system.panelQty)}</p>
              </div>
            </div>
            <div className="py-2">
              <div className="flex justify-between font-semibold">
                <p>{content.summary.system.ess[locale]}</p>
                <p>{formatter_usd.to(price.ess)}</p>
              </div>
              <div className="pl-2 mt-1.5 w-full flex justify-between text-sm text-slate-500">
                <p>{content.summary.system.battery[locale]}</p>
                <p>{formatter_wh.to(system.batterySizeWH)}</p>
              </div>
            </div>
            <div className="py-2">
              <div className="flex justify-between font-semibold">
                <p>{content.summary.system.inverter[locale]}</p>
                <p>{formatter_usd.to(price.inverter)}</p>
              </div>
            </div>
            <div className="py-2">
              <div className="flex justify-between font-semibold">
                <p>{content.summary.system.installation[locale]}</p>
                <p>{content.summary.system.free[locale]}</p>
              </div>
            </div>
            <div className="pt-2 flex justify-between font-bold border-t">
              <div className="">
                <p>{content.summary.system.total[locale]}</p>
              </div>
              <p className="">{formatter_usd.to(price.total)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 md:mt-0 px-4 py-6 md:p-20 bg-gray-50 flex-1 rounded-2xl max-w-sm ">
        <p className="text-xl">{content.summary.selection.title[locale]}</p>
        <hr className="my-3" />
        <div className="flex justify-between">
          <p className="font-medium">{panel?.title}</p>
          <p className="text-sm text-slate-600">
            {content.summary.selection.panel[locale]}
          </p>
        </div>
        <hr className="my-3" />
        <div className="flex justify-between">
          <p className="font-medium">{`${daySize.amperes} A`}</p>
          <p className="text-sm text-slate-600">
            {content.summary.selection.daytime[locale]}
          </p>
        </div>
        <hr className="my-3" />
        <div className="flex justify-between">
          <p className="font-medium">{`${nightSize.amperes} A`}</p>
          <p className="text-sm text-slate-600">
            {content.summary.selection.nighttime[locale]}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="font-medium">{`${nightSize.hours} hr/day`}</p>
          <p className="text-sm text-slate-600">
            {content.summary.selection.nighttime[locale]}
          </p>
        </div>
      </div>
    </div>
  );
};
