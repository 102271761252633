import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../config";
import { fetchLocation, setCity, setCountry } from "../slices/location";

// Create the middleware instance and methods
export const locationChangeListener = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
locationChangeListener.startListening({
  matcher: isAnyOf(setCity, setCountry),
  effect: async (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    const state = listenerApi.getState() as RootState;

    const params = {
      country: state.location.selectedCountry,
      city: state.location.selectedCity,
    };

    await listenerApi.dispatch(fetchLocation(params));
  },
});
