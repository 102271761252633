import { useRef, useEffect, useState } from "react";
import throttle from "lodash.throttle";

/**
 * Check if an element is in viewport

 * @param {number} offset - Number of pixels up to the observable element from the top
 * @param {number} throttleMilliseconds - Throttle observable listener, in ms
 */
export default function useVisibility<Element extends HTMLElement>(
  offset: number = 0,
  throttleMilliseconds: number = 100
): [Boolean, React.RefObject<Element>] {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<Element>(null);

  const onScroll = throttle(() => {
    if (!ref.current) {
      setIsVisible(false);
      return;
    }
    const top = ref.current.getBoundingClientRect().top;
    const elementHeight = ref.current.clientHeight;
    setIsVisible(
      top + offset + elementHeight >= 0 && top - offset <= window.innerHeight
    );
  }, throttleMilliseconds);

  useEffect(() => {
    document.addEventListener("scroll", onScroll, true);
    return () => document.removeEventListener("scroll", onScroll, true);
  });

  return [isVisible, ref];
}
