import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const ProductsPanels = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="bg-gray-100 py-24">
      <div className="mx-6 md:mx-auto max-w-screen-lg">
        <h1 className="uppercase ltr:tracking-widest mb-4 text-center">
          {content.h1[locale]}
        </h1>
        <h2 className="text-3xl font-light ltr:tracking-widest mb-3 text-center">
          {content.h2[locale]}
        </h2>
        <div className="mt-16 flex flex-col-reverse md:flex-row gap-6">
          <div className="flex-1 bg-white rounded-2xl shadow-sm">
            <div className="px-12 py-6 border-b-2 border-gray-100">
              <div
                className="flex justify-between text-xl font-semibold ltr:tracking-widest"
                style={{ direction: "ltr" }}
              >
                <p>Tiger Neo</p>
                <p>565W</p>
              </div>
              <p className="mt-2">{content.heroDescription[locale]}</p>
            </div>
            <div className="px-12 py-6 border-b-2 border-gray-100 ">
              <p className="mb-3 font-semibold">
                {content.featureTitle[locale]}
              </p>
              <p className="mb-3">{content.featureDescription[locale]}</p>
              <ul className="list-disc list-inside">
                <li>{content.featureBullet1[locale]}</li>
                <li>{content.featureBullet2[locale]}</li>
                <li>{content.featureBullet3[locale]}</li>
                <li>{content.featureBullet4[locale]}</li>
                <li>{content.featureBullet5[locale]}</li>
              </ul>
            </div>
            <div className="px-12 py-6">
              <a
                href="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/documents%2FJKM550-570N-72HL4-BDV-F1-EN.pdf?alt=media&token=3f2bcf84-dfb8-4c5c-87c6-39371bdaaabe"
                target="_blank"
                rel="noreferrer"
                className="mt-4 w-full flex items-center justify-center bg-gray-800 hover:bg-amber-600 transition-all text-white py-3 px-4 rounded"
              >
                {content.button[locale]}
              </a>
            </div>
          </div>
          <div className="flex-1">
            <img
              alt="Tiger Neo Solar Panel"
              className="aspect-square object-contain"
              src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Ftiger-neo.png?alt=media&token=e3653f68-17e4-46fd-ba83-ca22a6b6aaf6"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
