type Props = {
  size: number;
  className?: string;
};

export const ToolsIcon = ({ size, className }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 64 64">
      <g>
        <path d="M60.78,53.31c1.66-7.05-5.56-13.48-12.36-11.1L35.06,28.85l11-12.5c.55.09,1.31.27,1.67-.31l5.79-8.23a1,1,0,0,0-.09-1.27L51,4a1,1,0,0,0-1.39-.05l-7.32,6.65c-.53.41-.24,1.24-.17,1.78l-11,12.51-9.28-9.28c2.35-6.79-4-13.91-11-12.4a1,1,0,0,0-.79.88,1,1,0,0,0,.28.81l4,4L13.1,13.1,8.83,14.24l-4-4a1,1,0,0,0-1.69.51c-1.55,7,5.7,13.41,12.42,11l9.66,9.66-2.45,2.77c-.31-.33-.69-.84-1.21-.8a1,1,0,0,0-.73.34l-1.55,1.77-.86-.89a1,1,0,0,0-1.47,0L4.82,48.5a7.56,7.56,0,0,0-1.21,7.89c1.65,4.42,7.81,5.71,10.88,2L26.66,44.55c.79-.92-.46-1.75-1-2.37l1.67-1.9c.65-.76-.06-1.43-.61-2l2.53-2.86,13,13c-2.36,6.79,4,13.91,11,12.4A1,1,0,0,0,54,60a1,1,0,0,0-.28-.82l-4-4L50.9,50.9l4.27-1.14,4,4a1.29,1.29,0,0,0,.71.3h0A1,1,0,0,0,60.78,53.31ZM50.22,6.06l1.22,1.27-5,7.06-2-.31L44,11.69ZM42.53,14.9A1.12,1.12,0,0,0,43.72,16L33.64,27.43l-1.15-1.15ZM6.32,49.82l11.45-13,.85.89h0l.31.31L5,53.81A5.44,5.44,0,0,1,6.32,49.82Zm-.63,6.26,14.62-16.6L22,41.26,7.35,57.89A4.59,4.59,0,0,1,5.69,56.08ZM13,57a4.29,4.29,0,0,1-3.57,1.52l14-15.85,1.15,1.2Zm9.84-17.82L20.7,37l.95-1.08.45.46c.44.49,2.73,2.85,3.12,3.27l-.95,1.08Zm2.5-2.35L24.2,35.69l2.48-2.8L27.83,34ZM55.21,47.68l-5.38,1.44a1,1,0,0,0-.71.71l-1.44,5.38a1,1,0,0,0,.26,1L50.73,59a7.67,7.67,0,0,1-6.46-10.44.83.83,0,0,0,0-.56.89.89,0,0,0-.26-.53L16.57,20a1,1,0,0,0-.54-.26.87.87,0,0,0-.55,0A7.67,7.67,0,0,1,5,13.27l2.78,2.79a1,1,0,0,0,.71.29,1.15,1.15,0,0,0,.26,0l5.38-1.44a1,1,0,0,0,.71-.71l1.44-5.38a1,1,0,0,0-.26-1L13.27,5a7.68,7.68,0,0,1,6.46,10.44.89.89,0,0,0,0,.58.78.78,0,0,0,.25.51L47.43,44.05a.86.86,0,0,0,.52.26.93.93,0,0,0,.57,0A7.67,7.67,0,0,1,59,50.73l-2.78-2.79A1,1,0,0,0,55.21,47.68Z" />
      </g>
    </svg>
  );
};
