import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const AboutPartner = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="bg-gray-100 py-24">
      <div className="mx-6 md:mx-auto max-w-screen-lg">
        <h1 className="uppercase ltr:tracking-widest mb-4 text-center">
          {content.h1[locale]}
        </h1>
        <h2 className="text-3xl font-light ltr:tracking-widest mb-3 text-center">
          {content.h2[locale]}
        </h2>
        <div className="mt-16 md:flex gap-6">
          <div className="flex-1 p-12 bg-white rounded-2xl shadow-sm">
            <h2 className="text-3xl font-light ltr:tracking-widest mb-3">
              {content.card1Title[locale]}
            </h2>
            <p className="font-light">{content.card1Description[locale]}</p>
            <a
              href="mailto:resell@toptaqa.com"
              className="mt-4 w-fit flex items-center justify-center bg-gray-800 hover:bg-amber-600 transition-all text-white py-3 px-4 rounded-full"
            >
              {content.card1Button[locale]}
              <BsArrowRight className="ml-2 rtl:hidden" size={20} />
              <BsArrowLeft className="mr-2 ltr:hidden" size={20} />
            </a>
          </div>
          <div className="flex-1 p-12 mt-6 md:mt-0 bg-white rounded-2xl shadow-sm">
            <h2 className="text-3xl font-light ltr:tracking-widest mb-3">
              {content.card2Title[locale]}
            </h2>
            <p className="font-light">{content.card2Description[locale]}</p>
            <a
              href="mailto:partnership@toptaqa.com"
              className="mt-4 w-fit flex items-center justify-center bg-gray-800 hover:bg-amber-600 transition-all text-white py-3 px-4 rounded-full"
            >
              {content.card2Button[locale]}
              <BsArrowRight className="ml-2 rtl:hidden" size={20} />
              <BsArrowLeft className="mr-2 ltr:hidden" size={20} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
