export const content = {
  h1: {
    en: "Connect with us",
    ar: "اتصل بنا",
    ku: "پەیوەنديمان پئوەبکە",
  },
  h2: {
    en: "Follow the journey of spreading sustainable energy in Iraq",
    ar: "تابع رحلة نشر الطاقة المستدامة في العراق",
    ku: "پەیڕەوی لە گەشتی بڵاوکردنەوەی وزەی بەردەوام لە عێراق بکەن",
  },
};
