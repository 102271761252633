import { useSelector } from "react-redux";
import { RootState } from "../../../../store/config";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { content } from "../content";

export const DaySizeVisualSection = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  const { amperes } = useSelector(
    (state: RootState) => state.cpq.residential.config.daySize
  );

  let src = "";
  switch (amperes) {
    case 1:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F230W.webp?alt=media&token=e956b993-7606-47b9-a4bd-12d4f74655ed";
      break;
    case 2:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F460W.webp?alt=media&token=28f0aa52-c110-4811-8d11-832dba45e679";
      break;
    case 3:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F920W.webp?alt=media&token=1da52875-236d-4e39-bf1f-d2a962de2f84";
      break;
    case 4:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F920W.webp?alt=media&token=1da52875-236d-4e39-bf1f-d2a962de2f84";
      break;
    case 5:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F920W.webp?alt=media&token=1da52875-236d-4e39-bf1f-d2a962de2f84";
      break;
    case 6:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F1150W.webp?alt=media&token=5c120eb9-5917-49e5-98a1-f9d74aa42987";
      break;
    case 7:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F1380W.webp?alt=media&token=6c35e67a-482a-4547-ab36-d98bfc44ba8c";
      break;
    case 8:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F1610W.webp?alt=media&token=09caff27-1521-49cd-8dda-6a4fe6da416b";
      break;
    case 9:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F1840W.webp?alt=media&token=16c15c5b-ded3-4e9f-bba9-8bd10462aa43";
      break;
    case 10:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F2070W.webp?alt=media&token=3aa54dcb-b75c-460d-a83b-a63d744ce068";
      break;
    case 11:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F2300W.webp?alt=media&token=25734a91-32a0-4164-a0f5-91d490247fd4";
      break;
    case 12:
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F2530W.webp?alt=media&token=13c4b368-ff1f-4eaf-9a5d-f8cba14a2c3d";
      break;
    default:
      break;
  }

  if (amperes >= 35) {
    src =
      "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F6900W%2B.webp?alt=media&token=9b4a486e-5e00-40b2-af5f-66d631f87faa";
  } else if (amperes >= 30) {
    src =
      "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F5750W%2B.webp?alt=media&token=69a5ddae-0aeb-4750-8d7b-43c6287ed0d6";
  } else if (amperes >= 25) {
    src =
      "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F4600W%2B.webp?alt=media&token=1d9d0da5-6a04-4fc4-a627-9282c77d5c06";
  } else if (amperes >= 20) {
    src =
      "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F3450W%2B.webp?alt=media&token=cb0d859c-cdb4-48a8-895c-5efc3b618a35";
  } else if (amperes >= 15) {
    src =
      "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F2980W%2B.webp?alt=media&token=5d7f9e7e-a366-47f2-bdca-1a59cad06937";
  } else if (amperes >= 13) {
    src =
      "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconsumption-levels%2F2760W.webp?alt=media&token=af8794e7-691f-4480-b9ef-c3520ebc7c5b";
  }

  return (
    <div className="w-full aspect-video min-h-[246px] max-w-full md:h-full md:aspect-auto relative bg-gray-50 rounded-2xl overflow-hidden shadow-sm">
      <div className="w-full h-full absolute">
        <Swiper
          pagination
          navigation
          className="h-full w-full rounded-2xl"
          modules={[Pagination, Navigation]}
          slidesPerView={1}
          allowTouchMove={false}
        >
          <SwiperSlide className="bg-gray-50">
            <img
              src={src}
              className="w-full h-full rounded-2xl object-contain bg-gray-50"
              alt="Appliances"
            />
            <div className="absolute p-2 md:p-4 flex justify-center items-center rounded left-6 bottom-10 right-6 text-xs md:text-sm text-white font-medium bg-black/30 backdrop-blur-xl max-w-md mx-auto">
              <p className="ml-2 capitalize">{`${amperes} ${content.daySize.labels.ampere[locale]}`}</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
