import * as nouislider from "nouislider";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/config";
import { setModuleOrientation } from "../../../../store/slices/cpq/commercial";
import { Compass } from "../../../../components/Compass";
import { InfoModal } from "../../../../components/InfoModal";
import { Slider } from "../../../../components/Slider";
import { content } from "../content";

export const OrientationInputSection = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { locale, direction } = useSelector(
    (state: RootState) => state.localization
  );

  const moduleOrientation = useSelector(
    (state: RootState) => state.cpq.commercial.config.moduleOrientation
  );
  const onOrientationUpdate = (values: (string | number)[]) => {
    const updatedVal = Number(values[0]);

    dispatch(setModuleOrientation({ orientation: updatedVal }));
  };

  const onOrientationEnd = (values: (string | number)[]) => {
    const updatedVal = Number(values[0]);

    dispatch(setModuleOrientation({ orientation: updatedVal }));
  };

  return (
    <>
      <div className="w-full aspect-video min-h-[246px] bg-gray-50 rounded-xl shadow-sm text-sm relative flex justify-center items-center">
        <Compass
          direction={moduleOrientation}
          className="w-1/2 aspect-square"
        />
      </div>
      <div className="mt-6 px-4 py-6 bg-gray-50 rounded-xl overflow-hidden">
        {direction === "ltr" && (
          <Slider
            label={content.orientation.labels.input[locale]}
            value={moduleOrientation}
            onEnd={onOrientationEnd}
            onUpdate={onOrientationUpdate}
            pipsMode={nouislider.PipsMode.Steps}
            options={{
              connect: "lower",
              direction: "ltr",
              start: moduleOrientation,
              step: 45,
              range: {
                min: [0],
                max: [360],
              },
            }}
            suffix="°"
          />
        )}
        {direction === "rtl" && (
          <Slider
            label={content.orientation.labels.input[locale]}
            value={moduleOrientation}
            onEnd={onOrientationEnd}
            onUpdate={onOrientationUpdate}
            pipsMode={nouislider.PipsMode.Steps}
            options={{
              connect: "lower",
              direction: "rtl",
              start: moduleOrientation,
              step: 45,
              range: {
                min: [0],
                max: [360],
              },
            }}
            suffix="°"
          />
        )}
      </div>
      <InfoModal
        linkText={content.orientation.modal.labelBold[locale]}
        description={content.orientation.modal.labelRegular[locale]}
        title={content.orientation.modal.title[locale]}
        boxClassName="mt-5"
        img="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fassets%2Fcompass.webp?alt=media&token=b4d2b51a-712e-40ac-b409-0fecd349c287"
        type="box"
      >
        <div className="p-6 md:px-20 md:pb-20">
          <p>{content.orientation.modal.p[0][locale]}</p>
          <p className="mt-3">{content.orientation.modal.p[1][locale]}</p>
          <p className="mt-3">{content.orientation.modal.p[2][locale]}</p>
        </div>
      </InfoModal>
    </>
  );
};
