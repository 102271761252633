import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const Partners = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="py-24">
      <div className="mx-6 md:mx-16 lg:mx-auto max-w-screen-lg">
        <h2 className="text-3xl font-light ltr:tracking-widest mb-3 text-center">
          {content.title[locale]}
        </h2>
        <div>
          <div className="flex gap-6">
            <img
              className="w-32 object-contain"
              alt="Jinko Solar"
              src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fjinko-solar-logo-vector.png?alt=media&token=3323071d-efce-4ac5-bb0b-72bbe45b0d83"
            />
          </div>
          <div className="pt-6 flex justify-center gap-6">
            <a
              href="mailto:partnership@toptaqa.com"
              className="py-2 px-6 border-2 border-gray-700 bg-gray-700 text-white rounded text-center"
            >
              <p className="w-52">{content.button[locale]}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
