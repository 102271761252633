export const content = {
  h1: {
    en: "Go Solar Today",
    ar: "اذهب للطاقة الشمسية اليوم",
    ku: "هە رئە مڕۆبگۆڕە بە وزەی خۆر",
  },
  h2: {
    en: "Harness the power of the sun to generate electricity",
    ar: "تسخير طاقة الشمس لتوليد الكهرباء",
    ku: "هێزی خۆر بەکاربهێنە بۆ بەرهەمهێنانی کارەبا",
  },
  residentialTitle: {
    en: "Residential Solutions",
    ar: "حلول سكنية",
    ku: "چارەسەری نیشتەجێبوون",
  },
  residentialSubtitle: {
    en: "Customize your solution now",
    ar: "انظر كيف يمكنك ان توفر الكثير",
    ku: "ئێستا چارەسەرەکەت بە خواستی خۆت دابنێ",
  },
  residentialContent: {
    en: "Power your home with your own solar system. Minimal maintenance.",
    ar: "زود منزلك بنظامك الشمسي. الحد الأدنى من الصيانة.",
    ku: "بە وزە ی خۆر خۆت کارەبای ماڵەکەت دابين بکە",
  },
  commercialTitle: {
    en: "Commercial Solutions",
    ar: "الحلول التجارية",
    ku: "چارەسەری بازرگانی",
  },
  commercialSubtitle: {
    en: "See how much you can save",
    ar: "تخصيص الحل الخاص بك الآن",
    ku: "بزانە چەند دەتوانیت پاشەکەوت بکەیت",
  },
  commercialContent: {
    en: "Reduce your monthly electricity bill by sending your generated power to the grid.",
    ar: "قلل فاتورة الكهرباء الشهرية عن طريق إرسال الطاقة المولدة إلى الشبكة.",
    ku: "پارەی کارەبای مانگانەت کەم بکەرەوە بە ناردنی کارەبای بەرهەم هێنراوت بۆ تۆڕ.",
  },
  consultingTitle: {
    en: "Consulting",
    ar: "مستشار",
    ku: "ڕاوێژکاری",
  },
  consultingSubtitle: {
    en: "Email Us",
    ar: "ارسل لنا عبر البريد الإلكتروني",
    ku: "نامە ی ئە لکترۆنی بنئرە",
  },
  consultingContent: {
    en: "We support solar projects of all scales, from analysis to commissioning.",
    ar: "نحن ندعم مشاريع الطاقة الشمسية بجميع المقاييس ، من التحليل إلى التشغيل.",
    ku: "ئێمە پشتگیری لە پڕۆژەکانی وزەی خۆر دەکەین بە هەموو قەبارەیەکەوە، لە شیکارییەوە تا بەکارهێنان.",
  },
  electricalTitle: {
    en: "Electrical",
    ar: "الكهرباء",
    ku: "کارەبایی",
  },
  electricalSubtitle: {
    en: "Email Us",
    ar: "ارسل لنا عبر البريد الإلكتروني",
    ku: "نامە ی ئە لکترۆنی بنئرە",
  },
  electricalContent: {
    en: "Our electricians can help you with all electrical work. Solar or otherwise.",
    ar: "يمكن للكهربائيين لدينا مساعدتك في جميع الأعمال الكهربائية. الشمسية أو غير ذلك.",
    ku: "کارەباچیەکانمان دەتوانن هاوکارتان بن لە هەموو کارە کارەباییەکان.  خۆر یان بە شێوەیەکی تر.",
  },
  apiTitle: {
    en: "Data API",
    ar: "البيانات API",
    ku: "داتا API",
  },
  apiSubtitle: {
    en: "Email Us",
    ar: "ارسل لنا عبر البريد الإلكتروني",
    ku: "نامە ی ئە لکترۆنی بنئرە",
  },
  apiContent: {
    en: "Get access to solar data through our simple-to-use API.",
    ar: "احصل على إمكانية الوصول إلى بيانات الطاقة الشمسية من خلال واجهة برمجة التطبيقات سهلة الاستخدام.",
    ku: "داتای وزە ی خۆر بە دەست بئنە بە ئاسانی لەڕئگە ی API",
  },
};
