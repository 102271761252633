import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const AboutStory = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="bg-gray-100 py-24">
      <div className="mx-6 md:mx-auto flex flex-col-reverse md:flex-row gap-6 max-w-screen-lg">
        <div className="flex-1">
          <h1 className="uppercase ltr:tracking-widest mb-4">
            {content.h1[locale]}
          </h1>
          <h2 className="text-3xl font-light ltr:tracking-widest mb-3">
            {content.h2[locale]}
          </h2>
          <p className="font-light">{content.p[locale]}</p>
        </div>
        <div className="flex-1 flex gap-4">
          <img
            className="object-cover aspect-square rounded-2xl shadow-sm"
            style={{ width: "calc(50% - 8px)" }}
            src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fsweden.jpg?alt=media&token=bd3420df-2d36-4316-b23f-e67e332576b1"
            alt="Sweden"
          />
          <img
            className="object-cover aspect-square rounded-2xl shadow-sm"
            style={{ width: "calc(50% - 8px)" }}
            src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Ferbil-center.jpg?alt=media&token=b059d377-7c24-4932-bd6f-4a1e056cf235"
            alt="Iraq"
          />
        </div>
      </div>
    </div>
  );
};
