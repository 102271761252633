import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../../api";
import { ICoupon } from "../../../types/coupon";

interface State {
  code: string;
  data: ICoupon | null;
  api: { pending: boolean; error: any };
}

export const fetchCoupon = createAsyncThunk(
  "coupons/fetchCoupon",
  async (
    param: {
      code: string;
    },
    thunkAPI
  ) => {
    try {
      const data = await api.getCoupon(param.code);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    code: "",
    data: null,
    api: { pending: false, error: null },
  } as State,
  reducers: {
    setCouponCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCoupon.pending, (state, action) => {
      state.api.pending = true;
    });
    builder.addCase(fetchCoupon.fulfilled, (state, action) => {
      state.api.pending = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCoupon.rejected, (state, action) => {
      state.api.pending = false;
      state.api.error = action.payload;
    });
  },
});

export const { setCouponCode } = couponSlice.actions;
