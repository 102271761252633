import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api";
import { IDaily } from "../../../types/power/daily";

export const fetchDaily = createAsyncThunk(
  "daily/fetchDaily",
  async (
    param: {
      locationId: string;
      year: number;
    },
    thunkAPI
  ) => {
    try {
      const data = await api.getDaily(param.locationId, param.year);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

interface State {
  [locationId: string]: {
    data: IDaily[] | null;
    api: { pending: boolean; error: any };
  };
}

export const dailySlice = createSlice({
  name: "dayly",
  initialState: {} as State,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDaily.pending, (state, action) => {
      state[action.meta.arg.locationId] = {
        data: null,
        api: { pending: true, error: null },
      };
    });
    builder.addCase(fetchDaily.fulfilled, (state, action) => {
      state[action.meta.arg.locationId] = {
        data: action.payload,
        api: { pending: false, error: null },
      };
    });
    builder.addCase(fetchDaily.rejected, (state, action) => {
      state[action.meta.arg.locationId] = {
        data: [],
        api: { pending: false, error: action.error },
      };
    });
  },
});
