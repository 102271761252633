import { useSelector } from "react-redux";
import { RootState } from "../../../../store/config";

export const RoofSlopeVisualSection = () => {
  const roofSlope = useSelector(
    (state: RootState) => state.cpq.residential.config.roofSlope
  );

  let src = "";
  if (roofSlope === "flat") {
    src =
      "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fflat-house-modern-day.webp?alt=media&token=038b449a-a33d-46d3-8e5b-d0f16213cef8";
  }

  if (roofSlope === "tilted") {
    src =
      "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Ftilted-house-modern-day.webp?alt=media&token=82b4ee87-9894-43e8-8659-f4c962f6ea57";
  }

  return (
    <img
      alt=""
      src={src}
      className="w-full h-full aspect-video rounded-2xl object-cover shadow"
    />
  );
};
