import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../config";
import {
  setRoofLength,
  setRoofMaxPanels,
  setRoofWidth,
} from "../slices/cpq/commercial";

// Create the middleware instance and methods
export const commercialRoofMaxPanelListener = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
commercialRoofMaxPanelListener.startListening({
  matcher: isAnyOf(setRoofLength, setRoofWidth),
  effect: (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    const state = listenerApi.getState() as RootState;
    const width = state.cpq.commercial.config.roofWidth;
    const length = state.cpq.commercial.config.roofLength;

    // First panel in the offering list
    const maxPerRow = Math.floor(width / 1.3);
    const maxPerCol = Math.floor(length / 2.6);
    const maxTotal = maxPerRow * maxPerCol;

    listenerApi.dispatch(
      setRoofMaxPanels({ row: maxPerRow, col: maxPerCol, total: maxTotal })
    );
  },
});
