type Props = {
  size: number;
  className?: string;
};

export const ChatIcon = ({ size, className }: Props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      viewBox="0 0 50 50"
      width={size}
      height={size}
    >
      <g>
        <path
          d="M49,27c0-2.757-2.243-5-5-5h-2v-3c0-9.374-7.626-17-17-17S8,9.626,8,19v3H6c-2.757,0-5,2.243-5,5v6c0,2.757,2.243,5,5,5h6
     V22h-2v-3c0-8.271,6.729-15,15-15s15,6.729,15,15v3h-2v16h2v5c0,1.654-1.346,3-3,3H27v2h10c2.757,0,5-2.243,5-5v-5h2
     c2.757,0,5-2.243,5-5V27z M10,36H6c-1.654,0-3-1.346-3-3v-6c0-1.654,1.346-3,3-3h4V36z M47,33c0,1.654-1.346,3-3,3h-4V24h4
     c1.654,0,3,1.346,3,3V33z"
        />
      </g>
      <g></g>
    </svg>
  );
};
