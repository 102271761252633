import styled from "styled-components";
import { Listbox } from "@headlessui/react";
import {
  CheckIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
} from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { setCity, setCountry } from "../../../store/slices/location";
import { AppDispatch, RootState } from "../../../store/config";
import { useEffect, useMemo, useState } from "react";
import { fetchSupportedLocations } from "../../../store/slices/meta/supported-locations";
import { useNavigate, useParams } from "react-router-dom";
import { useAnalyticsEventTracker } from "../../../hooks/useAnalyticsEventTracker";
import { content } from "./content";

const Label = styled.p`
  font-size: 0.875rem;
  font-weight: 300;
`;

export const SelectLocation: React.FC = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { lang } = useParams();
  const { locale } = useSelector((state: RootState) => state.localization);

  const tracker = useAnalyticsEventTracker();

  const [segment, setSegment] = useState<"commercial" | "residential">(
    "commercial"
  );
  const navigate = useNavigate();

  const pending = useSelector(
    (state: RootState) => state.meta.supportedLocations.api.pending
  );

  const supportedLocations = useSelector(
    (state: RootState) => state.meta.supportedLocations.data
  );

  useEffect(() => {
    if (pending || supportedLocations) return;
    dispatch(fetchSupportedLocations());
  }, [dispatch, pending, supportedLocations]);

  const selectedCountry = useSelector(
    (state: RootState) => state.location.selectedCountry
  );

  const selectedCity = useSelector(
    (state: RootState) => state.location.selectedCity
  );

  const countries = useMemo(() => {
    if (!supportedLocations) return [];
    return supportedLocations.countries;
  }, [supportedLocations]);

  const cities = useMemo(() => {
    if (!supportedLocations) return [];
    return supportedLocations.cities[selectedCountry];
  }, [selectedCountry, supportedLocations]);

  const handleCountryChange = (country: string) => {
    dispatch(setCountry(country));
  };

  const handleCityChange = (city: string) => {
    dispatch(setCity(city));
  };

  const handleSegmentChange = (value: "commercial" | "residential") => {
    setSegment(value);
  };

  const handleEnterConfigurator = () => {
    tracker("click", "configure", "Web");
    navigate(`/${lang}/cpq/${segment}`);
  };

  return (
    <div
      {...props}
      className="w-full md:w-auto md:flex relative rounded-xl shadow-lg"
    >
      <Listbox value={selectedCountry} onChange={handleCountryChange}>
        <div className="relative">
          <Listbox.Button
            aria-label="Navigate to home solar configurator"
            className="h-full w-full md:w-28 flex flex-col gap-1 px-8 py-4 bg-white hover:bg-gray-100 cursor-pointer rounded-t-xl ltr:md:rounded-tr-none rtl:md:rounded-tl-none ltr:md:rounded-l-xl rtl:md:rounded-r-xl text-left"
          >
            <Label className="text-slate-500 ltr:tracking-wide">
              {content.countryLabel[locale]}
            </Label>
            <span className="block truncate capitalize">{selectedCountry}</span>
          </Listbox.Button>
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {countries.map((country) => (
              <Listbox.Option
                key={country.name}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-blue-100 text-blue-900" : "text-slate-900"
                  }`
                }
                value={country.name}
              >
                {({ selected }) => (
                  <>
                    <span className="block truncate capitalize">
                      {country.name}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
      <Listbox value={selectedCity} onChange={handleCityChange}>
        <div className="relative">
          <Listbox.Button className="h-full w-full md:w-44 flex flex-col gap-1 px-8 py-4 bg-white hover:bg-gray-100 cursor-pointer text-left">
            <Label className="text-slate-500 ltr:tracking-wide">
              {content.cityLabel[locale]}
            </Label>
            <span className="block truncate capitalize">{selectedCity}</span>
          </Listbox.Button>
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {cities.map((city, personIdx) => (
              <Listbox.Option
                key={personIdx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-blue-100 text-blue-900" : "text-slate-900"
                  }`
                }
                value={city.name}
              >
                {({ selected }) => (
                  <>
                    <span className="block truncate capitalize">
                      {city.name}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
      <Listbox value={segment} onChange={handleSegmentChange}>
        <div className="relative">
          <Listbox.Button className="h-full w-full md:w-44 flex flex-col gap-1 px-8 py-4 bg-white hover:bg-gray-100 cursor-pointer text-left">
            <Label className="text-slate-500 ltr:tracking-wide">
              {content.segmentLabel[locale]}
            </Label>
            <span className="block truncate capitalize">{segment}</span>
          </Listbox.Button>
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <Listbox.Option
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? "bg-blue-100 text-blue-900" : "text-slate-900"
                }`
              }
              value={"commercial"}
            >
              {({ selected }) => (
                <>
                  <span className="block truncate capitalize">Commercial</span>
                  {selected ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
            <Listbox.Option
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? "bg-blue-100 text-blue-900" : "text-slate-900"
                }`
              }
              value={"residential"}
            >
              {({ selected }) => (
                <>
                  <span className="block truncate capitalize">Residential</span>
                  {selected ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          </Listbox.Options>
        </div>
      </Listbox>
      <button
        className="w-full md:w-auto px-8 py-4 md:px-4 flex bg-blue-600 hover:bg-blue-500  text-white rounded-b-xl ltr:md:rounded-bl-none rtl:md:rounded-br-none ltr:md:rounded-r-xl rtl:md:rounded-l-xl gap-4 items-center justify-between"
        onClick={handleEnterConfigurator}
      >
        <p className="md:hidden">{content.configureButton[locale]}</p>
        <ArrowRightIcon className="w-6 rtl:hidden" />
        <ArrowLeftIcon className="w-6 ltr:hidden" />
      </button>
    </div>
  );
};
