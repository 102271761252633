import { useEffect } from "react";
import useMidwayTracker from "../../hooks/useMidwayTracker";

type Props = {
  title?: string;
  titleContinued?: string;
  leftChildren: React.ReactNode;
  rightChildren: React.ReactNode;
  stickySide?: "left" | "right";
  onActive?: () => void;
  hideStickyOnMD?: boolean;
  topPositionClass?: string;
  className?: string;
};
export const Sticky2xColumns = ({
  title = "",
  titleContinued = "",
  leftChildren,
  rightChildren,
  stickySide = "left",
  onActive = undefined,
  hideStickyOnMD = false,
  topPositionClass = "top-6",
  className = "",
}: Props) => {
  const [isMidway, ref] = useMidwayTracker<HTMLDivElement>(0, 15);

  useEffect(() => {
    if (isMidway && onActive) {
      onActive();
    }
  }, [isMidway, onActive]);

  return (
    <div
      className={`w-full flex ${
        stickySide === "left" ? "flex-col md:flex-row" : "flex-col"
      } ${className}`}
    >
      <div
        className="flex-1 md:pb-24"
        ref={stickySide === "right" ? ref : undefined}
      >
        <h2 className="px-6 mb-4 text-2xl font-semibold text-slate-900 leading-tight md:hidden">
          <span className="text-slate-900">{title}</span>
          <span className="text-slate-500">{titleContinued}</span>
        </h2>
        <div
          className={`px-6 md:px-0 ${
            stickySide === "left"
              ? `md:sticky md:mx-0 ${topPositionClass} ltr:md:pr-20 rtl:md:pl-20`
              : ""
          } ${hideStickyOnMD && stickySide === "left" && "md:hidden"}`}
        >
          <>
            {stickySide === "right" && (
              <h2 className="mb-4 text-2xl font-semibold text-slate-900 leading-tight hidden md:block">
                <span className="text-slate-900">{title}</span>
                <span className="text-slate-500">{titleContinued}</span>
              </h2>
            )}
            {leftChildren}
          </>
        </div>
      </div>
      <div
        className="w-full max-w-sm pb-48"
        ref={stickySide === "left" ? ref : undefined}
      >
        <div
          className={`px-6 md:px-0 ${
            stickySide === "right"
              ? `md:sticky md:mx-0 ${topPositionClass}`
              : "mt-4"
          }`}
        >
          <>
            {stickySide === "left" && (
              <h2 className="mb-4 text-2xl font-semibold leading-tight hidden md:block">
                <span className="text-slate-900">{title}</span>
                <span className="text-slate-500">{titleContinued}</span>
              </h2>
            )}
            {rightChildren}
          </>
        </div>
      </div>
    </div>
  );
};
