import { useSelector } from "react-redux";
import { ChatIcon } from "../../../assets/icons/chat";
import { CheckIcon } from "../../../assets/icons/check";
import { PaymentIcon } from "../../../assets/icons/payment";
import { SolarSystemIcon } from "../../../assets/icons/solar-system";
import { ToolsIcon } from "../../../assets/icons/tools";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const How = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="py-24">
      <div className="mx-6 md:mx-16 lg:mx-auto">
        <h1 className="uppercase ltr:tracking-widest mb-4 text-center">
          {content.h1[locale]}
        </h1>
        <h2 className="text-3xl font-light ltr:tracking-widest mb-3 text-center">
          {content.h2[locale]}
        </h2>
        <div className="max-w-xl mx-auto mt-16 flex items-center flex-col gap-8">
          <div className="flex gap-6 w-full">
            <div className="flex flex-col items-center">
              <SolarSystemIcon size={48} className="" />
              <hr className="mt-3 h-24 w-0.5 bg-gray-800" />
            </div>
            <div>
              <p className="mb-2 text-2xl">{content.firstStepTitle[locale]}</p>
              <p className="font-light">{content.firstStepContent[locale]}</p>
            </div>
          </div>
          <div className="flex gap-6 w-full">
            <div className="flex flex-col items-center">
              <ChatIcon size={48} className="" />
              <hr className="mt-3 h-24 w-0.5 bg-gray-800" />
            </div>
            <div>
              <p className="mb-2 text-2xl">{content.secondStepTitle[locale]}</p>
              <p className="font-light">{content.secondStepContent[locale]}</p>
            </div>
          </div>
          <div className="flex gap-6 w-full">
            <div className="flex flex-col items-center">
              <PaymentIcon size={48} className="" />
              <hr className="mt-3 h-24 w-0.5 bg-gray-800" />
            </div>
            <div>
              <p className="mb-2 text-2xl">{content.thirdStepTitle[locale]}</p>
              <p className="font-light">{content.thirdStepContent[locale]}</p>
            </div>
          </div>
          <div className="flex gap-6 w-full">
            <div className="flex flex-col items-center">
              <ToolsIcon size={48} className="" />
              <hr className="mt-3 h-24 w-0.5 bg-gray-800" />
            </div>
            <div>
              <p className="mb-2 text-2xl">{content.forthStepTitle[locale]}</p>
              <p className="font-light">{content.forthStepContent[locale]}</p>
            </div>
          </div>
          <div className="flex gap-6 w-full">
            <div className="flex flex-col items-center">
              <CheckIcon size={48} className="" />
            </div>
            <div>
              <p className="mb-2 text-2xl"> {content.fifthStepTitle[locale]}</p>
              <p className="font-light">{content.fifthStepContent[locale]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
