import * as nouislider from "nouislider";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/config";
import {
  setRoofLength,
  setRoofWidth,
} from "../../../../store/slices/cpq/commercial";
import { Slider } from "../../../../components/Slider";
import { content } from "../content";

export const FootprintInputSection = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { locale, direction } = useSelector(
    (state: RootState) => state.localization
  );

  const { roofWidth, roofLength } = useSelector(
    (state: RootState) => state.cpq.commercial.config
  );

  const onWidthUpdate = (values: (string | number)[]) => {
    const updatedVal = Number(values[0]);
    if (updatedVal > 5) {
      dispatch(setRoofWidth({ width: updatedVal }));
    }
  };

  const onLengthUpdate = (values: (string | number)[]) => {
    const updatedVal = Number(values[0]);
    if (updatedVal > 5) {
      dispatch(setRoofLength({ length: updatedVal }));
    }
  };

  const onWidthEnd = (
    values: (string | number)[],
    handleNumber: number,
    unencoded: number[],
    tap: boolean,
    positions: number[],
    slider: nouislider.API
  ) => {
    const updatedVal = Number(values[0]);
    if (updatedVal < 5) {
      slider.reset();
      dispatch(setRoofWidth({ width: 5 }));
    }
  };

  const onLengthEnd = (
    values: (string | number)[],
    handleNumber: number,
    unencoded: number[],
    tap: boolean,
    positions: number[],
    slider: nouislider.API
  ) => {
    const updatedVal = Number(values[0]);
    if (updatedVal < 5) {
      slider.reset();
      dispatch(setRoofLength({ length: 5 }));
    }
  };

  return (
    <>
      <div className="mt-6 px-4 py-6 bg-gray-50 rounded-xl overflow-hidden">
        {direction === "ltr" && (
          <Slider
            label={content.footprint.labels.widthInput[locale]}
            value={roofWidth}
            onUpdate={onWidthUpdate}
            onEnd={onWidthEnd}
            options={{
              connect: "lower",
              direction: "ltr",
              start: roofWidth,
              range: {
                min: [0],
                "25%": [10],
                "50%": [20],
                "75%": [30],
                max: [40],
              },
            }}
            suffix="m"
          />
        )}
        {direction === "rtl" && (
          <Slider
            label={content.footprint.labels.widthInput[locale]}
            value={roofWidth}
            onUpdate={onWidthUpdate}
            onEnd={onWidthEnd}
            options={{
              connect: "lower",
              direction: "rtl",
              start: roofWidth,
              range: {
                min: [0],
                "25%": [10],
                "50%": [20],
                "75%": [30],
                max: [40],
              },
            }}
            suffix="m"
          />
        )}
      </div>
      <div className="mt-6 px-4 py-6 bg-gray-50 rounded-xl overflow-hidden">
        {direction === "ltr" && (
          <Slider
            label={content.footprint.labels.heightInput[locale]}
            value={roofLength}
            onUpdate={onLengthUpdate}
            onEnd={onLengthEnd}
            options={{
              connect: "lower",
              direction: "ltr",
              start: roofLength,
              range: {
                min: [0],
                "25%": [10],
                "50%": [20],
                "75%": [30],
                max: [40],
              },
            }}
            suffix="m"
          />
        )}
        {direction === "rtl" && (
          <Slider
            label={content.footprint.labels.heightInput[locale]}
            value={roofLength}
            onUpdate={onLengthUpdate}
            onEnd={onLengthEnd}
            options={{
              connect: "lower",
              direction: "rtl",
              start: roofLength,
              range: {
                min: [0],
                "25%": [10],
                "50%": [20],
                "75%": [30],
                max: [40],
              },
            }}
            suffix="m"
          />
        )}
      </div>
    </>
  );
};
