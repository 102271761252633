export const PanelIcon = () => {
  return (
    <svg
      width="15"
      height="24"
      viewBox="0 0 15 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.25"
        width="14.5"
        height="23.5"
        rx="0.75"
        stroke="#111827"
        strokeWidth="0.5"
      />
      <rect x="2" y="2" width="5" height="5.5" fill="#6B7280" />
      <rect x="8" y="2" width="5" height="5.5" fill="#6B7280" />
      <rect x="8" y="9.25" width="5" height="5.5" fill="#6B7280" />
      <rect x="8" y="16.5" width="5" height="5.5" fill="#6B7280" />
      <rect x="2" y="16.5" width="5" height="5.5" fill="#6B7280" />
      <rect x="2" y="9.25" width="5" height="5.5" fill="#6B7280" />
    </svg>
  );
};
