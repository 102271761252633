export const content = {
  h1: {
    en: "Contact Us",
    ar: "اتصل بنا",
    ku: "پە يوە نديمان پئوە بکە",
  },
  h2: {
    en: "Get Support from our World Class Customer Success Team",
    ar: "احصل على الدعم من فريق نجاح العملاء العالمي",
    ku: "پشتگیری وەربگرە لە شارەزايانمان",
  },
  p: {
    en: " in solar system installations of all grades and sizes",
    ar: " في تركيبات أنظمة الطاقة الشمسية بجميع درجاتها وأحجامها",
    ku: " لە دامەزراندنەکانی وزە ی خۆر بە هەموو پلە و قەبارەکان",
  },
  highlighted: { en: "Experts", ar: "خبراء", ku: "شارەزايان" },
};
