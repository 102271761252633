import { useEffect, useRef } from "react";
import * as nouislider from "nouislider";
import "nouislider/dist/nouislider.css";
import "./slider.css";
import wNumb from "wnumb";

type Props = {
  value: any;
  suffix?: string;
  decimals?: number;
  pipsMode?: nouislider.PipsMode.Range | nouislider.PipsMode.Steps;
  pipsDensity?: number;
  options?: nouislider.Options;
  label?: string;
  onStart?: (
    values: (number | string)[],
    handleNumber: number,
    unencoded: number[],
    tap: boolean,
    positions: number[],
    slider: nouislider.API
  ) => void;
  onSlide?: (
    values: (number | string)[],
    handleNumber: number,
    unencoded: number[],
    tap: boolean,
    positions: number[],
    slider: nouislider.API
  ) => void;
  onDrag?: (
    values: (number | string)[],
    handleNumber: number,
    unencoded: number[],
    tap: boolean,
    positions: number[],
    slider: nouislider.API
  ) => void;
  onUpdate?: (
    values: (number | string)[],
    handleNumber: number,
    unencoded: number[],
    tap: boolean,
    positions: number[],
    slider: nouislider.API
  ) => void;
  onChange?: (
    values: (number | string)[],
    handleNumber: number,
    unencoded: number[],
    tap: boolean,
    positions: number[],
    slider: nouislider.API
  ) => void;
  onSet?: (
    values: (number | string)[],
    handleNumber: number,
    unencoded: number[],
    tap: boolean,
    positions: number[],
    slider: nouislider.API
  ) => void;
  onEnd?: (
    values: (number | string)[],
    handleNumber: number,
    unencoded: number[],
    tap: boolean,
    positions: number[],
    slider: nouislider.API
  ) => void;
  className?: string;
};

export const Slider = ({
  value,
  suffix = "",
  decimals = 1,
  label = "",
  pipsMode = nouislider.PipsMode.Range,
  pipsDensity = 2,
  options = {
    start: 0,
    direction: "ltr",
    range: {
      min: [0],
      "10%": [500, 100],
      "50%": [10000, 100],
      max: [100000],
    },
  },
  onStart,
  onSlide,
  onDrag,
  onUpdate,
  onChange,
  onSet,
  onEnd,
  className,
}: Props) => {
  const formatter = wNumb({ suffix, decimals });
  const ref = useRef<HTMLDivElement & nouislider.API>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const update = (
    values: (number | string)[],
    handleNumber: number,
    unencoded: number[],
    tap: boolean,
    positions: number[],
    slider: nouislider.API
  ) => {
    if (!inputRef.current?.value) return;
    const formatted = formatter.to(Number(values[0]));

    inputRef.current.value = formatted;
  };

  useEffect(() => {
    if (!ref.current || !inputRef.current || !options) return;

    // Don't initialize twice
    const noui = nouislider.create(ref.current, options);

    // Direction
    noui.options.direction = options.direction;

    // Events
    if (onStart) noui.on("start", onStart);
    if (onSlide) noui.on("slide", onSlide);
    if (onDrag) noui.on("drag", onDrag);
    if (onUpdate) noui.on("update", onUpdate);
    noui.on("update", update);
    if (onChange) noui.on("change", onChange);
    if (onSet) noui.on("set", onSet);
    if (onEnd) noui.on("end", onEnd);

    // Pips
    noui.pips({
      mode: pipsMode,
      density: pipsDensity,
      format: wNumb({ suffix }),
    });
    // Tooltip
    noui.options.tooltips = formatter;

    // Input event handler
    inputRef.current.addEventListener("change", function () {
      const val = Number(formatter.from(this.value));
      const start = Number(options.start);
      const max = Number(options.range.max);
      if (val < start) {
        noui.set(start);
        this.value = formatter.to(start);
      } else if (val > max) {
        noui.set([max]);
        this.value = formatter.to(max);
      } else {
        noui.set([val]);
        this.value = formatter.to(val);
      }
    });

    return () => {
      if (!ref || !inputRef) return;
      noui.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, inputRef]);

  return (
    <div className={`${className} pb-12`}>
      <div className="flex justify-between mb-4">
        <p className="text-sm flex items-end">{label}</p>
        <input
          ref={inputRef}
          type="text"
          className="w-20 h-6 py-1 px-2 text-xs rounded-sm bg-gray-50 cursor-default ltr:text-right rtl:text-left outline-none"
          readOnly
          value={formatter.to(Number(value))}
        />
      </div>
      <div ref={ref} />
    </div>
  );
};
