export const content = {
  h1: {
    en: "Quick Delivery Anywhere in Iraq",
    ar: "توصيل سريع لأي مكان في العراق",
    ku: "گەیاندنی خێرا لە هەر شوێنێکی عێراق",
  },
  h2: {
    en: "Place an order and have it delivered to you in a few days",
    ar: "ضع طلبًا واستلمه لك في غضون أيام قليلة",
    ku: "داواکاری بکە و دوای چە ند ڕۆژئک دەگە ينرئتە دەستت",
  },
};
