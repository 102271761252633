import numeral from "numeral";
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import colors from "tailwindcss/colors";
import { RootState } from "../../../../store/config";
import { PanelIcon } from "../../../../components/panel.icon";
import { MotionConfig, motion } from "framer-motion";
import { content } from "../content";

export const FootprintVisualSection = () => {
  const box = useRef<HTMLCanvasElement>(null);

  const { locale } = useSelector((state: RootState) => state.localization);

  const roofWidth = useSelector(
    (state: RootState) => state.cpq.commercial.config.roofWidth
  );
  const roofLength = useSelector(
    (state: RootState) => state.cpq.commercial.config.roofLength
  );

  const roofMaxPanels = useSelector(
    (state: RootState) => state.cpq.commercial.config.roofMaxPanels
  );

  const [boxDimension, setBoxDimensions] = useState({
    boxWidth: 0,
    boxHeight: 0,
    itemWidth: 0,
  });

  useEffect(() => {
    if (!box.current || !box.current.parentElement) return;
    let parentWidth = box.current.parentElement.clientHeight - 48;
    let itemWidth = 0;
    if (roofWidth > roofLength) {
      itemWidth = Math.ceil(parentWidth / roofWidth);
    } else {
      itemWidth = Math.ceil(parentWidth / roofLength);
    }

    setBoxDimensions({
      boxWidth: 0,
      boxHeight: 0,
      itemWidth: itemWidth,
    });
  }, [box, roofWidth, roofLength]);

  useEffect(() => {
    if (box) {
      const context = box.current?.getContext("2d", { alpha: true });
      if (!context) return;

      const CANVAS_WIDTH = context.canvas.width;
      const CANVAS_HEIGHT = context.canvas.height;

      context.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      const array = Array(roofMaxPanels.total);

      const pixelPerMeterWidth = CANVAS_WIDTH / roofWidth;
      const pixelPerMeterHeight = CANVAS_HEIGHT / roofLength;

      const PANEL_WIDTH = pixelPerMeterWidth * 1.3;
      const PANEL_HEIGHT = pixelPerMeterHeight * 2.6;

      const xStart =
        ((CANVAS_WIDTH / PANEL_WIDTH - roofMaxPanels.row) * PANEL_WIDTH) / 2;
      const yStart =
        ((CANVAS_HEIGHT / PANEL_HEIGHT - roofMaxPanels.col) * PANEL_HEIGHT) / 2;
      context.beginPath();
      for (let idx = 0; idx < array.length; idx++) {
        const colNr = idx % roofMaxPanels.row;
        const rowNr = Math.floor(idx / roofMaxPanels.row);

        context.imageSmoothingEnabled = false;
        context.fillStyle = "rgba(0,0,30, 0.9)";
        context.fillRect(
          colNr * PANEL_WIDTH + xStart,
          rowNr * PANEL_HEIGHT + yStart,
          PANEL_WIDTH,
          PANEL_HEIGHT
        );

        context.rect(
          colNr * PANEL_WIDTH + xStart,
          rowNr * PANEL_HEIGHT + yStart,
          PANEL_WIDTH,
          PANEL_HEIGHT
        );

        context.strokeStyle = colors.gray[200];
        context.lineWidth = 1;
        context.lineJoin = "round";
      }
      context.stroke();
    }
  }, [box, roofMaxPanels, roofLength, roofWidth]);

  return (
    <div className="p-6 md:p-20 w-full h-full flex flex-col table-fixed bg-gray-50 rounded-2xl shadow-sm">
      <div className="flex-1 relative aspect-video">
        <MotionConfig>
          <motion.canvas
            ref={box}
            className="border-4 border-stone-500 bg-gray-300"
            animate={{
              type: "spring",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              width: `${Math.floor(roofWidth) * boxDimension.itemWidth}px`,
              height: `${Math.floor(roofLength) * boxDimension.itemWidth}px`,
            }}
          ></motion.canvas>
        </MotionConfig>
      </div>
      <div className="w-full">
        <ul className="w-full flex flex-row items-center mt-4 mb-6">
          <li className="w-1/2 flex flex-col items-center">
            <div className="bg-[#0f102c] w-3 h-6 m-2.5" />
            <p className="mt-1.5 text-xs font-medium text-slate-900">
              {content.footprint.labels.panel[locale]}
            </p>
          </li>
          <li className="w-1/2 flex flex-col items-center">
            <div className="my-2.5 font-light text-slate-900">
              {numeral(roofWidth).multiply(roofLength).format("0")}m<sup>2</sup>
            </div>
            <p className="mt-1.5 text-xs font-medium text-slate-900">
              {content.footprint.labels.area[locale]}
            </p>
          </li>
        </ul>
        <hr className="" />
        <div className="mt-6 flex justify-center items-center">
          <div className="my-1.5 mx-2.5">
            <PanelIcon />
          </div>
          <div className="ml-2">
            <p className="text-sm font-semibold">{`x${numeral(
              roofMaxPanels.total
            ).format("0")} ${
              content.footprint.labels.quantityBold[locale]
            }`}</p>
            <p className="mt-1.5 text-sm">
              {content.footprint.labels.quantityRegular[locale]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
