import { CogIcon, SunIcon } from "@heroicons/react/solid";
import { ColumnChart } from "bizcharts";
import React from "react";
import { IoConstructOutline, IoSunnyOutline } from "react-icons/io5";
import colors from "tailwindcss/colors";

const Page = ({
  children = null,
  className = "",
  style = {},
}: {
  children?: React.ReactNode;
  className?: string;
  style?: object;
}) => {
  return (
    <div
      className={`my-8 relative w-full md:shadow-xl md:h-[297mm] md:w-[210mm] mx-auto flex flex-col ${className}`}
      style={{ ...style, boxShadow: "0 3px 6px rgb(0 0 0 / 16%)" }}
    >
      {children}
    </div>
  );
};

const CoverBackground = () => {
  return (
    <div className="w-full h-full absolute left-0 top-0 right-0 bottom-0">
      <svg
        className="absolute"
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        viewBox="0 0 794 1123"
      >
        <defs>
          <path
            id="cube"
            d="M60.4,15.6L33.5,0.1c-0.3-0.2-0.7-0.2-1,0L5.6,15.6c-0.3,0.2-0.5,0.5-0.5,0.8v31c0,0.3,0.2,0.7,0.5,0.8
                l26.9,15.5C32.7,64,32.8,64,33,64s0.3,0,0.5-0.1l26.9-15.5c0.3-0.2,0.5-0.5,0.5-0.8v-31C60.8,16.1,60.7,15.8,60.4,15.6z M33,2.1
                l24.9,14.4L33,30.9L8.1,16.5L33,2.1z M7.1,18.2L32,32.6v28.8L7.1,47V18.2z M34,61.4V32.6l24.9-14.4V47L34,61.4z"
          ></path>
        </defs>
        <g>
          <use
            x="-7"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="47"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="101"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="155"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="209"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="263"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="317"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="425"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
        </g>
        <g transform="translate(48, -83)">
          <use
            x="-7"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="47"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="101"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="155"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="209"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="263"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="317"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="371"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
        </g>
        <g transform="translate(-48, 83)">
          <use
            x="-7"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="101"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="155"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="209"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="317"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
        </g>
        <g transform="translate(0, 166)">
          <use
            x="101"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
        </g>
      </svg>
      <svg
        className="absolute top-[560px] right-0"
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        viewBox="0 0 794 1123"
      >
        <defs>
          <path
            id="cube-white"
            fill="#ffffff"
            d="M60.4,15.6L33.5,0.1c-0.3-0.2-0.7-0.2-1,0L5.6,15.6c-0.3,0.2-0.5,0.5-0.5,0.8v31c0,0.3,0.2,0.7,0.5,0.8
                l26.9,15.5C32.7,64,32.8,64,33,64s0.3,0,0.5-0.1l26.9-15.5c0.3-0.2,0.5-0.5,0.5-0.8v-31C60.8,16.1,60.7,15.8,60.4,15.6z M33,2.1
                l24.9,14.4L33,30.9L8.1,16.5L33,2.1z M7.1,18.2L32,32.6v28.8L7.1,47V18.2z M34,61.4V32.6l24.9-14.4V47L34,61.4z"
          ></path>
        </defs>
        <g transform="translate(48, 0)">
          <use
            x="371"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
        </g>
        <g transform="translate(0, 83)">
          <use
            x="371"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
        </g>
        <g transform="translate(-48, 166)">
          <use
            x="307"
            y="4"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="371"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
          <use
            x="425"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
        </g>
        <g transform="translate(0, 249)">
          <use
            x="317"
            y="10"
            className="scale-[1.775]"
            xlinkHref="#cube-white"
          ></use>
        </g>
      </svg>
    </div>
  );
};

const Header = ({
  children = null,
  className = "",
  style = {},
}: {
  children?: React.ReactNode;
  className?: string;
  style?: object;
}) => {
  return (
    <div
      className={`w-full px-[1.25in] pt-5 flex text-xs ${className}`}
      style={style}
    >
      {children}
    </div>
  );
};

const Footer = ({
  children = null,
  className = "",
  style = {},
}: {
  children?: React.ReactNode;
  className?: string;
  style?: object;
}) => {
  return (
    <div
      className={`w-full px-[1.25in] py-[0.75in] text-xs ${className}`}
      style={style}
    >
      {children}
    </div>
  );
};

const Content = ({
  children,
  className = "",
  style = {},
}: {
  children?: React.ReactNode;
  className?: string;
  style?: object;
}) => {
  return (
    <div
      className={`flex-1 px-[1.25in] py-[0.75in] ${className}`}
      style={style}
    >
      {children}
    </div>
  );
};

const ContentDivider = ({
  className = "",
}: {
  children?: React.ReactNode;
  className?: string;
  style?: object;
}) => {
  return (
    <div className={`flex gap-6 ${className}`}>
      <div className={`w-1/3 border-t border-black`} />
      <div className={`w-2/3 border-t border-black`} />
    </div>
  );
};

export const Proposal = () => {
  return (
    <div className="w-full h-full pb-4 overflow-hidden">
      <Page className="bg-blue-400">
        <CoverBackground />
        <Header className="h-[50mm]"></Header>
        <Content>
          <div className="relative px-[1.25in] py-[1.5rem] ml-[-1.25in] mr-[1.25in] bg-white">
            <div>
              <p className="text-5xl font-bold">20.34kW</p>
              <p className="text-5xl font-bold">Solar System</p>
              <p className="text-2xl font-semibold py-2">
                with 10.24kWh battery storage
              </p>
            </div>
            <div className="mt-10 text-xs">
              <p className="font-bold">Prepared for:</p>
              <p>Unknown address</p>
              <p className="mt-4 font-bold">Prepared by:</p>
              <p>Hazir Magron on Dec 11, 2022</p>
            </div>
          </div>
        </Content>
        <Footer>
          <p className="font-bold">Toptaqa</p>
        </Footer>
      </Page>
      <Page className="">
        <Header>
          <div className="ml-[-1.25in] w-[1.25in] py-0.5 px-2 bg-blue-400 text-right font-bold">
            2
          </div>
        </Header>
        <Content className="flex flex-col px-[72px]">
          <ContentDivider />
          <div className="flex gap-6 min-h-1/3">
            <div className="w-1/3 py-3">
              <IoSunnyOutline size={36} className="text-blue-500 mb-2" />
              <p className="text-lg font-semibold mb-2">
                Daily production per month
              </p>
              <p className="text-xs">
                How much electricity will my system generate per day, on
                average?
              </p>
            </div>
            <div className="w-2/3 py-3">
              <ColumnChart
                autoFit
                data={[
                  { production: 23, month: 1 },
                  { production: 44, month: 2 },
                  { production: 55, month: 3 },
                  { production: 66, month: 4 },
                  { production: 77, month: 5 },
                  { production: 88, month: 6 },
                  { production: 86, month: 7 },
                  { production: 89, month: 8 },
                  { production: 74, month: 9 },
                  { production: 55, month: 10 },
                  { production: 44, month: 11 },
                  { production: 15, month: 12 },
                ]}
                xField={"month"}
                yField={"production"}
                color={colors.blue[400]}
              />
            </div>
          </div>
          <ContentDivider />
          <div className="flex gap-6 min-h-1/3">
            <div className="w-1/3 py-3">
              <IoConstructOutline size={36} className="text-blue-500" />
              <p className="text-lg font-semibold mb-2">System Details</p>
              <p className="text-xs">Your custom design</p>
            </div>
            <div className="w-2/3 py-3">
              <div className="pb-4">
                <p className="text-xs font-bold">
                  System size<sup>1</sup>
                </p>
                <p className="text-sm font-medium">
                  20.34 kW<sub>DC</sub> (STC)
                </p>
              </div>
              <div className="pb-4">
                <p className="text-xs font-bold">
                  Estimated annual production<sup>2</sup>
                </p>
                <p className="text-sm font-medium">19,808 kWh</p>
              </div>
              <div className="pb-4">
                <p className="text-xs font-bold">Solar panel</p>
                <p className="text-sm font-medium">
                  36 × 565W Jinko Solar Tiger Neo -{" "}
                  <span className="text-gray-400">JKM565N-72HL4-BDV</span>
                </p>
                <p className="text-xs font-medium">
                  2278 mm × 1134 mm · Monocrystalline
                  {" · "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline visited:bg-purple-600"
                  >
                    Datasheet
                  </a>
                  {" · "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline visited:bg-purple-600"
                  >
                    Warranty
                  </a>
                </p>
              </div>
              <div className="pb-4">
                <p className="text-xs font-bold">Inverter</p>
                <p className="text-sm font-medium">
                  1 × Growatt MAC 15KTL3-XL · 15000W
                </p>
                <p className="text-xs font-medium">
                  3 phase · 98.8% max efficiency
                  {" · "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline visited:bg-purple-600"
                  >
                    Datasheet
                  </a>
                  {" · "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline visited:bg-purple-600"
                  >
                    Manual
                  </a>
                </p>
              </div>
              <div className="pb-4">
                <p className="text-xs font-bold">Energy Storage</p>
                <p className="text-sm font-medium">
                  1 × Jinko Solar JKS-B51200-BI
                </p>
                <p className="text-xs font-medium">
                  10.24kWh · Lithium-Ion
                  {" · "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline visited:bg-purple-600"
                  >
                    Datasheet
                  </a>
                  {" · "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline visited:bg-purple-600"
                  >
                    Warranty
                  </a>
                  {" · "}
                  <a
                    href="#"
                    className="text-blue-600 hover:underline visited:bg-purple-600"
                  >
                    Safety
                  </a>
                </p>
              </div>
              <div className="pb-4">
                <p className="text-xs font-bold">
                  System efficiency<sup>3</sup>
                </p>
                <p className="text-sm font-medium">87%</p>
              </div>
            </div>
          </div>
          <ContentDivider />
        </Content>
        <Footer>
          <p className="font-bold">Toptaqa</p>
        </Footer>
      </Page>
    </div>
  );
};
