export const content = {
  h1: {
    en: "Solar Data API",
    ar: "واجهة برمجة تطبيقات الطاقة الشمسية",
    ku: "API داتای خۆر",
  },
  h2: {
    en: "Extensive and Simple-to-use",
    ar: "شامل وسهل الاستخدام",
    ku: "بەرفراوان و سادەیە بۆ بەکارهێنان",
  },
  p: {
    en: "Get access to our vast bank of solar data through a simple-to-use API. We serve solar radiation, wind, geometric and meteorological data among others that we serve in JSON data - ready to be used in your analysis and simulation models. Our data coverage is global, granular, accurate and very easy to set up and use.",
    ar: "احصل على إمكانية الوصول إلى مصرفنا الضخم من البيانات الشمسية من خلال واجهة برمجة تطبيقات سهلة الاستخدام. نحن نخدم بيانات الإشعاع الشمسي والرياح والهندسة والأرصاد الجوية من بين أمور أخرى نخدمها في بيانات JSON - جاهزة للاستخدام في نماذج التحليل والمحاكاة الخاصة بك. تغطية البيانات لدينا عالمية وحبيبية ودقيقة وسهلة الإعداد والاستخدام.",
    ku: "دەستت بگات بە بانکی فراوانی داتا خۆرەکانمان لە ڕێگەی API یەکی سادە بۆ بەکارهێنان.  ئێمە خزمەت بە تیشکی خۆر، با، ئەندازەیی و کەشناسی دەکەین لەنێو ئەوانی تردا کە ئێمە لە داتاکانی JSON خزمەتیان دەکەین - ئامادەن بۆ بەکارهێنان لە مۆدێلەکانی شیکاری و ھاوشێوەکردنتان.  ڕووماڵکردنی داتاکانمان جیهانییە، دانەوێڵەیی، وردە و زۆر ئاسانە بۆ ڕێکخستن و بەکارهێنانی.",
  },
};
