import DataSet from "@antv/data-set";
import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { interpolateBasis } from "d3-interpolate";
import { RootState } from "../config";
import {
  setAvgDailyOutputPerPanel,
  setDaySizeAmperes,
  setDaySizeHours,
  setNightSizeAmperes,
  setNightSizeHours,
  setResidentialSimulationAvgHourData,
} from "../slices/cpq/residential";

// Create the middleware instance and methods
export const residentialAvgHourlySimulationTrigger = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
residentialAvgHourlySimulationTrigger.startListening({
  matcher: isAnyOf(
    setAvgDailyOutputPerPanel,
    setDaySizeAmperes,
    setDaySizeHours,
    setNightSizeAmperes,
    setNightSizeHours
  ),
  effect: async (action, listenerApi) => {
    // Run whatever additional side-effect-y logic you want here

    const state = listenerApi.getState() as RootState;
    const simulationData = state.cpq.residential.simulationData;
    const avgDailyOutputPerPanel = state.cpq.residential.avgDailyOutputPerPanel;

    if (avgDailyOutputPerPanel && simulationData.length > 0) {
      const supplyVoltage = state.location.data?.supplyVoltage;
      const daySize = state.cpq.residential.config.daySize;
      const nightSize = state.cpq.residential.config.nightSize;
      const startHour = nightSize.hours / 2;
      const endHour = 24 - nightSize.hours / 2;

      if (!supplyVoltage) return;
      const panelsQty =
        (daySize.amperes * daySize.hours) / avgDailyOutputPerPanel;

      const ds = new DataSet();
      const dv = ds.createView();
      dv.source(simulationData);

      dv.transform({
        type: "aggregate",
        fields: ["output"],
        operations: ["average"],
        as: ["output"],
        groupBy: ["hour"],
      });

      dv.transform({
        type: "map",
        callback: (row: any, idx, arr) => {
          if (row.hour >= 24) row.hour = row.hour - 24;
          const output = row.output * panelsQty;
          row.systemSize = daySize.amperes;
          row.output = output;

          if (idx > 0) {
            row.outputAcc = output + arr[idx - 1].outputAcc;
          } else {
            row.outputAcc = output;
          }

          return row;
        },
      });

      dv.transform({
        type: "sort-by",
        fields: ["hour"],
        order: "ASC",
      });

      const interpolator = interpolateBasis(
        Array.from(Array(24).keys()).map((v) => dv.rows[v].output)
      );

      dv.transform({
        type: "map",
        callback: (row: any, idx, arr) => {
          // Set battery within accepted hours
          const insideDayHours = row.hour <= startHour || row.hour >= endHour;
          if (insideDayHours) {
            row.flexible = nightSize.amperes;
          }

          row.potentialUsage = nightSize.amperes;
          const interpolateIdx = row.hour === 24 ? 0 : row.hour;
          row.inflexible = interpolator(interpolateIdx / 23);

          row.total = 0;
          if (row.inflexible > 0) {
            row.total = row.total + row.inflexible;
          }

          if (row.flexible > 0) {
            row.total = row.total + row.flexible;
          }

          if (row.inflexible === 0) delete row.inflexible;
          if (row.total === 0) delete row.total;
          if (row.potentialUsage === 0) delete row.potentialUsage;

          return row;
        },
      });

      listenerApi.dispatch(
        setResidentialSimulationAvgHourData({
          data: dv.rows,
        })
      );
    }
  },
});
