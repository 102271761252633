import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  IoChevronForwardCircleOutline,
  IoChevronForwardCircleSharp,
  IoClose,
} from "react-icons/io5";

export const InfoModal = ({
  type = "text",
  linkText = "",
  description = "",
  img = "",
  title = "Heading",
  className = "text-blue-600 mt-1 mb-6 hover:underline cursor-pointer",
  boxClassName = "",
  panelClass = "",
  children,
}: {
  type?: "box" | "text";
  linkText: string | JSX.Element;
  description?: string;
  img?: string;
  title: string;
  className?: string;
  boxClassName?: string;
  panelClass?: string;
  children: JSX.Element;
}) => {
  const [boxHover, setBoxHover] = useState(false);
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      {type === "text" && typeof linkText === "string" && (
        <p className={`${className}`} onClick={openModal}>
          {linkText}
        </p>
      )}
      {type === "text" && typeof linkText === "object" && (
        <div onClick={openModal}>{linkText}</div>
      )}
      {type === "box" && (
        <div
          className={`flex rounded-xl bg-gray-100 overflow-hidden cursor-pointer ${boxClassName}`}
          onClick={openModal}
          onMouseEnter={() => setBoxHover(true)}
          onMouseLeave={() => setBoxHover(false)}
        >
          {img && (
            <img src={img} alt="" className="w-28 aspect-square object-cover" />
          )}
          <div className={`p-4 flex flex-1 text-sm`}>
            <div className="flex-1">
              <p className="font-semibold">{linkText}</p>
              <p className="">{description}</p>
            </div>
            {boxHover ? (
              <IoChevronForwardCircleSharp
                size={20}
                className="text-gray-800 rtl:-scale-x-100"
              />
            ) : (
              <IoChevronForwardCircleOutline
                size={20}
                className="text-gray-800 rtl:-scale-x-100"
              />
            )}
          </div>
        </div>
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className={`relative z-50`} onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0">
            <div className="flex h-full items-end md:items-center md:justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="md:opacity-0 md:scale-95 translate-y-full md:translate-y-0"
                enterTo="md:opacity-100 md:scale-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="md:opacity-100 md:scale-100 translate-y-0"
                leaveTo="md:opacity-0 md:scale-95 translate-y-full md:translate-y-0"
              >
                <Dialog.Panel
                  className={`w-full flex flex-col max-h-[80%] md:max-h-[60%] md:h-auto md:w-[800px] md:max-w-full transform overflow-hidden rounded-t-2xl md:rounded-2xl bg-white text-left align-middle shadow-xl transition-all ${panelClass}`}
                >
                  <div>
                    <button
                      className="md:hidden w-9 h-9 flex justify-center items-center bg-gray-200 rounded-full ltr:ml-6 rtl:mr-6 my-6"
                      onClick={closeModal}
                    >
                      <IoClose
                        className="text-gray-500"
                        size={25}
                        style={{ strokeWidth: "14px" }}
                      />
                    </button>
                  </div>
                  <div className="flex flex-col flex-1 md:h-full overflow-y-auto">
                    <Dialog.Title
                      as="h1"
                      className="px-6 pb-7 md:pt-20 text-4xl font-medium leading-snug text-slate-900 text-center"
                    >
                      {title}
                    </Dialog.Title>
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
