import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ILocale = "en" | "ar" | "ku";
export type IDireciton = "ltr" | "rtl";
interface State {
  locale: ILocale;
  direction: IDireciton;
}

export const localizationSlice = createSlice({
  name: "localization",
  initialState: {
    locale: "en",
    direction: "ltr",
  } as State,
  reducers: {
    switchLocale: (state, action: PayloadAction<ILocale>) => {
      state.locale = action.payload;
      if (action.payload === "en") {
        state.direction = "ltr";
      } else if (action.payload === "ar") {
        state.direction = "rtl";
      } else if (action.payload === "ku") {
        state.direction = "rtl";
      }
    },
  },
});

export const { switchLocale } = localizationSlice.actions;
