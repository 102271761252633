import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { FiMenu } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Logo } from "../../components/Logo";
import { RootState } from "../../store/config";
import { content } from "./content";
import { Navlink } from "./navlink.component";
import { Link, useParams } from "react-router-dom";

type Props = {
  transparent: boolean | undefined;
  className?: string;
};

export const NavMobile: React.FC<Props> = ({
  transparent = false,
  className = "",
}) => {
  const { lang } = useParams();
  const { locale, direction } = useSelector(
    (state: RootState) => state.localization
  );

  return (
    <nav className={`${transparent ? "visible" : ""} ${className} `}>
      <Menu as="div" className="text-left">
        <div>
          <Menu.Button
            className={`${
              transparent ? "text-white" : "text-black"
            } flex w-full justify-center items-center p-[3px]`}
          >
            <FiMenu size={24} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <Menu.Items
            style={{ textAlign: direction === "ltr" ? "left" : "right" }}
            className="fixed left-0 top-0 right-0 bottom-0 z-50 divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="flex justify-between py-4 px-6">
              <Link to={`/${lang}`} rel="canonical">
                <Logo fill="text-slate-900" />
              </Link>
              <Menu.Item>
                <div>
                  <IoCloseSharp size={30} className="cursor-pointer" />
                </div>
              </Menu.Item>
            </div>
            <hr />
            <div className="flex flex-col pb-12">
              <Menu.Item>
                <>
                  <Navlink
                    href={`/${lang}/products`}
                    className={`px-6 py-3 opacity-90 hover:opacity-100 text-black`}
                  >
                    {content.products[locale]}
                  </Navlink>
                </>
              </Menu.Item>
              <Menu.Item>
                <>
                  <Navlink
                    href={`/${lang}/services`}
                    className={`px-6 py-3 opacity-90 hover:opacity-100 text-black`}
                  >
                    {content.services[locale]}
                  </Navlink>
                </>
              </Menu.Item>
              <Menu.Item>
                <>
                  <Navlink
                    href={`/${lang}/about`}
                    className={`px-6 py-3 opacity-90 hover:opacity-100 text-black`}
                  >
                    {content.abuotus[locale]}
                  </Navlink>
                </>
              </Menu.Item>
              <Menu.Item>
                <>
                  <Navlink
                    href={`/${lang}/support`}
                    className={`px-6 py-3 opacity-90 hover:opacity-100 text-black`}
                  >
                    {content.support[locale]}
                  </Navlink>
                </>
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/${lang}/cpq/residential`}
                    className="mx-6 my-3 py-2.5 flex justify-center items-center bg-blue-600 text-white rounded-full hover:bg-blue-500 hover:text-white text-sm uppercase ltr:tracking-wide"
                  >
                    {content.configure[locale]}
                  </Link>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </nav>
  );
};
