import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const ProductsPointers = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="py-24">
      <div className="mx-6 md:mx-auto max-w-screen-lg">
        <h1 className="uppercase ltr:tracking-widest mb-4 text-center">
          {content.h1[locale]}
        </h1>
        <h2 className="text-3xl font-light ltr:tracking-widest mb-3 text-center">
          {content.h2[locale]}
        </h2>
        <div className="mt-16 p-12 grid grid-rows-3 grid-cols-1 md:grid-rows-1 md:grid-cols-3 gap-x-12 gap-y-20">
          <div>
            <p className="text-xl">{content.pointTitle1[locale]}</p>
            <p className="mt-2 font-light">
              {content.pointDecription1[locale]}
            </p>
          </div>
          <div>
            <p className="text-xl">{content.pointTitle2[locale]}</p>
            <p className="mt-2 font-light">
              {content.pointDecription2[locale]}
            </p>
          </div>
          <div>
            <p className="text-xl">{content.pointTitle3[locale]}</p>
            <p className="mt-2 font-light">
              {content.pointDecription3[locale]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
