import {
  IoCheckmarkCircleOutline,
  IoDiamondSharp,
  IoThumbsUpSharp,
} from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const Pointers = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="py-24">
      <div className="mx-6 md:mx-16 lg:mx-auto max-w-screen-lg">
        <h2 className="text-3xl font-light ltr:tracking-widest mb-3 text-center">
          {content.title[locale]}
        </h2>
        <div className="flex flex-col md:flex-row gap-8 mt-16">
          <div className="flex flex-col flex-1 items-center text-center py-14 px-5 shadow-sm rounded-2xl bg-amber-100 text-stone-900">
            <p className="text-3xl font-bold">{content.qualityTitle[locale]}</p>
            <p className="mt-4 font-semibold">
              {content.qualityDescription[locale]}
            </p>
            <IoDiamondSharp size={100} className="mt-9 text-amber-200" />
          </div>
          <div className="flex flex-col flex-1 items-center text-center py-14 px-5 shadow-sm rounded-2xl bg-blue-700 text-white">
            <p className="text-3xl font-bold">
              {content.customizationTitle[locale]}
            </p>
            <p className="mt-4 font-semibold">
              {content.customizationDescription[locale]}
            </p>
            <IoCheckmarkCircleOutline size={100} className="mt-9" />
          </div>
          <div className="flex flex-col flex-1 items-center text-center py-14 px-5 shadow-sm rounded-2xl bg-stone-900 text-white">
            <p className="text-3xl font-bold">
              {content.customerTitle[locale]}
            </p>
            <p className="mt-4 font-semibold">
              {content.customerDescription[locale]}
            </p>
            <IoThumbsUpSharp size={100} className="mt-9 opacity-10" />
          </div>
        </div>
      </div>
    </div>
  );
};
