export const content = {
  h1: {
    en: "Consulting",
    ar: "مستشار",
    ku: "ڕاوێژکاری",
  },
  h2: {
    en: "Scientific and Data-driven",
    ar: "علمي وقائم على البيانات",
    ku: "زانستی و داتا-بزوێنەر",
  },
  p: {
    en: "We provide financial, project management and solar assessment consulting services for solar projects of all types scales. From small-scale home renovations to utility-scale projects, and everything in-between. Our experts bring a scientific and data-driven approach to successfully deliver timely and qualitatively results.",
    ar: "نقدم خدمات استشارية في الشؤون المالية وإدارة المشاريع وتقييم الطاقة الشمسية لمشاريع الطاقة الشمسية بمختلف أنواعها. من تجديدات المنازل على نطاق صغير إلى مشاريع المرافق ، وكل شيء بينهما. يجلب خبراؤنا نهجًا علميًا قائمًا على البيانات لتقديم نتائج نوعية وفي الوقت المناسب بنجاح.",
    ku: "ئێمە خزمەتگوزاری ڕاوێژکاری دارایی و بەڕێوەبردنی پڕۆژە و هەڵسەنگاندنی خۆر بۆ پڕۆژەکانی وزەی خۆر بە هەموو جۆرە پێوەرەکانەوە پێشکەش دەکەین.  لە نۆژەنکردنەوەی ماڵە بچووکەکانەوە تا پڕۆژەی خزمەتگوزارییەکان، و هەموو شتێک لە نێوانیاندا.  پسپۆڕەکانمان ڕێبازێکی زانستی و داتا-بزوێنەر دەهێنن بۆ ئەوەی بە سەرکەوتوویی ئەنجامەکان لە کاتی خۆیدا و چۆنایەتی پێشکەش بکەن.",
  },
};
