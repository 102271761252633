import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/config";
import { setCity, setCountry } from "../../../../store/slices/location";
import { fetchSupportedLocations } from "../../../../store/slices/meta/supported-locations";
import { Combobox } from "../../../../components/Combobox";
import { content } from "../content";

export const LocationInputSection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { locale } = useSelector((state: RootState) => state.localization);

  const supportedLocationsPending = useSelector(
    (state: RootState) => state.meta.supportedLocations.api.pending
  );

  const supportedLocations = useSelector(
    (state: RootState) => state.meta.supportedLocations.data
  );

  useEffect(() => {
    if (supportedLocationsPending || supportedLocations) return;
    dispatch(fetchSupportedLocations());
  }, [dispatch, supportedLocations, supportedLocationsPending]);

  const selectedCountry = useSelector(
    (state: RootState) => state.location.selectedCountry
  );

  const selectedCity = useSelector(
    (state: RootState) => state.location.selectedCity
  );

  const countries = useMemo(() => {
    if (!supportedLocations) return [];
    return supportedLocations.countries;
  }, [supportedLocations]);

  const cities = useMemo(() => {
    if (!supportedLocations) return [];
    return supportedLocations.cities[selectedCountry];
  }, [selectedCountry, supportedLocations]);

  const handleCountryChange = (country: string) => {
    dispatch(setCountry(country));
  };

  const handleCityChange = (city: string) => {
    dispatch(setCity(city));
  };

  return (
    <>
      <Combobox
        label={content.location.labels.country[locale]}
        data={countries}
        selected={selectedCountry}
        onChange={handleCountryChange}
        className={"w-full mt-6 relative"}
      />
      <Combobox
        label={content.location.labels.city[locale]}
        data={cities}
        selected={selectedCity}
        onChange={handleCityChange}
        className={"w-full mt-5 relative"}
      />
    </>
  );
};
