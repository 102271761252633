export const content = {
  card: {
    0: {
      p: {
        en: "Start a live chat with one of our ",
        ar: "ابدأ محادثة مباشرة مع أحد خبرائنا",
        ku: "لەگەڵ یەکێک لە شارەزايانمان دەست بە چاتی ڕاستەوخۆ بکە",
      },
      highlight: {
        en: "experts",
        ar: "خبراء",
        ku: "شارەزايان",
      },
      action: {
        en: "Chat now",
        ar: "الدردشة الآن",
        ku: "گفتوگۆ بکە ئێستا",
      },
    },
    1: {
      p: {
        en: "Get email support in ",
        ar: "احصل على دعم البريد الإلكتروني في",
        ku: "پشتگیری ئیمەیڵ بەدەست بهێنە لە...",
      },
      highlight: {
        en: "arabic, kurdish or english",
        ar: "العربية والكردية والإنجليزية",
        ku: "عە رە بی،وردی يان ئينگليزی",
      },
      action: {
        en: "Email us",
        ar: "ارسل لنا عبر البريد الإلكتروني",
        ku: "نامە ی ئە لکترۆنی بنئرە",
      },
    },
  },
};
