import { BsFacebook, BsInstagram } from "react-icons/bs";
import { RiLinkedinFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RootState } from "../../store/config";
import { content } from "./content";

import "./index.css";

export const Social = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="linear-gradiant gradient-background py-48">
      <div className="mx-6 md:mx-auto max-w-screen-lg">
        <h1 className="uppercase ltr:tracking-widest mb-4 text-center text-white">
          {content.h1[locale]}
        </h1>
        <h2 className="text-3xl font-light ltr:tracking-widest mb-3 text-center text-white">
          {content.h2[locale]}{" "}
        </h2>
        <div className="mt-16 flex justify-center items-center">
          <a
            href="#"
            className="w-12 aspect-square flex justify-center items-center rounded-full bg-gray-100"
          >
            <BsFacebook size={44} className="scale-[1.12] text-gray-800" />
          </a>
          <a
            href="#"
            className="rtl:mr-6 ltr:ml-6 w-12 aspect-square flex justify-center items-center rounded-full bg-gray-800"
          >
            <BsInstagram size={24} className="text-gray-100" />
          </a>
          <a
            href="#"
            className="rtl:mr-6 ltr:ml-6 w-12 aspect-square flex justify-center items-center rounded-full bg-gray-800"
          >
            <RiLinkedinFill size={24} className="text-gray-100" />
          </a>
        </div>
      </div>
    </div>
  );
};
