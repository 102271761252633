import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const Consulting = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="bg-gray-100 py-24">
      <div className="mx-6 md:mx-auto md:flex gap-6 max-w-screen-lg">
        <div className="flex-1">
          <img
            className="object-cover aspect-video rounded-2xl shadow-sm"
            src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fassets%2Fproject-mgmt.webp?alt=media&token=5e67b55d-724f-4632-8c6c-3bd04e807b17"
            alt="Consulting Services"
          />
        </div>
        <div className="mt-6 md:mt-0 flex-1 flex flex-col justify-center">
          <h1 className="uppercase ltr:tracking-widest mb-2">
            {content.h1[locale]}
          </h1>
          <h2 className="text-3xl font-light ltr:tracking-widest mb-4">
            {content.h2[locale]}
          </h2>
          <p className="font-light">{content.p[locale]}</p>
        </div>
      </div>
    </div>
  );
};
