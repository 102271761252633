import {
  collection,
  getDocs,
  limit,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { ICoupon } from "../types/coupon";

const converter = {
  toFirestore: (data: ICoupon) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as ICoupon,
};

export const getCoupon = async (code: string): Promise<ICoupon | null> => {
  // Query Params
  const collectionRef = collection(db, "coupons");
  const codeCountry = where("code", "==", code.toLocaleLowerCase());
  const q = query(collectionRef, codeCountry, limit(1)).withConverter(
    converter
  );
  // Fetch
  const querySnapshot = await getDocs(q);
  // Controls
  const coupons = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  const coupon = coupons.pop();

  if (coupon) {
    return coupon;
  } else {
    return null;
  }
};
