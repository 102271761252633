export const content = {
  h1: {
    en: "About Us",
    ar: "من نحن",
    ku: "دەربارەی ئێمە",
  },
  h2: {
    en: "Solving Iraq's decades long electricity problem",
    ar: "حل مشكلة الكهرباء في العراق منذ عقود",
    ku: "چارەسەرکردنی کێشەی کارەبای دەیان ساڵەی عێراق",
  },
};
