import { doc, getDoc, QueryDocumentSnapshot } from "firebase/firestore";
import { db } from "../firebase/config";
import { IMetaCatalogue } from "../types/meta/catalogue";

const converter = {
  toFirestore: (data: IMetaCatalogue) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as IMetaCatalogue,
};

export const getCatalogue = async (): Promise<IMetaCatalogue | null> => {
  // Query Params
  const docRef = doc(db, "meta_data", "catalogue").withConverter(converter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};
