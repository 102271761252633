import { createListenerMiddleware } from "@reduxjs/toolkit";
import { RootState } from "../config";
import {
  bookmarkResidential,
  setResidentialConfigAction,
} from "../slices/cpq/residential";

// Create the middleware instance and methods
export const residentialBookmarkListener = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
residentialBookmarkListener.startListening({
  actionCreator: bookmarkResidential,
  effect: async (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();
    const { uuid } = action.payload;

    const state = listenerApi.getState() as RootState;
    if (uuid) {
      try {
        const { config, customer } = state.cpq.residential;
        const { selectedCountry, selectedCity } = state.location;
        await listenerApi.dispatch(
          setResidentialConfigAction({
            uuid,
            customer,
            config,
            country: selectedCountry,
            city: selectedCity,
          })
        );
      } catch (error) {
        console.error(error);
      }
    }
  },
});
