type Props = {
  title?: string;
  titleContinued?: string;
  children: React.ReactNode;
  className?: string;
  classNameBox?: string;
};
export const SingleColumn = ({
  title = "",
  titleContinued = "",
  children = null,
  className = "my-24 mx-auto px-6 md:px-0",
  classNameBox = "",
}: Props) => {
  return (
    <div className={`max-w-sm w-full ${className} md:max-w-screen-xl`}>
      {(title || titleContinued) && (
        <h2 className="mb-4 text-2xl font-semibold text-slate-900 leading-tight">
          <span className="text-slate-900">{title}</span>
          <span className="text-slate-500">{` ${titleContinued}`}</span>
        </h2>
      )}
      <div className={`${classNameBox}`}>{children}</div>
    </div>
  );
};
