// @ts-nocheck
import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

export const Tawk = React.forwardRef((props: any, ref) => {
  const onLoad = () => {
    if (props.onLoad) {
      props.onLoad(ref.current);
    }
  };

  const onChatStarted = () => {
    if (props.onChatStarted) {
      props.onChatStarted(ref.current);
    }
  };

  const onChatEnded = () => {
    if (props.onChatEnded) {
      props.onChatEnded(ref.current);
    }
  };

  return (
    <TawkMessengerReact
      ref={ref}
      propertyId="62fb9da954f06e12d88ef33b"
      widgetId="1gajdvo9i"
      onLoad={onLoad}
      onChatStarted={onChatStarted}
      onChatEnded={onChatEnded}
    />
  );
});
