import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const ProductsBatteries = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="py-24">
      <div className="mx-6 md:mx-auto max-w-screen-lg">
        <h1 className="uppercase ltr:tracking-widest mb-4 text-center">
          {content.h1[locale]}
        </h1>
        <h2 className="text-3xl font-light ltr:tracking-widest mb-3 text-center">
          {content.h2[locale]}
        </h2>
        <div className="mt-16 md:flex gap-6">
          <div className="flex-1 flex items-center justify-center">
            <img
              alt="Jinko Residential ESS"
              className="aspect-square object-contain"
              src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2FJinko-ESS.png?alt=media&token=c5f33263-ef64-421c-aeab-3dd1734d6a56"
            />
          </div>
          <div className="flex-1 bg-gray-100 rounded-2xl shadow-sm">
            <div className="px-12 py-6 border-b-2 border-white">
              <div className="flex justify-between text-xl font-semibold ltr:tracking-widest">
                <p>{content.heroTitle[locale]}</p>
                <p>10.24kWh</p>
              </div>
            </div>
            <div className="px-12 py-6 border-b-2 border-white">
              <p className="mb-3 font-semibold">
                {content.featureTitle[locale]}
              </p>
              <p className="mb-3">{content.featureDescription[locale]}</p>
              <ul className="list-disc list-inside">
                <li>{content.featureBullet1[locale]}</li>
                <li>{content.featureBullet2[locale]}</li>
                <li>{content.featureBullet3[locale]}</li>
                <li>{content.featureBullet4[locale]}</li>
                <li>{content.featureBullet5[locale]}</li>
              </ul>
            </div>
            <div className="px-12 py-6">
              <a
                href="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/documents%2Fjinko-ess-datasheet.pdf?alt=media&token=70c0195a-5df5-4409-ac87-f527f0b069ff"
                target="_blank"
                rel="noreferrer"
                className="mt-4 w-full flex items-center justify-center bg-gray-800 hover:bg-amber-600 transition-all text-white py-3 px-4 rounded"
              >
                {content.button[locale]}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
