import {
  doc,
  getDoc,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { RootState } from "../store/config";
import { ICity } from "../types/city";
import { ICountry } from "../types/country";

type IConverterType = RootState["cpq"]["commercial"]["config"] &
  RootState["cpq"]["commercial"]["customer"] & {
    uuid: string;
    type: "commercial";
    created: number;
    country: ICountry;
    city: ICity;
  };

const converter = <T>() => ({
  toFirestore: (data: PartialWithFieldValue<T>) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
});

export const getCommercialConfig = async (
  uuid: string
): Promise<IConverterType | null> => {
  // Set with converter
  const ref = doc(db, "configs", uuid).withConverter(
    converter<IConverterType>()
  );
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    const data = docSnap.data();
    return data;
  } else {
    // doc.data() will be undefined in this case
    return null;
  }
};
