import ReactGA from "react-ga4";

type Actions = "n/a" | "click" | "scroll";

export const useAnalyticsEventTracker = () => {
  const eventTracker = (
    action: Actions = "n/a",
    label: string = "n/a",
    category: string = "n/a"
  ) => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};
