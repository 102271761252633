export const content = {
  h1: {
    en: "Our Products",
    ar: "منتجاتنا",
    ku: "پيداويستيە کان مان",
  },
  h2: {
    en: "We make your choice easy - get the best products to the most competitive prices",
    ar: "نجعل اختيارك سهلاً - احصل على أفضل المنتجات بأسعار تنافسية",
    ku: "ئێمە هەڵبژاردنی ئێوە ئاسان دەکەین - باشترین بەرهەمەکان بەدەست بهێنە بە کێبڕکێکارترین نرخ",
  },
  pointTitle1: {
    en: "Fast delivery.",
    ar: "توصيل سريع.",
    ku: "گە ياندنی خێرا",
  },
  pointDecription1: {
    en: "Get your products within days of ordering.",
    ar: "احصل على منتجاتك في غضون أيام من الطلب.",
    ku: "لە ماوەی چەند ڕۆژێکدا پيداويستيە کانت بەدەست بهێنە دوای داواکردن",
  },
  pointTitle2: {
    en: "Warranty.",
    ar: "ضمان.",
    ku: "گەرەنتی",
  },
  pointDecription2: {
    en: "Get long-term product warranty for peace of mind.",
    ar: "احصل على ضمان طويل الأمد للمنتج من أجل راحة البال.",
    ku: "گەرەنتی درێژخایەنی بەرهەم بەدەست بهێنە بۆ ئارامی دەروونی",
  },
  pointTitle3: {
    en: "Cream of the crop.",
    ar: "زبدة المحصول أو كريمة المحصول.",
    ku: "باشترین لە باشترین",
  },
  pointDecription3: {
    en: "Get the best products for the best prices, always.",
    ar: "احصل دائمًا على أفضل المنتجات بأفضل الأسعار.",
    ku: "باشترین بەرهەم بەدەست بێنە بە باشترین نرخ هەمیشە",
  },
};
