import { Transition } from "@headlessui/react";
import { useState } from "react";
import { IoBookmarkOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import wNumb from "wnumb";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { RootState } from "../../../store/config";
import { Header } from "../../../common/Header";
import { DynamicHeader } from "../../../components/DynamicHeader";
import { InfoModal } from "../../../components/InfoModal";
import { SingleColumn } from "../../../components/SingleColumn";
import { Sticky2xColumns } from "../../../components/Sticky2xColumns";
import { ConfigurationInputSection } from "./sections/configuration.input.section";
import { ConfigurationVisualSection } from "./sections/configuration.visual.section";
import { ConsumptionInputSection } from "./sections/consumption.input.section";
import { ConsumptionVisualSection } from "./sections/consumption.visual.section";
import { ContactInputSection } from "./sections/contact.input.section";
import { DetailsInputSection } from "./sections/details.input.section";
import { FootprintInputSection } from "./sections/footprint.input.section";
import { FootprintVisualSection } from "./sections/footprint.visual.section";
import { GridUptimeInputSection } from "./sections/grid-uptime.input.section";
import { GridUptimeVisualSection } from "./sections/grid-uptime.visual.section";
import { LocationInputSection } from "./sections/location.input.section";
import { LocationVisualSection } from "./sections/location.visual.section";
import { OrientationInputSection } from "./sections/orientation.input.section";
import { SegmentInputSection } from "./sections/segment.input.section";
import { SegmentVisualSection } from "./sections/segment.visual.section";
import { CommercialSimulationSection } from "./sections/simulation.section";
import { DataPreparer } from "./wrapper/data-preparer";
import { Helmet } from "react-helmet-async";
import { Link, ScrollRestoration } from "react-router-dom";
import { content } from "./content";
import { useDispatch } from "react-redux";
import { bookmarkCommercial } from "../../../store/slices/cpq/commercial";
import { getUUID } from "../../../helpers/memory/get-uuid";

export const CPQCommercial = () => {
  const dispatch = useDispatch();

  const { height } = useWindowDimensions();
  const { locale } = useSelector((state: RootState) => state.localization);
  const uuid = useSelector((state: RootState) => state.cpq.commercial.uuid);
  const [activeSection, setActiveSection] = useState(1);

  const formatter_usd = wNumb({ decimals: 0, prefix: "$", thousand: "," });
  const formatter_precise_usd = wNumb({
    decimals: 2,
    prefix: "$",
    thousand: ",",
  });

  const price = useSelector(
    (state: RootState) => state.cpq.commercial.config.price
  );

  const segment = useSelector(
    (state: RootState) => state.cpq.commercial.config.segment
  );

  const bookmark = () => {
    if (!uuid) {
      dispatch(bookmarkCommercial({ uuid: getUUID() }));
    } else {
      dispatch(bookmarkCommercial({ uuid }));
    }
  };

  let src = "";
  switch (segment) {
    case "commercial building":
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fcommercial.webp?alt=media&token=01c5ca82-36d1-4cc0-ba9e-ea692a8de009";
      break;
    case "industrial":
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Findustrial.webp?alt=media&token=92ab6744-6a69-40d2-88ee-f278c12802c5";
      break;
    case "warehouse":
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fwarehouse.webp?alt=media&token=0db45154-4b89-4684-a4cb-926b789cd1f0";
      break;
    case "governmental":
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fgovernmental.webp?alt=media&token=80c7aa38-e7e1-40a2-b3fc-a3031c702b22";
      break;
    case "agriculture":
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fagriculture.webp?alt=media&token=25fdcce0-8250-4920-b5fc-906ed828a25c";
      break;
    default:
      break;
  }

  return (
    <DataPreparer>
      <Helmet>
        <title>Commercial Solar Solution</title>
        <meta
          name="description"
          content="Get a free quote for a commercial solar system that shows how much you're saving on your electricity bill."
        />
      </Helmet>
      <ScrollRestoration />
      <Header relative />
      <div className="pt-24 w-full md:max-w-[87.5%] mx-auto">
        <DynamicHeader
          preface={content.h2[locale]}
          postface={`${formatter_usd.to(price.total)} ${
            content.headerPostfaceOr[locale]
          } ${formatter_usd.to((price.total * 1.2) / 24)}${
            content.headerPostface[locale]
          }`}
        >
          {content.h1[locale]}
        </DynamicHeader>
        <div className="w-full max-w-sm md:max-w-full mx-auto">
          <Sticky2xColumns
            title={content.location.title[locale]}
            titleContinued={content.location.titleContinued[locale]}
            stickySide="left"
            className="pt-12"
            topPositionClass="md:top-36 max-h-56 w-full h-full"
            leftChildren={<LocationVisualSection />}
            rightChildren={<LocationInputSection />}
          />
        </div>
        <div className="md:flex relative">
          <div className="flex-1 hidden md:flex relative">
            <div
              className={`sticky w-full h-full top-[150px] pr-0 ltr:md:pr-20 rtl:md:pl-20 overflow-hidden`}
              style={{ maxHeight: `calc(${height}px - 200px)` }}
            >
              <div className="w-full h-full bg-gray-50 rounded-2xl">
                <Transition
                  as={"div"}
                  show={activeSection === 1}
                  enter="ease-in-out transition-opacity duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out transition-opacity duration-250"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={"w-full h-full"}
                >
                  <SegmentVisualSection />
                </Transition>
                <Transition
                  as={"div"}
                  show={activeSection === 2}
                  enter="ease-in-out transition-opacity duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out transition-opacity duration-250"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={"w-full h-full"}
                >
                  <ConsumptionVisualSection />
                </Transition>
                <Transition
                  as={"div"}
                  show={activeSection === 3}
                  enter="ease-in-out transition-opacity duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out transition-opacity duration-250"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={"w-full h-full"}
                >
                  <GridUptimeVisualSection />
                </Transition>
                <Transition
                  as={"div"}
                  show={activeSection === 4 || activeSection === 5}
                  enter="ease-in-out transition-opacity duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out transition-opacity duration-250"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={`w-full h-full`}
                >
                  <FootprintVisualSection />
                </Transition>
                <Transition
                  as={"div"}
                  show={activeSection === 6}
                  enter="ease-in-out transition-opacity duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out transition-opacity duration-250"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={"w-full h-full"}
                >
                  <ConfigurationVisualSection />
                </Transition>
              </div>
            </div>
          </div>
          <div className="flex-1 max-w-sm mx-auto">
            <Sticky2xColumns
              title={content.segment.title[locale]}
              titleContinued={content.segment.titleContinued[locale]}
              stickySide="left"
              topPositionClass="md:top-36"
              hideStickyOnMD
              onActive={() => setActiveSection(1)}
              leftChildren={<SegmentVisualSection />}
              rightChildren={<SegmentInputSection />}
            />
            <Sticky2xColumns
              title={content.consumption.title[locale]}
              titleContinued={content.consumption.titleContinued[locale]}
              stickySide="left"
              topPositionClass="md:top-36"
              hideStickyOnMD
              onActive={() => setActiveSection(2)}
              leftChildren={<ConsumptionVisualSection />}
              rightChildren={<ConsumptionInputSection />}
            />
            <Sticky2xColumns
              title={content.gridUptime.title[locale]}
              titleContinued={content.gridUptime.titleContinued[locale]}
              stickySide="left"
              topPositionClass="md:top-36"
              hideStickyOnMD
              onActive={() => setActiveSection(3)}
              leftChildren={<GridUptimeVisualSection />}
              rightChildren={<GridUptimeInputSection />}
            />
            <Sticky2xColumns
              title={content.footprint.title[locale]}
              titleContinued={content.footprint.titleContinued[locale]}
              stickySide="left"
              topPositionClass="md:top-36"
              hideStickyOnMD
              onActive={() => setActiveSection(4)}
              leftChildren={<FootprintVisualSection />}
              rightChildren={<FootprintInputSection />}
            />
            <Sticky2xColumns
              title={content.orientation.title[locale]}
              titleContinued={content.orientation.titleContinued[locale]}
              stickySide="left"
              topPositionClass="md:top-36"
              hideStickyOnMD
              onActive={() => setActiveSection(5)}
              leftChildren={null}
              rightChildren={<OrientationInputSection />}
            />
            <Sticky2xColumns
              title={content.configuration.title[locale]}
              titleContinued={content.configuration.titleContinued[locale]}
              stickySide="left"
              topPositionClass="md:top-36"
              hideStickyOnMD
              onActive={() => setActiveSection(6)}
              leftChildren={<ConfigurationVisualSection />}
              rightChildren={<ConfigurationInputSection />}
            />
          </div>
        </div>
        <SingleColumn className="mt-48 mx-auto">
          <CommercialSimulationSection />
        </SingleColumn>
        <SingleColumn
          title={content.details.title[locale]}
          titleContinued={content.details.titleContinued[locale]}
        >
          <DetailsInputSection />
        </SingleColumn>
        <SingleColumn
          title={content.contact.title[locale]}
          titleContinued={content.contact.titleContinued[locale]}
        >
          <ContactInputSection />
        </SingleColumn>
      </div>
      <div className="py-24 w-full bg-gray-50">
        <div className="px-6 md:px-0 md:flex max-w-sm md:w-[87.5%] md:max-w-screen-md mx-auto">
          <div className="ltr:md:pr-12 rtl:md:pl-12 md:flex-1">
            <h2 className="mb-4 text-2xl font-semibold text-slate-900 leading-tight">
              <span className="text-slate-900">
                {content.finish.title[locale]}
              </span>
              <span className="text-slate-500">
                {content.finish.titleContinued[locale]}
              </span>
            </h2>
            <img
              className="w-full h-60 object-cover rounded-xl"
              alt=""
              src={src}
            />
          </div>
          <div className="w-full max-w-xs mt-4 md:mt-0">
            <p className="text-lg pb-1.5">
              {content.finish.secondaryTitle[locale]}
            </p>
            <p className="font-semibold text-lg">{`${formatter_precise_usd.to(
              price.total
            )}`}</p>
            <button className="mt-11 py-3 px-6 bg-blue-600 leading-5 text-white rounded-lg min-w-full">
              {content.finish.button[locale]}
            </button>
            <div className="w-full mt-4 pt-4 flex items-center border-t border-gray-200">
              <div className="w-6 flex items-center justify-center">
                <InfoModal
                  linkText={
                    <IoBookmarkOutline
                      size={24}
                      className="text-blue-600 cursor-pointer"
                      onClick={bookmark}
                    />
                  }
                  title={"Bookmark"}
                  children={
                    <div className="flex-1 p-10 bg-gray-100">
                      <p className="font-semibold mb-1.5">Save link below</p>
                      <Link
                        to={`/${locale}/cpq/commercial/${uuid}`}
                        className="hover:underline underline-offset-4"
                      >{`www.toptaqa.com/${locale}/cpq/commercial/${uuid}`}</Link>
                    </div>
                  }
                />
              </div>
              <p className="ltr:ml-2 rtl:mr-2 text-sm">
                <b className="font-semibold">
                  {content.finish.bookmarkHighlight[locale]}
                </b>
                {content.finish.bookmarkRegular[locale]}
              </p>
            </div>
          </div>
        </div>
      </div>
    </DataPreparer>
  );
};
