import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { ICountry } from "../types/country";
import { ICity } from "../types/city";
import { ILocation } from "../types/location";

const converter = {
  toFirestore: (data: ILocation) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as ILocation,
};

export const getLocation = async (
  country: ICountry,
  city: ICity
): Promise<ILocation | null> => {
  // Query Params
  const collectionRef = collection(db, "locations");
  const filterCountry = where("country", "==", country.toLocaleLowerCase());
  const filterCity = where("city", "==", city.toLocaleLowerCase());
  const q = query(
    collectionRef,
    filterCountry,
    filterCity,
    limit(1)
  ).withConverter(converter);
  // Fetch
  const querySnapshot = await getDocs(q);
  // Controls
  const locations = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  const location = locations.pop();

  if (!location?.coordinates.latitude) return null;

  const modified = {
    ...location,
    id: location.id,
    coordinates: {
      latitude: location?.coordinates.latitude
        ? location?.coordinates.latitude
        : null,
      longitude: location?.coordinates.longitude
        ? location?.coordinates.longitude
        : null,
    },
  };

  return modified;
};
