export const content = {
  title: {
    en: "Our Partners",
    ar: "شركاؤنا",
    ku: "هاوبەشەکانمان",
  },
  button: {
    en: "Become a Partner",
    ar: "كن شريكا",
    ku: "ببە بە هاوبەش",
  },
};
