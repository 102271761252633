import { createListenerMiddleware } from "@reduxjs/toolkit";
import { RootState } from "../config";
import { getCommercialConfigAction } from "../slices/cpq/commercial";
import { setCity, setCountry } from "../slices/location";

// Create the middleware instance and methods
export const commercialConfigLoadListener = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
commercialConfigLoadListener.startListening({
  actionCreator: getCommercialConfigAction.fulfilled,
  effect: async (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();
    const state = listenerApi.getState() as RootState;

    const { selectedCountry, selectedCity } = state.location;
    if (action.payload) {
      const { country, city } = action.payload;
      if (selectedCountry === country && selectedCity === city) {
        return;
      }
      const setCountryPromise = listenerApi.dispatch(setCountry(country));
      const setCityPromise = listenerApi.dispatch(setCity(city));

      await Promise.all([setCountryPromise, setCityPromise]);
    }
  },
});
