import {
  doc,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { RootState } from "../store/config";
import { ICity } from "../types/city";
import { ICountry } from "../types/country";

type IConverterType = RootState["cpq"]["commercial"]["config"] &
  RootState["cpq"]["commercial"]["customer"] & {
    uuid: string;
    type: "commercial";
    created: number;
    country: ICountry;
    city: ICity;
  };

const converter = <T>() => ({
  toFirestore: (data: PartialWithFieldValue<T>) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
});

export const setCommercialConfig = async (
  uuid: string,
  config: RootState["cpq"]["commercial"]["config"],
  country: ICountry,
  city: ICity
): Promise<void> => {
  // Set with converter
  const ref = doc(db, "configs", uuid).withConverter(
    converter<IConverterType>()
  );
  await setDoc(
    ref,
    { ...config, created: Date.now(), type: "commercial", uuid, country, city },
    { merge: true }
  );
};
