import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const ProductsDelivery = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="bg-gray-100 py-24">
      <div className="mx-6 md:mx-auto max-w-screen-lg">
        <h1 className="uppercase ltr:tracking-widest mb-4 text-center">
          {content.h1[locale]}
        </h1>
        <h2 className="text-3xl font-light ltr:tracking-widest mb-3 text-center">
          {content.h2[locale]}
        </h2>
        <div className="mt-16 h-96 w-full flex justify-center">
          <img
            className="aspect-square"
            alt="Iraq"
            src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Firaq%20map.png?alt=media&token=5f782220-2795-451b-ba83-e70e8ff0aec4"
          />
        </div>
      </div>
    </div>
  );
};
