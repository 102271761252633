import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../store/config";
import { content } from "./content";
import { SolutionsCard } from "./solutions.card";

export const Solutions = () => {
  const { lang } = useParams();
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="py-24 bg-gray-100">
      <div className="mx-6 md:mx-16 lg:mx-auto max-w-screen-lg">
        <h1 className="uppercase ltr:tracking-widest mb-4 text-center">
          {content.h1[locale]}
        </h1>
        <h2 className="text-3xl font-light ltr:tracking-widest mb-3 text-center">
          {content.h2[locale]}
        </h2>
        <div className="mt-16 flex flex-col gap-8">
          <div className="flex flex-col md:flex-row gap-8">
            <SolutionsCard
              title={content.residentialTitle[locale]}
              subtitle={content.residentialSubtitle[locale]}
              content={content.residentialContent[locale]}
              img1920={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1920-1080%2Ftilted-house-modern-day.webp?alt=media&token=5ae63c3e-0c82-4b2b-943d-08e8004c308d"
              }
              img1080={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Ftilted-house-modern-day.webp?alt=media&token=82b4ee87-9894-43e8-8659-f4c962f6ea57"
              }
              img720={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F720-405%2Ftilted-house-modern-day.webp?alt=media&token=10f77546-1ecd-4ee1-9fb9-fd5c9e583582"
              }
              imgAlt={"Residential Solar"}
              href={`${lang}/cpq/residential`}
              className="w-full lg:w-1/2"
            />
            <SolutionsCard
              title={content.commercialTitle[locale]}
              subtitle={content.commercialSubtitle[locale]}
              content={content.commercialContent[locale]}
              img1920={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1920-1080%2Fcommercial.webp?alt=media&token=301949be-e593-416b-9e8f-3bbe2db46ec5"
              }
              img1080={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fcommercial.webp?alt=media&token=01c5ca82-36d1-4cc0-ba9e-ea692a8de009"
              }
              img720={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F720-405%2Fcommercial.webp?alt=media&token=e9962213-1d7f-4046-a56d-b5ac804663ce"
              }
              imgAlt={"Commercial Solar"}
              href={`${lang}/cpq/commercial`}
              className="w-full lg:w-1/2"
            />
          </div>
          <div className="flex flex-col md:flex-row gap-8">
            <SolutionsCard
              title={content.consultingTitle[locale]}
              subtitle={content.consultingSubtitle[locale]}
              content={content.consultingContent[locale]}
              img1920={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1920-1080%2Fproject-management.webp?alt=media&token=7b4c5741-bfbe-4b4a-82a1-5a703a792fad"
              }
              img1080={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fproject-management.webp?alt=media&token=88c611cc-bb47-41a6-ba50-92aa9e406817"
              }
              img720={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F720-405%2Fproject-management.webp?alt=media&token=e6207682-2184-47e5-ac64-decc33ad869f"
              }
              imgAlt={"Consulting"}
              href={"mailto:info@toptaqa.com?subject=Consulting Service"}
              className="w-full lg:w-1/3"
            />
            <SolutionsCard
              title={content.electricalTitle[locale]}
              subtitle={content.electricalSubtitle[locale]}
              content={content.electricalContent[locale]}
              img1920={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1920-1080%2Felectrical.webp?alt=media&token=6d711588-5543-4973-a86f-0b3f22e4237c"
              }
              img1080={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Felectrical.webp?alt=media&token=41d9c8a7-a6cc-4353-9648-484af865c891"
              }
              img720={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F720-405%2Felectrical.webp?alt=media&token=d5db65d0-ba2e-40c4-b23f-fbba35f7f648"
              }
              imgAlt={"Electrical Services"}
              href={"mailto:info@toptaqa.com?subject=Electrical Service"}
              className="w-full lg:w-1/3"
            />
            <SolutionsCard
              title={content.apiTitle[locale]}
              subtitle={content.apiSubtitle[locale]}
              content={content.apiContent[locale]}
              img1920={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1920-1080%2Fdata-api.webp?alt=media&token=07db0a65-b528-4dc7-9e33-0f0bcf49a96e"
              }
              img1080={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fdata-api.webp?alt=media&token=91dabaf0-57b6-4fd0-a5a1-91276ff490ee"
              }
              img720={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F720-405%2Fdata-api.webp?alt=media&token=22386235-234f-4796-8899-df5edc4bfd04"
              }
              imgAlt={"Data API"}
              href={"mailto:info@toptaqa.com?subject=Data API"}
              className="w-full lg:w-1/3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
