import { useSelector } from "react-redux";
import { RootState } from "../../../../store/config";

export const PanelsVisualSection = () => {
  const panelSelected = useSelector(
    (state: RootState) => state.cpq.residential.config.panel
  );

  return (
    <div className="w-full aspect-video min-h-[246px] max-w-full md:h-full md:aspect-auto bg-gray-50 md:p-20 rounded-2xl relative shadow-sm">
      <img
        className="w-full h-full object-contain"
        src={panelSelected?.img ? panelSelected.img : ""}
        alt={"Solar Panel"}
      />
      <div className="absolute p-2 md:p-4 flex rounded left-6 bottom-10 right-6 text-xs md:text-sm text-white font-medium bg-black/30 backdrop-blur-xl max-w-md mx-auto">
        <div className="flex flex-1 justify-center items-center">
          <p className="ml-2 capitalize">{`${panelSelected?.maxPowerWattage} Watt`}</p>
        </div>
        <div className="border-white/30 border-l-[1px] mx-3" />
        <div className="flex flex-1 justify-center items-center ">
          <p className="ml-2 capitalize">{`${panelSelected?.type}`}</p>
        </div>
      </div>
    </div>
  );
};
