import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const AboutValues = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="py-24">
      <div className="mx-6 md:mx-auto max-w-screen-lg">
        <h1 className="uppercase ltr:tracking-widest mb-4 text-center">
          {content.h1[locale]}
        </h1>
        <h2 className="text-3xl font-light ltr:tracking-widest mb-3 text-center">
          {content.h2[locale]}
        </h2>
        <div className="mt-16 p-12 grid grid-col-1 grid-rows-6 md:grid-cols-3 md:grid-rows-2 gap-x-12 gap-y-20">
          <div>
            <p className="text-xl">{content.title1[locale]}</p>
            <p className="mt-2 font-light">{content.description1[locale]}</p>
          </div>
          <div>
            <p className="text-xl">{content.title2[locale]}</p>
            <p className="mt-2 font-light">{content.description2[locale]}</p>
          </div>
          <div>
            <p className="text-xl">{content.title3[locale]}</p>
            <p className="mt-2 font-light">{content.description3[locale]}</p>
          </div>
          <div>
            <p className="text-xl">{content.title4[locale]}</p>
            <p className="mt-2 font-light">{content.description4[locale]}</p>
          </div>
          <div>
            <p className="text-xl">{content.title5[locale]}</p>
            <p className="mt-2 font-light">{content.description5[locale]}</p>
          </div>
          <div>
            <p className="text-xl">{content.title6[locale]}</p>
            <p className="mt-2 font-light">{content.description6[locale]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
