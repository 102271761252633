import DataSet from "@antv/data-set";
import { Chart, Axis, LineAdvance, Legend, Tooltip } from "bizcharts";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import colors from "tailwindcss/colors";
import wNumb from "wnumb";
import { RootState } from "../../../../store/config";

export const ConsumptionVisualSection = () => {
  const consumption = useSelector(
    (state: RootState) => state.cpq.commercial.config.consumption
  );

  const data = [...consumption].map((val, index) => ({
    consumption: val,
    month: index + 1,
  }));

  const ds = new DataSet();
  const dv = ds.createView().source(data);

  let maxConsumption =
    dv.rows.length > 0 ? Number(dv.max("consumption")) : 1000;
  if (maxConsumption === 0) {
    maxConsumption = 1000;
  }
  const scaleCfg = {
    consumption: {
      alias: "Consumption",
      tickCount: 10,
      min: 0,
      max: maxConsumption * 1.2,
      formatter: (v: number) =>
        wNumb({ suffix: " kWh", decimals: 0, thousand: "," }).to(v),
    },
    month: {
      tickCount: 12,
      formatter: (month: number) => {
        const date = new Date(1999, month - 1, 1).toISOString();
        return DateTime.fromISO(date).toFormat("MMM");
      },
    },
  };

  const grid = {
    line: {
      type: "line",
      style: {
        stroke: colors.gray[300],
        lineWidth: 0.25,
      },
    },
  };

  const line = {
    style: {
      stroke: colors.gray[700],
      lineWidth: 0.25,
    },
  };

  return (
    <div className="p-6 md:p-20 w-full h-full aspect-video md:aspect-auto bg-gray-50 rounded-2xl relative hidden md:block">
      <Chart autoFit scale={scaleCfg} data={dv}>
        <Axis grid={grid} name="consumption" line={line} />
        <Axis grid={grid} name="month" line={line} tickLine={{ length: 0 }} />
        <Tooltip showCrosshairs shared showMarkers />
        <Legend />
        <LineAdvance
          shape={"smooth"}
          position="month*consumption"
          point
          area
          size={0}
          tooltip={false}
          color={`l(270) 0:${colors.gray[400]} 1:${colors.gray[400]}`}
          style={{ fillOpacity: 0.25 }}
        />
        <LineAdvance
          shape={"smooth"}
          position="month*consumption"
          point
          size={3}
          color={`l(270) 0:${colors.blue[600]} 1:${colors.blue[600]}`}
          style={{ fillOpacity: 1 }}
        />
      </Chart>
    </div>
  );
};
