import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/config";
import {
  ICommercialSegment,
  setSegment,
} from "../../../../store/slices/cpq/commercial";
import { ProductSelect } from "../../../../components/ProductSelect";
import { content } from "../content";

export const SegmentInputSection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { locale } = useSelector((state: RootState) => state.localization);

  const segment = useSelector(
    (state: RootState) => state.cpq.commercial.config.segment
  );

  const handleSegmentChange = (segment: ICommercialSegment) => {
    dispatch(setSegment({ segment }));
  };
  return (
    <>
      <ProductSelect
        img={""}
        title={content.segment.labels.commercialBuilding[locale]}
        selected={segment === "commercial building"}
        onClick={() => handleSegmentChange("commercial building")}
        className={"mt-6"}
      />
      <ProductSelect
        img={""}
        title={content.segment.labels.industrial[locale]}
        selected={segment === "industrial"}
        onClick={() => handleSegmentChange("industrial")}
        className={"mt-5"}
      />
      <ProductSelect
        img={""}
        title={content.segment.labels.warehouse[locale]}
        selected={segment === "warehouse"}
        onClick={() => handleSegmentChange("warehouse")}
        className={"mt-5"}
      />
      <ProductSelect
        img={""}
        title={content.segment.labels.governmental[locale]}
        selected={segment === "governmental"}
        onClick={() => handleSegmentChange("governmental")}
        className={"mt-5"}
      />
      <ProductSelect
        img={""}
        title={content.segment.labels.agriculture[locale]}
        selected={segment === "agriculture"}
        onClick={() => handleSegmentChange("agriculture")}
        className={"mt-5"}
      />
    </>
  );
};
