import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { HeroBackdrop } from "./backdrop.component";
import { content } from "./content";
import { HeroH1 } from "./h1.component";
import { HeroH2 } from "./h2.component";
import { SelectLocation } from "./select-location.component";

export const Hero: React.FC = (props) => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div
      {...props}
      className="h-screen overflow-hidden relative overscroll-none flex justify-center items-center"
    >
      <picture className="w-full h-full object-cover absolute">
        <source
          srcSet="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F900-900%2Fsolar-panel-background-hero.webp?alt=media&token=8895d711-bfe1-4a2d-93ef-c81b18e77bf0"
          media="(max-width: 768px)"
        />
        <source
          srcSet="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1920-1080%2Fsolar-panel-background-hero.webp?alt=media&token=a832728b-4dd7-42cd-ac86-817616bab1ad"
          media="(min-width: 768px)"
        />
        <img className="w-full h-full object-cover" alt="Solar system hero" />
      </picture>
      <HeroBackdrop />
      <div className="w-full md:flex-grow md:flex justify-center px-6 xl:px-0">
        <div className="w-full h-full max-w-screen-lg justify-center flex flex-grow flex-col gap-4 items-start">
          <div className="ml-2 relative flex flex-col gap-2 max-w-screen-md">
            <HeroH1>{content.h1[locale]}</HeroH1>
            <HeroH2>{content.h2[locale]}</HeroH2>
          </div>
          <SelectLocation />
        </div>
      </div>
    </div>
  );
};
