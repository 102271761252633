import { Helmet } from "react-helmet-async";
import { ScrollRestoration } from "react-router-dom";
import { Footer } from "../../common/Footer";
import { Header } from "../../common/Header";
import { ProductsBatteries } from "./Batteries";
import { ProductsDelivery } from "./Delivery";
import { ProductsHero } from "./Hero";
import { ProductsPanels } from "./Panels";
import { ProductsPointers } from "./Pointers";

export const Products = () => {
  return (
    <>
      <Helmet>
        <title>Products</title>
        <meta
          name="description"
          content="The highest quality solar products and solutions in Iraq."
        />
      </Helmet>
      <ScrollRestoration />
      <Header autoChange />
      <ProductsHero />
      <ProductsPointers />
      <ProductsPanels />
      <ProductsBatteries />
      <ProductsDelivery />
      <Footer />
    </>
  );
};
