export const content = {
  h1: {
    en: "Solar Systems",
    ar: "النظام الشمسي",
    ku: "وزەی خۆر",
  },
  h2: {
    en: "Planning solar has never been easier. Configure online now",
    ar: "لم يكن التخطيط للطاقة الشمسية أسهل من أي وقت مضى. تكوين عبر الإنترنت الآن",
    ku: "پلاندانانی وزەی خۆر هەرگیز ئاسانتر نەبووە.  ئێستا بە شێوەی ئۆنلاین ڕێکبخە",
  },
  countryLabel: {
    en: "Country",
    ar: "بلد",
    ku: "وڵات",
  },
  cityLabel: {
    en: "City",
    ar: "مدينة",
    ku: "شار",
  },
  segmentLabel: {
    en: "Segment",
    ar: "مقطع",
    ku: "بەش",
  },
  configureButton: {
    en: "Configure",
    ar: "إبدأ",
    ku: "ڕێکخستن",
  },
};
