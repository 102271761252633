import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../config";
import { setPrice, setSystem } from "../slices/cpq/residential";

// Create the middleware instance and methods
export const residentialPriceTrigger = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
residentialPriceTrigger.startListening({
  matcher: isAnyOf(setSystem),
  effect: (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    const state = listenerApi.getState() as RootState;

    const panel = state.cpq.residential.config.panel;
    const system = state.cpq.residential.config.system;

    if (panel) {
      // Prices
      const ARRAY_PRICE_PER_WATT = panel.price.usd;
      const ESS_PRICE_PER_WH = 0.6;
      const INVERTER_PRICE_PER_WATT = 0.2;

      const arrayPrice = ARRAY_PRICE_PER_WATT * system.arraySizeW;
      const inverterPrice = INVERTER_PRICE_PER_WATT * system.arraySizeW;
      const essPrice = ESS_PRICE_PER_WH * system.batterySizeWH;

      const totalPrice = arrayPrice + inverterPrice + essPrice;

      // First panel in the offering list
      const price = {
        array: arrayPrice,
        inverter: inverterPrice,
        ess: essPrice,
        total: totalPrice,
      };

      listenerApi.dispatch(setPrice({ price: price }));
    }
  },
});
