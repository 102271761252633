import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Logo } from "../../components/Logo";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { LanguageMenu } from "./language-menu";
import { NavDesktop } from "./nav.desktop";
import { NavMobile } from "./nav.mobile";

type Props = {
  relative?: boolean;
  autoChange?: boolean;
  className?: string;
};

export const Header: React.FC<Props> = (props) => {
  const { lang } = useParams();

  const { screenSize } = useWindowDimensions();
  const [transparent, setTransparent] = useState(true);
  const SCROLL_BREAKPOINT =
    screenSize === "sm" || screenSize === "md" ? 40 : 188;

  const handleScroll = () => {
    if (!props.autoChange) return;
    const turnTransparent = window.pageYOffset < SCROLL_BREAKPOINT;
    setTransparent(turnTransparent);
  };

  useEffect(() => {
    if (!props.autoChange) setTransparent(false);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`w-full py-4 z-50 font-medium justify-center md:flex-grow md:flex px-6 xl:px-0 ${
        transparent ? "" : "bg-white shadow-md"
      } ${props.relative ? "" : "fixed"} ${props.className}`}
    >
      <div className="w-full max-w-screen-lg flex justify-between items-center relative">
        <Link
          to={`/${lang}`}
          rel="canonical"
          aria-label="Navigate to home page"
        >
          <Logo fill={`${transparent ? "white" : "#0f172a"}`} />
        </Link>
        {/* Small Screen Hamburger Menu */}
        <div className="flex items-center">
          <NavDesktop transparent={transparent} className={"hidden lg:flex"} />
          <LanguageMenu />
          <NavMobile transparent={transparent} className={"flex lg:hidden"} />
        </div>
      </div>
    </header>
  );
};
