import styled from "styled-components";

const Styled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
`;

export const HeroBackdrop = () => {
  return <Styled />;
};
