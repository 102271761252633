import { IoBulbSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/config";

export const GridUptimeVisualSection = () => {
  const gridUptime = useSelector(
    (state: RootState) => state.cpq.commercial.config.gridUptime
  );

  return (
    <div
      className={`relative w-full h-full aspect-video shadow overflow-hidden rounded-2xl`}
    >
      <img
        alt="Grid Uptime"
        className="w-full h-full object-cover rounded-2xl"
        src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fstock%2FErbil-at-night.webp?alt=media&token=071b1d08-bbec-4879-8d40-953a2760bb45"
        style={{
          filter: `grayscale(${(1 - gridUptime) * 100}%) brightness(${
            (gridUptime * 0.5 + 0.5) * 100
          }%)`,
        }}
      />
      <div
        className={`absolute top-0 left-0 w-full h-full flex justify-center items-center  backdrop-blur-sm rounded-2xl`}
      >
        <div className="p-4 bg-black/50 backdrop-blur-3xl rounded-full">
          <IoBulbSharp
            size={40}
            className="text-white"
            style={{
              color: `rgba(255,255,255,${100 * (gridUptime * 0.5 + 0.5)}%)`,
            }}
          />
        </div>
      </div>
    </div>
  );
};
