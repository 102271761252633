import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HourNumbers, MonthNumbers } from "luxon";
import { api } from "../../../../api";
import { ICity } from "../../../../types/city";
import { ICountry } from "../../../../types/country";
import { IPanel } from "../../../../types/meta/offering/panel.type";
import { RootState } from "../../../config";

export type IPrice = {
  array: number;
  inverter: number;
  total: number;
};
export type IRoofMaxPanels = {
  row: number;
  col: number;
  total: number;
};

export type ISystem = {
  panelWatt: number;
  arraySizeW: number;
};
export type ICommercialSegment =
  | "commercial building"
  | "industrial"
  | "governmental"
  | "agriculture"
  | "warehouse";

export type ICommercialCustomer = {
  fullName: string;
  phoneNumber: string;
  address: string;
  email: string;
};

export type ICommercialSimulationData = Array<{
  datetime: string;
  date: string;
  year: number;
  month: MonthNumbers;
  hour: HourNumbers;
  dni: number;
  output_kwh: number;
  output_amp: number;
}>;

export type ICommercialSimulationAvgHourData = Array<{
  hour: HourNumbers;
  output: number;
}>;
interface State {
  uuid: string;
  setConfigApi: {
    pending: boolean;
    error: any;
  };
  getConfigApi: {
    created: number | null;
    pending: boolean;
    error: any;
  };
  customer: ICommercialCustomer;
  config: {
    currency: "usd";
    segment: ICommercialSegment;
    // Consumption
    consumption: number[];
    gridUptime: number;
    // Footprint
    roofWidth: number;
    roofLength: number;
    roofMaxPanels: IRoofMaxPanels;
    moduleOrientation: number;
    // Default
    moduleTilt: number;
    // Selection
    panel: IPanel | null;
    panelQty: number;
    system: ISystem;
    // Calculated
    price: IPrice;
  };
  // simulation
  avgDailyOutputPerPanel: number;
  simulationData: ICommercialSimulationData;
  simulationAvgHour: ICommercialSimulationAvgHourData;
}

export const getCommercialConfigAction = createAsyncThunk(
  "commercial/getCommercialConfig",
  async (
    param: {
      uuid: string;
    },
    thunkAPI
  ) => {
    try {
      const data = await api.getCommercialConfig(param.uuid);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const setCommercialConfigAction = createAsyncThunk(
  "commercial/setCommercialConfig",
  async (
    param: {
      uuid: string;
      customer: RootState["cpq"]["commercial"]["customer"];
      config: RootState["cpq"]["commercial"]["config"];
      country: ICountry;
      city: ICity;
    },
    thunkAPI
  ) => {
    try {
      await api.setCommercialConfig(
        param.uuid,
        param.config,
        param.country,
        param.city
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const commercialSlice = createSlice({
  name: "commercial",
  initialState: {
    uuid: "",
    setConfigApi: {
      pending: false,
      error: null,
    },
    getConfigApi: {
      created: null,
      pending: false,
      error: null,
    },
    customer: {
      fullName: "",
      phoneNumber: "",
      address: "",
      email: "",
    },
    config: {
      currency: "usd",
      segment: "commercial building",
      consumption: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      gridUptime: 0.65,
      roofWidth: 5,
      roofLength: 5,
      roofMaxPanels: {
        row: 3,
        col: 1,
        total: 3,
      },
      moduleOrientation: 180,
      moduleTilt: 30,
      panel: null,
      panelQty: 0,
      system: {
        panelWatt: 0,
        arraySizeW: 0,
      },
      price: {
        array: 0,
        inverter: 0,
        ess: 0,
        total: 0,
      },
    },
    avgDailyOutputPerPanel: 0,
    simulationData: [],
    simulationAvgHour: [],
  } as State,
  reducers: {
    setSegment: (
      state,
      action: PayloadAction<{ segment: ICommercialSegment }>
    ) => {
      const { segment } = action.payload;
      state.config.segment = segment;
    },
    setConsumption: (
      state,
      action: PayloadAction<{ consumption: number[] }>
    ) => {
      const { consumption } = action.payload;
      state.config.consumption = consumption;
    },
    setGridUptime: (state, action: PayloadAction<{ percent: number }>) => {
      const { percent } = action.payload;
      if (percent >= 0 && percent <= 1) {
        // 0-100%
        state.config.gridUptime = percent;
      }
    },
    setRoofWidth: (state, action: PayloadAction<{ width: number }>) => {
      const { width } = action.payload;
      state.config.roofWidth = width;
    },
    setRoofLength: (state, action: PayloadAction<{ length: number }>) => {
      const { length } = action.payload;
      state.config.roofLength = length;
    },
    setRoofMaxPanels: (
      state,
      action: PayloadAction<{ row: number; col: number; total: number }>
    ) => {
      const { row, col, total } = action.payload;
      state.config.roofMaxPanels = { row, col, total };
    },
    setModuleOrientation: (
      state,
      action: PayloadAction<{ orientation: number }>
    ) => {
      const { orientation } = action.payload;
      state.config.moduleOrientation = orientation;
    },
    setSystem: (state, action: PayloadAction<{ system: ISystem }>) => {
      const { system } = action.payload;

      state.config.system = system;
    },
    setPrice: (state, action: PayloadAction<{ price: IPrice }>) => {
      const { price } = action.payload;

      state.config.price = price;
    },
    setCommercialPanel: (state, action: PayloadAction<{ panel?: IPanel }>) => {
      const { panel } = action.payload;
      if (panel) {
        state.config.panel = panel;
      }
    },
    setPanelQty: (state, action: PayloadAction<{ qty: number }>) => {
      const { qty } = action.payload;

      state.config.panelQty = qty;
    },
    setCommercialCustomer: (
      state,
      action: PayloadAction<ICommercialCustomer>
    ) => {
      const { fullName, phoneNumber, address, email } = action.payload;

      state.customer.fullName = fullName;
      state.customer.phoneNumber = phoneNumber;
      state.customer.address = address;
      state.customer.email = email;
    },
    setCommmercialSimulationData: (
      state,
      action: PayloadAction<{
        data: ICommercialSimulationData;
      }>
    ) => {
      const { data } = action.payload;
      state.simulationData = data;
    },
    setCommercialSimulationAvgHourData: (
      state,
      action: PayloadAction<{
        data: ICommercialSimulationAvgHourData;
      }>
    ) => {
      const { data } = action.payload;
      state.simulationAvgHour = data;
    },
    bookmarkCommercial: (
      state,
      action: PayloadAction<{
        uuid: string;
      }>
    ) => {
      const { uuid } = action.payload;
      state.uuid = uuid;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCommercialConfigAction.pending, (state, action) => {
      state.setConfigApi.pending = true;
    });
    builder.addCase(setCommercialConfigAction.fulfilled, (state, action) => {
      state.setConfigApi.pending = false;
    });
    builder.addCase(setCommercialConfigAction.rejected, (state, action) => {
      state.setConfigApi.pending = false;
      state.setConfigApi.error = action.error;
    });
    builder.addCase(getCommercialConfigAction.pending, (state, action) => {
      state.getConfigApi.pending = true;
    });
    builder.addCase(getCommercialConfigAction.fulfilled, (state, action) => {
      state.getConfigApi.pending = false;
      if (action.payload) {
        state.getConfigApi.created = action.payload.created;
        state.uuid = action.payload.uuid;
        state.customer.fullName = action.payload.fullName;
        state.customer.phoneNumber = action.payload.phoneNumber;
        state.customer.address = action.payload.address;
        state.customer.email = action.payload.email;
        state.config.currency = action.payload.currency;
        state.config.segment = action.payload.segment;
        state.config.consumption = action.payload.consumption;
        state.config.gridUptime = action.payload.gridUptime;
        state.config.roofWidth = action.payload.roofWidth;
        state.config.roofLength = action.payload.roofLength;
        state.config.roofMaxPanels = action.payload.roofMaxPanels;
        state.config.moduleOrientation = action.payload.moduleOrientation;
        state.config.moduleTilt = action.payload.moduleTilt;
        state.config.panel = action.payload.panel;
        state.config.panelQty = action.payload.panelQty;
        state.config.system = action.payload.system;
        state.config.price = action.payload.price;
      }
    });
    builder.addCase(getCommercialConfigAction.rejected, (state, action) => {
      state.getConfigApi.pending = false;
      state.getConfigApi.error = action.error;
    });
  },
});

export const {
  setSegment,
  setConsumption,
  setGridUptime,
  setRoofWidth,
  setRoofLength,
  setRoofMaxPanels,
  setModuleOrientation,
  setCommercialPanel,
  setPanelQty,
  setSystem,
  setPrice,
  setCommercialCustomer,
  setCommmercialSimulationData,
  setCommercialSimulationAvgHourData,
  bookmarkCommercial,
} = commercialSlice.actions;
