import {
  collection,
  getDocs,
  orderBy,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { IHourly, IHourlyFirebase } from "../types/power/hourly";

const converter = {
  toFirestore: (data: IHourlyFirebase) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) =>
    snap.data() as IHourlyFirebase,
};

export const getHourly = async (
  locationId: string,
  year: number
): Promise<IHourly[] | null> => {
  // Query Params
  const collectionRef = collection(db, `locations/${locationId}/hourly`);
  const filterYear = where("year", "==", year);
  const orderByMonth = orderBy("month", "asc");
  const q = query(collectionRef, filterYear, orderByMonth).withConverter(
    converter
  );
  // Fetch
  const querySnapshot = await getDocs(q);

  // transform
  const ghiByHourlyArr = querySnapshot.docs.map((doc) => doc.data());

  const flatten = ghiByHourlyArr.map((obj) => obj.data).flat();

  return flatten;
};
