import { createListenerMiddleware } from "@reduxjs/toolkit";
import { RootState } from "../config";
import { setCommercialPanel } from "../slices/cpq/commercial";
import { setResidentialPanel } from "../slices/cpq/residential";
import { fetchOffering } from "../slices/meta/offering";

// Create the middleware instance and methods
export const offeringFetchedListener = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
offeringFetchedListener.startListening({
  actionCreator: fetchOffering.fulfilled,
  effect: (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    const state = listenerApi.getState() as RootState;

    if (action.payload) {
      // First panel in the offering list
      const firstPanel = action.payload.panels[0];
      if (!state.cpq.commercial.config.panel) {
        listenerApi.dispatch(setCommercialPanel({ panel: firstPanel }));
      }

      if (!state.cpq.residential.config.panel) {
        listenerApi.dispatch(setResidentialPanel({ panel: firstPanel }));
      }
    }
  },
});
