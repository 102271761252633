import { Footer } from "../../common/Footer";
import { Header } from "../../common/Header";
import { AboutHero } from "./Hero";
import { AboutMission } from "./Mission";
import { AboutPartner } from "./Partner";
import { Social } from "../../common/Social";
import { AboutStory } from "./Story";
import { AboutValues } from "./Values";
import { Helmet } from "react-helmet-async";
import { ScrollRestoration } from "react-router-dom";

export const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="Toptaqa is the main provider of high quality solar products and services in Iraq."
        />
      </Helmet>
      <ScrollRestoration />
      <Header autoChange />
      <AboutHero />
      <AboutMission />
      <AboutStory />
      <AboutValues />
      <AboutPartner />
      <Social />
      <Footer />
    </>
  );
};
