import { cosd } from "../math/cosd";
import { sind } from "../math/sind";

export const getSModule = (
  sIncident: number,
  elevationAngle: number,
  moduleTilt: number,
  solarAzimuth: number,
  moduleAzimuth: number
) => {
  const sModule =
    sIncident *
    (cosd(elevationAngle) *
      sind(moduleTilt) *
      cosd(solarAzimuth - moduleAzimuth) +
      sind(elevationAngle) * cosd(moduleTilt));

  return Math.max(sModule, 0);
};
