import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HourNumbers, MonthNumbers } from "luxon";
import { api } from "../../../../api";
import { ICity } from "../../../../types/city";
import { ICountry } from "../../../../types/country";
import { IPanel } from "../../../../types/meta/offering/panel.type";
import { RootState } from "../../../config";

export type IRoofSlop = "flat" | "tilted";
export type IPrice = {
  array: number;
  inverter: number;
  ess: number;
  total: number;
};
export type ISystem = {
  panelWatt: number;
  panelQty: number;
  arraySizeW: number;
  batterySizeWH: number;
};
export type IResidentialSimulationData = Array<{
  datetime: string;
  date: string;
  year: number;
  month: MonthNumbers;
  hour: HourNumbers;
  dni: number;
  output_kwh: number;
  output_amp: number;
}>;

export type IResidentialCustomer = {
  fullName: string;
  phoneNumber: string;
  address: string;
  email: string;
};

export type IResidentialSimulationAvgHourData = Array<{
  hour: HourNumbers;
  output: number;
}>;
interface State {
  uuid: string;
  setConfigApi: {
    pending: boolean;
    error: any;
  };
  getConfigApi: {
    created: number | null;
    pending: boolean;
    error: any;
  };
  customer: IResidentialCustomer;
  config: {
    currency: "usd";
    // Default
    moduleOrientation: number;
    moduleTilt: number;
    // Selection
    roofSlope: IRoofSlop;
    panel: IPanel | null;
    daySize: {
      amperes: number;
      hours: number;
    };
    nightSize: {
      amperes: number;
      hours: number;
    };
    // Calculated
    system: ISystem;
    price: IPrice;
  };
  // simulation
  avgDailyOutputPerPanel: number;
  simulationData: IResidentialSimulationData;
  simulationAvgHour: IResidentialSimulationAvgHourData;
}

export const getResidentialConfigAction = createAsyncThunk(
  "residential/getResidentialConfig",
  async (
    param: {
      uuid: string;
    },
    thunkAPI
  ) => {
    try {
      const data = await api.getResidentialConfig(param.uuid);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const setResidentialConfigAction = createAsyncThunk(
  "residential/setResidentialConfig",
  async (
    param: {
      uuid: string;
      customer: RootState["cpq"]["residential"]["customer"];
      config: RootState["cpq"]["residential"]["config"];
      country: ICountry;
      city: ICity;
    },
    thunkAPI
  ) => {
    try {
      await api.setResidentialConfig(
        param.uuid,
        param.config,
        param.country,
        param.city
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const residentialSlice = createSlice({
  name: "residential",
  initialState: {
    uuid: "",
    setConfigApi: {
      pending: false,
      error: null,
    },
    getConfigApi: {
      created: null,
      pending: false,
      error: null,
    },
    customer: {
      fullName: "",
      phoneNumber: "",
      address: "",
      email: "",
    },
    config: {
      currency: "usd",
      moduleOrientation: 180,
      moduleTilt: 30,
      roofSlope: "flat",
      panel: null,
      daySize: {
        amperes: 6,
        hours: 7,
      },
      nightSize: {
        amperes: 3,
        hours: 8,
      },
      system: {
        panelWatt: 0,
        panelQty: 0,
        arraySizeW: 0,
        batterySizeWH: 0,
      },
      price: {
        array: 0,
        inverter: 0,
        ess: 0,
        total: 0,
      },
    },
    avgDailyOutputPerPanel: 0,
    simulationData: [],
    simulationAvgHour: [],
  } as State,
  reducers: {
    setRoofSlope: (state, action: PayloadAction<{ slope: IRoofSlop }>) => {
      const { slope } = action.payload;
      state.config.roofSlope = slope;
    },
    setSystem: (state, action: PayloadAction<{ system: ISystem }>) => {
      const { system } = action.payload;

      state.config.system = system;
    },
    setPrice: (state, action: PayloadAction<{ price: IPrice }>) => {
      const { price } = action.payload;

      state.config.price = price;
    },
    setResidentialPanel: (state, action: PayloadAction<{ panel?: IPanel }>) => {
      const { panel } = action.payload;
      if (panel) {
        state.config.panel = panel;
      }
    },
    setDaySizeAmperes: (state, action: PayloadAction<{ amperes: number }>) => {
      const { amperes } = action.payload;
      state.config.daySize.amperes = amperes;
    },
    setDaySizeHours: (state, action: PayloadAction<{ hours: number }>) => {
      const { hours } = action.payload;
      state.config.daySize.hours = hours;
    },
    setNightSizeAmperes: (
      state,
      action: PayloadAction<{ amperes: number }>
    ) => {
      const { amperes } = action.payload;
      state.config.nightSize.amperes = amperes;
    },
    setNightSizeHours: (state, action: PayloadAction<{ hours: number }>) => {
      const { hours } = action.payload;
      state.config.nightSize.hours = hours;
    },
    setResidentialCustomer: (
      state,
      action: PayloadAction<IResidentialCustomer>
    ) => {
      const { fullName, phoneNumber, address, email } = action.payload;

      state.customer.fullName = fullName;
      state.customer.phoneNumber = phoneNumber;
      state.customer.address = address;
      state.customer.email = email;
    },
    setResidentialSimulationData: (
      state,
      action: PayloadAction<{
        data: IResidentialSimulationData;
      }>
    ) => {
      const { data } = action.payload;
      state.simulationData = data;
    },
    setResidentialSimulationAvgHourData: (
      state,
      action: PayloadAction<{
        data: IResidentialSimulationAvgHourData;
      }>
    ) => {
      const { data } = action.payload;
      state.simulationAvgHour = data;
    },

    setAvgDailyOutputPerPanel: (
      state,
      action: PayloadAction<{
        avgDailyOutputPerPanel: number;
      }>
    ) => {
      const { avgDailyOutputPerPanel } = action.payload;
      state.avgDailyOutputPerPanel = avgDailyOutputPerPanel;
    },
    bookmarkResidential: (
      state,
      action: PayloadAction<{
        uuid: string;
      }>
    ) => {
      const { uuid } = action.payload;
      state.uuid = uuid;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setResidentialConfigAction.pending, (state, action) => {
      state.setConfigApi.pending = true;
    });
    builder.addCase(setResidentialConfigAction.fulfilled, (state, action) => {
      state.setConfigApi.pending = false;
    });
    builder.addCase(setResidentialConfigAction.rejected, (state, action) => {
      state.setConfigApi.pending = false;
      state.setConfigApi.error = action.error;
    });
    builder.addCase(getResidentialConfigAction.pending, (state, action) => {
      state.getConfigApi.pending = true;
    });
    builder.addCase(getResidentialConfigAction.fulfilled, (state, action) => {
      state.getConfigApi.pending = false;
      if (action.payload) {
        state.getConfigApi.created = action.payload.created;
        state.uuid = action.payload.uuid;
        state.customer.fullName = action.payload.fullName;
        state.customer.phoneNumber = action.payload.phoneNumber;
        state.customer.address = action.payload.address;
        state.customer.email = action.payload.email;
        state.config.currency = action.payload.currency;
        state.config.moduleOrientation = action.payload.moduleOrientation;
        state.config.moduleTilt = action.payload.moduleTilt;
        state.config.roofSlope = action.payload.roofSlope;
        state.config.panel = action.payload.panel;
        state.config.daySize = action.payload.daySize;
        state.config.nightSize = action.payload.nightSize;
        state.config.system = action.payload.system;
        state.config.price = action.payload.price;
      }
    });
    builder.addCase(getResidentialConfigAction.rejected, (state, action) => {
      state.getConfigApi.pending = false;
      state.getConfigApi.error = action.error;
    });
  },
});

export const {
  setRoofSlope,
  setResidentialPanel,
  setSystem,
  setPrice,
  setDaySizeAmperes,
  setDaySizeHours,
  setNightSizeAmperes,
  setNightSizeHours,
  setResidentialCustomer,
  setResidentialSimulationData,
  setResidentialSimulationAvgHourData,
  setAvgDailyOutputPerPanel,
  bookmarkResidential,
} = residentialSlice.actions;
