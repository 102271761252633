export const content = {
  h1: {
    en: "Become our partner",
    ar: "كن شريكنا",
    ku: "ببە بە هاوبەشی ئێمە",
  },
  h2: {
    en: "Join our network and solve Iraq's electricity problem with us",
    ar: "انضم إلى شبكتنا وحل مشكلة الكهرباء في العراق معنا",
    ku: "بەشداری تۆڕەکەمان بکەن و کێشەی کارەبا لە عێراق لەگەڵ ئێمە چارەسەر بکەن",
  },
  card1Title: {
    en: "Become a reseller",
    ar: "كن موزعًا",
    ku: "ببە بە فرۆشيار",
  },
  card1Description: {
    en: "Take part of a world-class supply chain for solar products with quality controlled products and verified suppliers.",
    ar: "شارك في سلسلة التوريد ذات المستوى العالمي لمنتجات الطاقة الشمسية مع منتجات مراقبة الجودة والموردين المعتمدين.",
    ku: "بەشێک لە زنجیرەیەکی دابینکردنی ئاست بەرزی جیهانی بۆ بەرهەمی وزە ی خۆر بە بەرهەمە کۆنترۆڵکراوەکانی کوالیتی و دابینکەرانی پشتڕاستکراوە.",
  },
  card1Button: {
    en: "Email Us",
    ar: "ارسل لنا عبر البريد الإلكتروني",
    ku: "نامە ی ئەلکترۆنی بنئرە",
  },
  card2Title: {
    en: "Become an installer",
    ar: "كن مثبّتًا",
    ku: "ببە بە دامەزرێنەر",
  },
  card2Description: {
    en: "Install solar systems that are pre-configured to fully meet the customer's needs and requirements today.",
    ar: "قم بتثبيت أنظمة الطاقة الشمسية التي تم تكوينها مسبقًا لتلبية احتياجات ومتطلبات العميل بشكل كامل اليوم.",
    ku: "دانانی سیستەمی خۆر کە پێشوەختە ڕێکخراوە بۆ ئەوەی بە تەواوی پێداویستی و پێداویستییەکانی کڕیار لەمڕۆدا دابین بکات.",
  },
  card2Button: {
    en: "Apply",
    ar: "يتقدم",
    ku: "جێبەجێکردن",
  },
};
