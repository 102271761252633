export const content = {
  h1: {
    en: "Solar Solution",
    ar: "محلول الطاقة الشمسية",
    ku: "چارەسەری وزەی خور",
  },
  h2: {
    en: "Commercial",
    ar: "تجاري",
    ku: "بازرگانی",
  },
  headerPostfaceOr: {
    en: "or",
    ar: "أو",
    ku: "یان",
  },
  headerPostface: {
    en: "/mo. for 24mo.",
    ar: "/ شهر.  لمدة 24 شهرًا.",
    ku: "/مۆ.  بۆ 24mo.",
  },
  location: {
    title: {
      en: "Location.",
      ar: "موقع.",
      ku: "شوێن.",
    },
    titleContinued: {
      en: " Where do you want to install the system?",
      ar: " اين هو منزلك؟",
      ku: " ماڵت لە کوێیە؟",
    },
    labels: {
      country: {
        en: "Country",
        ar: "دولة",
        ku: "وڵات",
      },
      city: {
        en: "City",
        ar: "مدينة",
        ku: "شار",
      },
    },
  },
  segment: {
    title: {
      en: "Commercial Segment.",
      ar: "القطاع التجاري.",
      ku: "بەشی بازرگانی.",
    },
    titleContinued: {
      en: " Which type?",
      ar: " أي نوع؟",
      ku: " چ جۆرێک؟",
    },
    labels: {
      commercialBuilding: {
        en: "Commercial Building",
        ar: "مبنى تجاري",
        ku: "بینای بازرگانی",
      },
      industrial: {
        en: "Industrial",
        ar: "صناعي",
        ku: "پیشەسازی",
      },
      warehouse: {
        en: "Warehouse",
        ar: "مستودع",
        ku: "کۆگا",
      },
      governmental: {
        en: "Governmental",
        ar: "حكومية",
        ku: "حکومی",
      },
      agriculture: {
        en: "Agriculture",
        ar: "زراعة",
        ku: "کشتوکاڵ",
      },
    },
  },
  consumption: {
    title: {
      en: "Electricity.",
      ar: "كهرباء.",
      ku: "کارەبا.",
    },
    titleContinued: {
      en: " How much do you use?",
      ar: " كم كنت تستخدم؟",
      ku: " چەند بەکاری دەهێنیت؟",
    },
    labels: {
      monthly: {
        en: "Monthly cost",
        ar: "التكلفة الشهرية",
        ku: "تێچووی مانگانە",
      },
      annual: { en: "Annual cost", ar: "التكلفة السنوية", ku: "تێچووی ساڵانە" },
      price: { en: "Electricity price", ar: "سعر الكهرباء", ku: "نرخی کارەبا" },
      input: {
        en: "Average monthly consumption (kWh)",
        ar: "متوسط ​​الاستهلاك الشهري (كيلوواط ساعة)",
        ku: "تێکڕای بەکارهێنانی مانگانە (کیلۆوات کاتژمێر)",
      },
    },
    modal: {
      labelBold: {
        en: "Do you want to be more specific?",
        ar: "هل تريد أن تكون أكثر تحديدا؟",
        ku: "دەتەوێت تایبەتتر بیت؟",
      },
      labelRegular: {
        en: "Enter your monthly electricity consumption to get more accurate simulations.",
        ar: "أدخل استهلاكك الشهري للكهرباء للحصول على محاكاة أكثر دقة.",
        ku: "کارکردنی کارەبای مانانت داخڵ بکە (مانی چەند کیلۆسەعات کار دەکەیت)",
      },
      title: {
        en: "Monthly Consumption (kWh)",
        ar: "الاستهلاك الشهري (كيلوواط ساعة)",
        ku: "بەکارهێنانی مانگانە (کیلۆوات کاتژمێر)",
      },
      inputLabels: [
        {
          en: "Jan",
          ar: "کانون السانی",
          ku: "کانونی دووەم",
        },
        {
          en: "Feb",
          ar: "شباط",
          ku: "شوبات",
        },
        {
          en: "Mar",
          ar: "ازار",
          ku: "ئادار",
        },
        {
          en: "Apr",
          ar: "نيسان",
          ku: "نيسان",
        },
        {
          en: "May",
          ar: "مايس",
          ku: "مايس",
        },
        {
          en: "Jun",
          ar: "حوزيران",
          ku: "حوزەيران",
        },
        {
          en: "Jul",
          ar: "تموز",
          ku: "تەمووز",
        },
        {
          en: "Aug",
          ar: "أب",
          ku: "ئاب",
        },
        {
          en: "Sep",
          ar: "أيلول",
          ku: "ئەيلول",
        },
        {
          en: "Oct",
          ar: "تشريين لاول",
          ku: "تشرين يەکەم",
        },
        {
          en: "Nov",
          ar: "تشرين السانی",
          ku: "تشرين دووەم",
        },
        {
          en: "Dec",
          ar: "کانون لاول",
          ku: "کانونی يەکەم",
        },
      ],
    },
  },
  gridUptime: {
    title: {
      en: "Grid uptime.",
      ar: "الجهوزية الشبكة.",
      ku: "کاتی کارکردنی تۆڕ.",
    },
    titleContinued: {
      en: " How often is the grid operational?",
      ar: " كم مرة تعمل الشبكة؟",
      ku: " چەند جار تۆڕەکە کاردەکات؟",
    },
    labels: {
      input: {
        en: "Uptime (average over a year)",
        ar: "الجهوزية (متوسط ​​أكثر من عام)",
        ku: "کاتی کارکردن (تێکڕای زیاتر لە ساڵێک)",
      },
    },
    modal: {
      labelBold: {
        en: "Why does grid uptime matter?",
        ar: "لماذا الجهوزية الشبكة مهمة؟",
        ku: "بۆچی کاتی کارکردنی تۆڕ گرنگە؟",
      },
      labelRegular: {
        en: "Learn how grid uptime affect your solar system.",
        ar: "تعرف على كيفية تأثير وقت تشغيل الشبكة على نظامك الشمسي",
        ku: "بزانە کە چۆن کاتی کارکردنی تۆڕ کاریگەری لەسەر وزەی خۆرەکەت هەیە.",
      },
      title: {
        en: "Grid Uptime",
        ar: "وقت تشغيل الشبكة",
        ku: "کاتی کارکردنی تۆڕ",
      },
      p: [
        {
          en: "The electricity that your solar system produces can be fed into the electrical grid. The amount of electricity you feed back is then subtracted from your bill. Another way of looking at it is that the ministry of electricity pays you for the electricity that you produce with your solar system at their own rate.",
          ar: "يمكن تغذية الكهرباء المولدة من الطاقة الشمسية الخاصة بك في الشبكة.  ثم يتم خصم كمية الكهرباء التي تعيدها إلى الشبكة من الكهرباء.  هناك طريقة أخرى للنظر في الأمر وهي أن دائرة الكهرباء تدفع لك مقابل الكهرباء التي تنتجها بنظام الطاقة الشمسية لديك بسعرها.",
          ku: "ئەو کارەبایەی کە وزەی خۆرەکەت بەرهەمی دەهێنێت دەتوانرێت بخرێتە ناو تۆڕی کارەباکەوە.  پاشان ئەو بڕە کارەبایەی کە تۆ دەیخەيتەوە ناوتورەکەوە لە کارەباکەت کەم دەکرێتەوە.  شێوازێکی تری سەیرکردنی ئەوەیە کە وەزارەتی کارەبا پارەت پێدەدات بۆ ئەو کارەبایەی کە بە سیستەمی وزەی خۆرەکەت بەرهەمی دەهێنیت بە ڕێژەی خۆیان.     ",
        },
        {
          en: "When the grid is down you cannot feed electricity to it, however. Ifthe grid is online 100% of the time you can also feed 100% of theelectricity you produce back to it. If the grid uptime is lower than100% it reduces your the payback-period of your system.",
          ar: "لا يمكنك تشغيل الشبكة عندما تكون معطلة.  إذا كانت الشبكة متصلة بالإنترنت بنسبة 100٪ من الوقت ، يمكنك إعادة 100٪ من الكهرباء التي تنتجها إليها.  إذا كان وقت تشغيل الشبكة أقل من 100٪ ، فسيؤدي ذلك إلى تقليل فترة استرداد النظام.",
          ku: "کاتێک تۆڕەکە لەکارکەوتوە ناتوانیت کارەبای پێ بدەیت، بەڵام.  ئەگەر تۆڕەکە 100%ی کاتەکان ئۆنلاین بێت دەتوانیت 100%ی ئەو کارەبایەی کە بەرهەمی دەهێنیت بیدەیتەوە بۆی.  ئەگەر کاتی کارکردنی تۆڕەکە لە 100% کەمتر بێت ئەوە ماوەی گەڕانەوەی سیستەمەکەت کەم دەکاتەوە.",
        },
        {
          en: "We allow you to use different values for the grid uptime to see howit affects how much money your system makes. Remember that the griduptime is an average daily value over a whole year - so try to takeall four seasons into account when choosing an uptime value as itvaries between the seasons.",
          ar: "نحن نسمح لك باستخدام قيم مختلفة لوقت تشغيل الشبكة لمعرفة مقدار الأموال التي يكسبها نظامك.  تذكر أن صافي وقت العمل هو متوسط ​​القيمة اليومية على مدار عام كامل - لذا حاول أن تضع في اعتبارك جميع المواسم الأربعة عند اختيار قيمة وقت العمل لأنها تختلف بين المواسم.",
          ku: "ئێمە ڕێگەت پێدەدەین بەها جیاوازەکان بۆ کاتی کارکردنی تۆڕەکە بەکاربهێنیت بۆئەوەبزانيت کە سیستەمەکەت  چەندپارەبەدەست دەهێنێت.  لەبیرت بێت کە کاتی کارکردنی تۆڕ بەهایەکی مامناوەندی ڕۆژانەیە لە ماوەی ساڵێکی تەواودا - بۆیە هەوڵبدە هەموو چوار وەرزەکە لەبەرچاو بگرن لە کاتی هەڵبژاردنی بەهای کاتەکانی کارکردن چونکە لە نێوان وەرزەکاندا دەگۆڕێت.",
        },
      ],
    },
  },
  footprint: {
    title: {
      en: "Roof Dimensions.",
      ar: "أبعاد السقف",
      ku: "ڕەهەندەکانی سەقف",
    },
    titleContinued: {
      en: " How big is your roof?",
      ar: " ما هو حجم سقفك؟",
      ku: " سەقفەکەت چەند گەورەیە؟",
    },
    labels: {
      widthInput: {
        en: "Roof width",
        ar: "عرض السقف",
        ku: "پانی سەقف",
      },
      heightInput: {
        en: "Roof length",
        ar: "طول السقف",
        ku: "درێژی سەقف",
      },
      panel: {
        en: "Solar panel",
        ar: "لوحة شمسية",
        ku: "پانێڵی خۆر",
      },
      area: {
        en: "Roof area",
        ar: "مساحة السطح",
        ku: "ناوچەی سەقف",
      },
      quantityBold: {
        en: "panels",
        ar: "الألواح",
        ku: "پانێڵەکان",
      },
      quantityRegular: {
        en: "Estimated max limit that can be installed",
        ar: "الحد الأقصى المقدر الذي يمكن تثبيته",
        ku: "سنووری زۆرترینی خەمڵێنراو کە دەتوانرێت دابنرێت",
      },
    },
  },
  orientation: {
    title: {
      en: "Orientation.",
      ar: "اتجاه.",
      ku: "ئاڕاستەکردن.",
    },
    titleContinued: {
      en: " What direction does your building face?",
      ar: " ما الاتجاه الذي يوجهه المبنى الخاص بك؟",
      ku: " بیناکەت ڕووبەڕووی چ ئاراستەیەک دەبێتەوە؟",
    },
    labels: {
      input: { en: "Orientation", ar: "اتجاه", ku: "ئاڕاستەکردن" },
    },
    modal: {
      labelBold: {
        en: "Does orientation matter?",
        ar: "هل التوجيه مهم؟",
        ku: "ئایا ئاراستەکردن گرنگە؟",
      },
      labelRegular: {
        en: "Learn more about how the orientation affect your system.",
        ar: "تعرف على المزيد حول كيفية تأثير التوجيه على نظامك",
        ku: "زیاتر بزانە کە چۆن ئاراستەکردنەکە کاریگەری لەسەر سیستەمەکەت هەیە.",
      },
      title: {
        en: "Building Orientation",
        ar: "اتجاه المبنى",
        ku: "ئاراستەکردنی بیناسازی",
      },
      p: [
        {
          en: "To maximize the electricity production of your solar system it isimportant that the panels face the sun. The ideal orientation foryour system is typically towards the equator. Ideally your buildingface the equator, in which case the panels can be installed in thesame direction as your building. This allows more panels to fit onthe roof without sacrificing efficiency.",
          ar: "لتعظيم توليد الكهرباء من الطاقة الشمسية الخاصة بك ، من المهم أن تواجه الألواح الشمس.  من الأفضل أن يكون نظامك بوجه عام مواجهًا لخط الاستواء ، ومن الأفضل أن يكون المبنى الخاص بك مواجهًا لخط الاستواء ، وفي هذه الحالة يمكن تثبيت الألواح في نفس اتجاه المبنى الخاص بك.  يتيح ذلك تركيب المزيد من الألواح على السطح دون أي ضرر.",
          ku: "بۆ زۆرترین بەرهەمهێنانی کارەبای وزەی خۆرەکەت گرنگە کە پانێڵەکان ڕوو لە خۆر بن.  باشتروايە سیستەمەکەت بە شێوەیەکی گشتی بەرەو هێڵی ئیستواییبئت.واباشترە  بیناکەت ڕووی لە هێڵی ئیستوایی بێت، لەم حاڵەتەدا دەتوانرێت پانێڵەکان بە هەمان ئاراستەی بیناکەت دابنرێت.  ئەمەش ڕێگە دەدات پانێڵی زیاتر لەسەر سەقفەکە جێی ببێتەوە بەبێ هيچ زەرەرئک.",
        },
        {
          en: "If your roof faces another direction than the equator it doesn't necessarily mean that your system is less efficient. The panels can in that case be installed diagonally on the roof - facing the equator. This means that less panels fit on the roof, as diagonally placed panels require more space, and that it may look less aesthetically pleasing.",
          ar: "إذا كان سقفك في اتجاه آخر غير خط الاستواء ، فهذا لا يعني أن نظامك أقل كفاءة.  يمكن وضع الألواح في هذه الحالة قطريًا على السطح - مواجهًا لخط الاستواء.  هذا يعني أن عددًا أقل من الألواح سيتناسب مع السطح ، حيث تتطلب الألواح الموضوعة قطريًا مساحة أكبر ، وقد تبدو أقل إمتاعًا من الناحية الجمالية.",
          ku: "ئەگەر سەقفەکەت ڕووی لە ئاراستەیەکی تر بێت جگە لە هێڵی ئیستوایی، مانای ئەوەنيەکە سیستەمەکەت کەمتر کارامەیە.  پانێڵەکان لەو حاڵەتەدا دەتوانرێت بە شێوەی دیاکۆن لەسەر سەقفەکە دابنرێن - ڕوو لە هێڵی ئیستوایی.  ئەمەش بەو مانایەیە کە پانێڵی کەمتر لەسەر سەقفەکە جێی دەبێتەوە، چونکە پانێڵەکانی دانراو بە شێوەی دیاکۆن پێویستیان بە شوێنی زیاترە، و لەوانەیە لە ڕووی جوانکارییەوە کەمتر دڵخۆشکەر دەربکەوێت.",
        },
        {
          en: "The choice of prioriticing efficiency over system size and aesthetics is yours though. Both approaches are possible and it ultimately comes down to your own preference.",
          ar: "اختيار إعطاء الأولوية للوظائف على حجم النظام والجماليات متروك لك.  كلا الخيارين متاحان ويمكنك اختيار الخيار الخاص بك.",
          ku: "هەڵبژاردنی یەکەمایەتیدان بە کارایی بەسەر قەبارەی سیستەم و جوانکاریدا بيرکردنە وەی خۆتە.  هەردوو هەڵبژاردەکە بەردەستە و لە کۆتاییدا دەتوانيت بەخواستي خؤت هەڵيبژئريت.",
        },
      ],
    },
  },
  configuration: {
    title: {
      en: "Configuration.",
      ar: "ترتيب.",
      ku: "ڕێکخستن.",
    },
    titleContinued: {
      en: " Customize to your needs.",
      ar: " قم بالتخصيص حسب احتياجاتك.",
      ku: " بەپێی پێویستی خۆت ،تەرخان بکە",
    },
    labels: {
      systemSize: { en: "System size", ar: "حجم النظام", ku: "قەبارەی سیستەم" },
      input: { en: "Quantity", ar: "كمية", ku: "بڕ" },
    },
    modal: {
      labelBold: {
        en: "How big should you go?",
        ar: "ما هو الحجم الذي يجب أن تذهب إليه؟",
        ku: "چەند گەورە بیت؟",
      },
      labelRegular: {
        en: "Learn more about considerations for system size.",
        ar: "تعرف على المزيد حول اعتبارات حجم النظام.",
        ku: "زیاتر بزانە دەربارەی ڕەچاوکردنی قەبارەی سیستەم.",
      },
      title: { en: "System Size", ar: "حجم النظام", ku: "قەبارەی سیستەم" },
      p: [
        {
          en: "The primary goal of commercial solar systems are to drive down your electricity costs through smart metering. Since smart meters deducts from the electricity you consume your system size should be sized to generate roughly as much electricity as you consume to get no electricity bill.",
          ar: "الهدف الأساسي لأنظمة الطاقة الشمسية التجارية هو خفض تكاليف الكهرباء من خلال العدادات الذكية.  نظرًا لأن العدادات الذكية تُخصم من الكهرباء التي تستهلكها ، يجب أن يكون حجم نظامك لتوليد ما يقرب من كمية الكهرباء التي تستهلكها حتى لا تحصل على فاتورة كهرباء.",
          ku: "ئامانجی سەرەکی سیستەمی وزەی خۆری بازرگانی بریتییە لە دابەزاندنی تێچووی کارەباکەت لە ڕێگەی پێوەرکردنی زیرەکەوە.  بەو پێیەی پێوەرە زیرەکەکان لە کارەبای بەکارهێنراو دەبڕن قەبارەی سیستەمەکەت دەبێت قەبارەی ئەوە بێت کە بە نزیکەیی ئەوەندەی کارەبا بەرهەمبهێنیت کە بەکاریدەهێنیت بۆ ئەوەی هیچ پارەیەکی کارەبا بەدەستبهێنیت.",
        },
        {
          en: "Once your system produces as much electricity as as you consume there is little to gain from feeding more electricity to the grid.",
          ar: "بمجرد أن ينتج نظامك قدرًا من الكهرباء بقدر ما تستهلك ، لن يكون هناك الكثير من المكاسب من تغذية المزيد من الكهرباء إلى الشبكة.",
          ku: "کاتێک سیستەمەکەت بەقەد بەکارهێنانی کارەبا بەرهەم دەهێنێت، قازانجێکی کەم هەیە لە پێدانی کارەبای زیاتر بۆ تۆڕەکە.",
        },
      ],
    },
  },
  simulation: {
    title: {
      en: "Simulation & Analysis",
      ar: "المحاكاة والتحليل",
      ku: "ھاوشێوەکردن و شیکاری",
    },
  },
  details: {
    title: {
      en: "Details.",
      ar: "تفاصيل.",
      ku: "ووردەکاریەکان.",
    },
    titleContinued: {
      en: " Enter your details.",
      ar: " أدخل التفاصيل الخاصة بك.",
      ku: " وردەکارییەکانت بنووسە.",
    },
    labels: [
      {
        en: "Full name",
        ar: "الاسم بالكامل",
        ku: "ناوی تەواو",
      },
      {
        en: "Phone number",
        ar: "رقم الهاتف",
        ku: "ژمارەی تەلەفۆن",
      },
      {
        en: "Address",
        ar: "تبوك",
        ku: "ناونیشان",
      },
      {
        en: "Email",
        ar: "بريد إلكتروني",
        ku: "ئیمەیڵ",
      },
    ],
  },
  contact: {
    title: {
      en: "Contact.",
      ar: "اتصال.",
      ku: "پەیوەندی.",
    },
    titleContinued: {
      en: " How do you want us to contact you?",
      ar: " كيف تريد ان نتصل بك او نتواصل معك؟",
      ku: " چۆن دەتەوێت پەیوەندیت پێوە بکەین؟",
    },
  },
  finish: {
    title: {
      en: "Your Solar Solution.",
      ar: "الحل الشمسي الخاص بك.",
      ku: "چارەسەری وزەی خؤرەکەت.",
    },
    titleContinued: {
      en: " Customized to your needs.",
      ar: " مخصصة لاحتياجاتك.",
      ku: " بەپێی پێداویستییەکانتان دروستکراوە.",
    },
    secondaryTitle: {
      en: "Solar Solution",
      ar: "نظام",
      ku: "سیستێمەکە",
    },
    button: {
      en: "Place Order",
      ar: "مكان الامر",
      ku: "داواکاری دابنێ",
    },
    bookmarkHighlight: {
      en: "Save configuration.",
      ar: "حفظ التكوين.",
      ku: "ڕێکخستنەکان پاشەکەوت بکە.",
    },
    bookmarkRegular: {
      en: " Get a link and come back later.",
      ar: " احصل على رابط وارجع لاحقًا.",
      ku: " لینکێک وەربگرە و دواتر وەرەوە.",
    },
  },
};
