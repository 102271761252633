import { useDispatch, useSelector } from "react-redux";
import * as nouislider from "nouislider";
import { AppDispatch, RootState } from "../../../../store/config";
import { InfoModal } from "../../../../components/InfoModal";
import { Slider } from "../../../../components/Slider";
import { content } from "../content";
import DataSet from "@antv/data-set";
import wNumb from "wnumb";
import colors from "tailwindcss/colors";
import {
  Chart,
  Axis,
  LineAdvance,
  Annotation,
  Legend,
  Tooltip,
} from "bizcharts";
import { setDaySizeAmperes } from "../../../../store/slices/cpq/residential";

export const DaySizeInputSection = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { locale, direction } = useSelector(
    (state: RootState) => state.localization
  );

  const { amperes, hours } = useSelector(
    (state: RootState) => state.cpq.residential.config.daySize
  );

  const simulationAvgHour = useSelector(
    (state: RootState) => state.cpq.residential.simulationAvgHour
  );

  const handleSystemSizeAmpsChange = (values: (string | number)[]) => {
    const updatedVal = Math.round(Number(values[0]));
    if (updatedVal > 0) {
      dispatch(setDaySizeAmperes({ amperes: updatedVal }));
    }
  };

  const onAmpereEnd = (
    values: (string | number)[],
    handleNumber: number,
    unencoded: number[],
    tap: boolean,
    positions: number[],
    slider: nouislider.API
  ) => {
    const updatedVal = Number(values[0]);
    if (updatedVal < 1) {
      slider.set(1);
      dispatch(setDaySizeAmperes({ amperes: 1 }));
    }
  };

  const ds = new DataSet();
  const dv = ds.createView();
  dv.source(simulationAvgHour);

  let max =
    simulationAvgHour.length > 0 && Number(dv.max("output"))
      ? Math.max(Number(dv.max("output")), amperes * 1.1)
      : 10;

  const formatter_A = wNumb({ decimals: 1, suffix: " A", thousand: "," });
  const scaleCfg = {
    inflexible: {
      alias: "Electricity (from sunlight)",
      min: 0,
      max: max * 1.05,
      formatter: (v: number) =>
        typeof v === "number" ? formatter_A.to(v) : null,
    },
    hour: {
      type: "linear",
      alias: "Hour",
      min: 0,
      max: 23,
      tickCount: 12,
      formatter: (v: number) =>
        v === 12 ? `12pm` : v < 12 ? `${v}am` : `${v - 12}pm`,
    },
  };

  const grid = {
    line: {
      type: "line",
      style: {
        stroke: colors.gray[300],
        lineWidth: 0.25,
      },
    },
  };

  const line = {
    style: {
      stroke: colors.gray[700],
      lineWidth: 0.25,
    },
  };

  return (
    <>
      <div className="p-4 w-full aspect-video min-h-[246px] bg-gray-50 rounded-xl shadow-sm">
        <div className="w-full h-full md:pb-5" style={{ direction: "ltr" }}>
          <p
            className="text-xs text-gray-900 pb-1"
            style={{ direction: direction }}
          >
            {content.daySize.labels.chart[locale]}
          </p>
          <Chart autoFit scale={scaleCfg} data={dv} pure>
            <Axis
              name="date"
              grid={grid}
              line={line}
              visible={true}
              tickLine={{ length: 0 }}
            />
            <Axis name="inflexible" visible={false} />
            <LineAdvance
              shape="smooth"
              area
              position={`hour*inflexible`}
              style={{ fillOpacity: 1 }}
              color={`l(270) 0:${colors.yellow[200]} 1:${colors.yellow[200]}`}
              size={0}
            />
            <Annotation.Line
              start={["min", amperes]}
              end={["max", amperes]}
              text={{ content: `${amperes} Ampere`, offsetY: -6 }}
              style={{
                stroke: `l(270) 0:${colors.gray[400]} 1:${colors.gray[400]}`,
                lineDash: [3],
                lineWidth: 2,
              }}
            />
            <Legend name="flexible" position="top" />
            <Tooltip shared />
          </Chart>
        </div>
      </div>
      <div className="mt-6 px-4 py-6 bg-gray-50 rounded-xl overflow-hidden">
        {direction === "ltr" && (
          <Slider
            label={content.daySize.labels.ampere[locale]}
            value={amperes}
            className="mt-4"
            onSet={handleSystemSizeAmpsChange}
            onEnd={onAmpereEnd}
            pipsMode={nouislider.PipsMode.Steps}
            options={{
              connect: "lower",
              direction: "ltr",
              start: amperes,
              range: {
                min: [0],
                "25%": [4],
                "50%": [8],
                "75%": [20],
                max: [40],
              },
            }}
            suffix={content.daySize.labels.ampereSuffix[locale]}
            decimals={0}
          />
        )}
        {direction === "rtl" && (
          <Slider
            label={content.daySize.labels.ampere[locale]}
            value={amperes}
            className="mt-4"
            onSet={handleSystemSizeAmpsChange}
            onEnd={onAmpereEnd}
            pipsMode={nouislider.PipsMode.Steps}
            options={{
              connect: "lower",
              direction: "rtl",
              start: amperes,
              range: {
                min: [0],
                "25%": [4],
                "50%": [8],
                "75%": [20],
                max: [40],
              },
            }}
            suffix={content.daySize.labels.ampereSuffix[locale]}
            decimals={0}
          />
        )}
      </div>
      <InfoModal
        linkText={content.daySize.modal.labelBold[locale]}
        description={content.daySize.modal.labelRegular[locale]}
        type="box"
        img="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fstock%2Fac-bedroom.jpeg?alt=media&token=ac13c1d6-54c3-4452-b767-d3a6ef2f8b3d"
        title={content.daySize.modal.title[locale]}
        boxClassName="mt-5"
      >
        <div className="p-6 md:px-20 md:pb-20">
          <p>{content.daySize.modal.p[0][locale]}</p>
          <p className="mt-3">{content.daySize.modal.p[1][locale]}</p>
        </div>
      </InfoModal>
    </>
  );
};
