import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICountry } from "../../../types/country";
import { ICity } from "../../../types/city";
import { ILocation } from "../../../types/location";
import { api } from "../../../api";

interface State {
  selectedCountry: ICountry;
  selectedCity: ICity;
  data: ILocation | null;
  api: { pending: boolean; error: any };
}

export const fetchLocation = createAsyncThunk(
  "locations/fetchLocation",
  async (
    param: {
      country: ICountry;
      city: ICity;
    },
    thunkAPI
  ) => {
    try {
      const data = await api.getLocation(param.country, param.city);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    selectedCountry: "iraq",
    selectedCity: "baghdad",
    data: null,
    api: { pending: false, error: null },
  } as State,
  reducers: {
    setCountry: (state, action: PayloadAction<ICountry>) => {
      state.selectedCountry = action.payload;
    },
    setCity: (state, action: PayloadAction<ICity>) => {
      state.selectedCity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocation.pending, (state, action) => {
      state.api.pending = true;
    });
    builder.addCase(fetchLocation.fulfilled, (state, action) => {
      state.api.pending = false;
      state.data = action.payload;
    });
    builder.addCase(fetchLocation.rejected, (state, action) => {
      state.api.pending = false;
      state.api.error = action.payload;
    });
  },
});

export const { setCountry, setCity } = locationSlice.actions;
