import { Footer } from "../../common/Footer";
import { Header } from "../../common/Header";
import { Hero } from "./Hero";
import { Tawk } from "../../common/Tawk";
import { Pointers } from "./Pointers";
import { Partners } from "./Partners";
import { How } from "./How";
import { Solutions } from "./Solutions";
import { Social } from "../../common/Social";
import { Carousel } from "./Carousel";
import { Helmet } from "react-helmet-async";
import { ScrollRestoration } from "react-router-dom";

export const Index = () => {
  return (
    <>
      <Helmet>
        <title>Solar Energy in Iraq</title>
        <meta
          name="description"
          content="Get a free quote for home or commercial solar systems now. Customize your solution in minutes using our online configurator."
        />
      </Helmet>
      <ScrollRestoration />
      <Tawk />
      <Header autoChange />
      <Hero />
      <Pointers />
      <Solutions />
      <How />
      <Carousel />
      <Partners />
      <Social />
      <Footer />
    </>
  );
};
