import { doc, getDoc, QueryDocumentSnapshot } from "firebase/firestore";
import { db } from "../firebase/config";
import { IMetaOffering } from "../types/meta/offering/offering.type";

const converter = {
  toFirestore: (data: IMetaOffering) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as IMetaOffering,
};

export const getOffering = async (): Promise<IMetaOffering | null> => {
  // Query Params
  const docRef = doc(db, "meta_data", "offering").withConverter(converter);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};
