import React from "react";
import wNumb from "wnumb";
import "./compass.style.css";

type Props = {
  direction: number;
  directionNames?: string[];
  className?: string;
};
export const Compass: React.FC<Props> = ({
  direction = 0,
  directionNames = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"],
  className = "",
}) => {
  const directionName = (direction: number) => {
    let sections = directionNames.length,
      sect = 360 / sections,
      x = Math.floor((direction + sect / 2) / sect);

    x = x >= sections ? 0 : x;

    return directionNames[x];
  };

  const normalizeAngle = (direction: number) => {
    let newAngle = direction,
      rot = 0,
      ar = rot % 360;

    while (newAngle < 0) {
      newAngle += 360;
    }
    while (newAngle > 360) {
      newAngle -= 360;
    }
    while (rot < 0) {
      rot += 360;
    }
    while (rot > 360) {
      rot -= 360;
    }

    rot += newAngle - ar;

    return rot;
  };
  let dir = normalizeAngle(direction),
    name = directionName(dir);

  return (
    <div className={`${className} compass`}>
      <div
        className="compass__windrose"
        style={{
          transform: `rotate(-${direction}deg)`,
        }}
      >
        {[...Array(10)].map((k, i) => (
          <div className="compass__mark" key={i + 1}></div>
        ))}
        <div className="compass__mark--direction-h"></div>
        <div className="compass__mark--direction-v"></div>
      </div>

      <div className="compass__arrow-container">
        <div className="compass__arrow"></div>
        <div className="compass__labels">
          <span>{name}</span>
          <span>{wNumb({ decimals: 0, suffix: "°" }).to(dir)}</span>
        </div>
      </div>
    </div>
  );
};
