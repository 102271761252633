import { useRef, useEffect, useState } from "react";
import throttle from "lodash.throttle";

/**
 * Check if an element is halfway in viewport vertically
 * @param {number} offset - Number of percent up to the observable element from the top
 * @param {number} throttleMilliseconds - Throttle observable listener, in ms
 */
export default function useMidwayTracker<Element extends HTMLElement>(
  offset: number = 0,
  throttleMilliseconds: number = 100
): [Boolean, React.RefObject<Element>] {
  const [isMidway, setIsMidway] = useState(false);
  const ref = useRef<Element>(null);

  const onScroll = throttle(() => {
    if (!ref.current) {
      setIsMidway(false);
      return;
    }
    const { top, bottom } = ref.current.getBoundingClientRect();

    const isMidwayFromTop =
      top <= window.innerHeight / 2 + offset * window.innerHeight;
    const isMidwayFromBottom =
      bottom >= window.innerHeight / 2 + offset * window.innerHeight;
    setIsMidway(isMidwayFromTop && isMidwayFromBottom);
  }, throttleMilliseconds);

  useEffect(() => {
    document.addEventListener("scroll", onScroll, true);
    return () => document.removeEventListener("scroll", onScroll, true);
  });

  return [isMidway, ref];
}
