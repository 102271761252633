import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/config";
import { setResidentialPanel } from "../../../../store/slices/cpq/residential";
import { IPanel } from "../../../../types/meta/offering/panel.type";
import { InfoModal } from "../../../../components/InfoModal";
import { ProductSelect } from "../../../../components/ProductSelect";
import { content } from "../content";

export const PanelsInputSection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { locale } = useSelector((state: RootState) => state.localization);

  const offering = useSelector((state: RootState) => state.meta.offering.data);

  const panelSelected = useSelector(
    (state: RootState) => state.cpq.residential.config.panel
  );

  const handlePanelChange = (panel: IPanel | null) => {
    if (!panel) return;

    dispatch(setResidentialPanel({ panel }));
  };

  return (
    <>
      {offering?.panels.map((panel, i) => (
        <ProductSelect
          key={panel.id}
          img={panel.img}
          title={panel.title}
          selected={panelSelected !== null && panelSelected.id === panel.id}
          onClick={() => handlePanelChange(panel)}
          className={i === 0 ? "mt-6" : "mt-5"}
        />
      ))}
      <InfoModal
        linkText={content.panels.modal.labelBold[locale]}
        description={content.panels.modal.labelRegular[locale]}
        title={content.panels.modal.title[locale]}
        boxClassName="mt-5"
        img="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fstock%2Fcell-close-up.jpeg?alt=media&token=df9f36f0-f985-43c0-9993-f3b6dd658b50"
        type="box"
      >
        <div className="p-6 md:px-20 md:pb-20">
          <p>{content.panels.modal.p[0][locale]}</p>
          <p className="mt-3">{content.panels.modal.p[1][locale]}</p>
        </div>
      </InfoModal>
    </>
  );
};
