import numeral from "numeral";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { RootState } from "../../../../store/config";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const NightSizeVisualSection = () => {
  const roofSlope = useSelector(
    (state: RootState) => state.cpq.residential.config.roofSlope
  );

  const batterySizeWH = useSelector(
    (state: RootState) => state.cpq.residential.config.system.batterySizeWH
  );

  return (
    <div className="w-full aspect-video min-h-[246px] max-w-full md:h-full md:aspect-auto relative bg-gray-50 rounded-2xl overflow-hidden shadow-sm">
      <div className="w-full h-full absolute">
        <Swiper
          pagination
          navigation
          className="w-full h-full"
          modules={[Pagination, Navigation]}
          slidesPerView={1}
        >
          <SwiperSlide>
            {roofSlope === "flat" && (
              <img
                className="w-full h-full rounded-2xl object-cover"
                alt=""
                src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fstock%2Fflat-house-modern-night.webp?alt=media&token=8fde3ee2-d10e-4aa4-851b-1989e1a394c0"
              />
            )}
            {roofSlope === "tilted" && (
              <img
                className="w-full h-full rounded-2xl object-cover"
                alt=""
                src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fstock%2Ftilted-house-modern-night.webp?alt=media&token=8d71a13f-0272-41eb-8040-78aaaf16bcde"
              />
            )}
          </SwiperSlide>
          <SwiperSlide className="p-8 md:p-12 lg:p-20">
            <img
              className="w-full h-full object-contain"
              src={
                "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2FJinko-ESS.png?alt=media&token=c5f33263-ef64-421c-aeab-3dd1734d6a56"
              }
              alt={"Solar Battery"}
            />
            <div className="absolute p-2 md:p-4 flex rounded left-6 bottom-10 right-6 text-xs md:text-sm text-white font-medium bg-black/30 backdrop-blur-xl max-w-md mx-auto">
              <div className="flex flex-1 justify-center items-center">
                <p className="ml-2">{`${numeral(batterySizeWH)
                  .divide(1000)
                  .format("0.00")} kWh`}</p>
              </div>
              <div className="border-white/30 border-l-[1px] mx-3" />
              <div className="flex flex-1 justify-center items-center ">
                <p className="ml-2 capitalize">{`LiFePO4`}</p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
