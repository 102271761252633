import { createListenerMiddleware } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { fetchLocation } from "../slices/location";
import { fetchHourly } from "../slices/meteorology/hourly";

// Create the middleware instance and methods
export const locationFetchedListener = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
locationFetchedListener.startListening({
  actionCreator: fetchLocation.fulfilled,
  effect: async (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    if (action.payload?.id) {
      const params = {
        locationId: action.payload.id,
        year: 2021,
      };

      await listenerApi.dispatch(fetchHourly(params));
    }
  },
});
