import { useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../../../components/Input/input.component";
import { RootState } from "../../../../store/config";
import { content } from "../content";

export const DetailsInputSection = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");

  return (
    <div className="w-full grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
      <Input
        type="text"
        label={content.details.labels[0][locale]}
        value={name}
        onChange={(v) => setName(v)}
        className="flex-1"
      />
      <Input
        type="tel"
        label={content.details.labels[1][locale]}
        value={number}
        onChange={(v) => setNumber(v)}
        className="flex-1"
      />
      <Input
        type="text"
        label={content.details.labels[2][locale]}
        value={address}
        onChange={(v) => setAddress(v)}
        className="flex-1"
      />
      <Input
        type="email"
        label={content.details.labels[3][locale]}
        value={email}
        onChange={(v) => setEmail(v)}
        className="flex-1"
      />
    </div>
  );
};
