import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/config";
import { fetchLocation } from "../../../../store/slices/location";
import { fetchOffering } from "../../../../store/slices/meta/offering";

type Props = {
  children?: JSX.Element | JSX.Element[];
};
export const DataPreparer: React.FC<Props> = ({ children = null }) => {
  const dispatch = useDispatch<AppDispatch>();

  const offering = useSelector((state: RootState) => state.meta.offering.data);

  const location = useSelector((state: RootState) => state.location);

  useEffect(() => {
    if (!offering) {
      dispatch(fetchOffering());
    }
  }, [offering, dispatch]);

  useEffect(() => {
    if (!location.data) {
      dispatch(
        fetchLocation({
          country: location.selectedCountry,
          city: location.selectedCity,
        })
      );
    }
  }, [location, dispatch]);

  return <>{children}</>;
};
