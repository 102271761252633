export const content = {
  h1: {
    en: "Products",
    ar: "منتجات",
    ku: "پيداويستی",
  },
  h2: {
    en: "The most advanced and high quality solar products",
    ar: "منتجات الطاقة الشمسية الأكثر تقدمًا وعالية الجودة",
    ku: "باشترين و پيشکە وتووترين ئاميرە کانی وزەی خۆر",
  },
};
