import { doc, getDoc, QueryDocumentSnapshot } from "firebase/firestore";
import { db } from "../firebase/config";
import { IMetaSupportedLocations } from "../types/meta/supported-locations";

const converter = {
  toFirestore: (data: IMetaSupportedLocations) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) =>
    snap.data() as IMetaSupportedLocations,
};

export const getSupportedLocations =
  async (): Promise<IMetaSupportedLocations | null> => {
    // Query Params
    const docRef = doc(db, "meta_data", "supported_locations").withConverter(
      converter
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  };
