import {
  collection,
  getDocs,
  orderBy,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { IDaily, IDailyFirebase } from "../types/power/daily";

const converter = {
  toFirestore: (data: IDailyFirebase) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as IDailyFirebase,
};

export const getDaily = async (
  locationId: string,
  year: number
): Promise<IDaily[] | null> => {
  // Query Params
  const collectionRef = collection(db, `locations/${locationId}/daily`);
  const filterYear = where("year", "==", year);

  const q = query(
    collectionRef,
    filterYear,
    orderBy("month", "asc")
  ).withConverter(converter);
  // Fetch
  const querySnapshot = await getDocs(q);
  // transform
  const dailyByMonthArr = querySnapshot.docs.map((doc) => doc.data());
  const flatten = dailyByMonthArr.map((obj) => obj.data).flat();
  return flatten;
};
