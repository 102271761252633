export const content = {
  h1: {
    en: "World class tools",
    ar: "أدوات عالمية المستوى",
    ku: "باشترين ئامئرە جيهانييە کان",
  },
  h2: {
    en: "Get started on your own through our self-service tools",
    ar: "ابدأ بنفسك من خلال أدوات الخدمة الذاتية الخاصة بنا",
    ku: "لە ڕێگەی ئامرازەکانی خۆخزمەتکردنمانەوە بە تەنیا دەست پێ بکە",
  },
};
