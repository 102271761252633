import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/config";
import { setCouponCode, fetchCoupon } from "../../store/slices/coupon";
import { InfoModal } from "../InfoModal";
import { InputText } from "../InputText";
import { content } from "./content";

export const CouponModal = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { locale } = useSelector((state: RootState) => state.localization);
  const coupon = useSelector((state: RootState) => state.coupon);
  const handleCouponCodeChange = (code: string) => {
    dispatch(setCouponCode(code));
  };

  const handleGetDiscount = () => {
    dispatch(fetchCoupon({ code: coupon.code }));
  };

  const handleSubmit = (code: string) => {
    dispatch(fetchCoupon({ code }));
    dispatch(setCouponCode(code));
  };

  return (
    <InfoModal
      linkText={content.coupon.label[locale]}
      title={content.coupon.title[locale]}
      className="ltr:ml-1.5 rtl:mr-1.5 mt-0 mr-0 mb-0 text-black hover:underline cursor-pointer whitespace-nowrap"
      panelClass="p-0"
    >
      <>
        <div className="px-6 md:px-20 pb-6 w-full flex justify-center">
          <div className="w-full max-w-md flex">
            <InputText
              label={content.coupon.inputLabel[locale]}
              value={coupon.code}
              onChange={handleCouponCodeChange}
              onSubmit={handleSubmit}
              className="flex-1"
            />
            <button
              className="ml-3 px-4 py-2 rounded-xl bg-blue-600 text-white hover:bg-blue-500"
              onClick={handleGetDiscount}
            >
              {content.coupon.button[locale]}
            </button>
          </div>
        </div>
        <div className="flex-1 p-20 text-center bg-gray-100 font-numbers">
          <p className="mb-2 text-sm">
            {coupon.data
              ? content.coupon.messages.approved[locale]
              : content.coupon.messages.empty[locale]}
          </p>

          <p
            className={`text-4xl ${
              coupon.data && coupon.data.discount * 100 > 0
                ? "text-red-600"
                : "text-red-300"
            }`}
          >{`${coupon.data ? coupon.data.discount * 100 : 0}%`}</p>
        </div>
      </>
    </InfoModal>
  );
};
