import { Transition } from "@headlessui/react";
import { useState } from "react";
import { IoBookmarkOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { RootState } from "../../../store/config";
import { Header } from "../../../common/Header";
import { DynamicHeader } from "../../../components/DynamicHeader";
import { InfoModal } from "../../../components/InfoModal";
import { SingleColumn } from "../../../components/SingleColumn";
import { Sticky2xColumns } from "../../../components/Sticky2xColumns";
import { ContactInputSection } from "./sections/contact.input.section";
import { DetailsInputSection } from "./sections/details.input.section";
import { NightSizeInputSection } from "./sections/night-size.input.section";
import { NightSizeVisualSection } from "./sections/night-size.visual.section";
import { LocationInputSection } from "./sections/location.input.section";
import { LocationVisualSection } from "./sections/location.visual.section";
import { PanelsInputSection } from "./sections/panels.input.section";
import { PanelsVisualSection } from "./sections/panels.visual.section";
import { RoofSlopeInputSection } from "./sections/roof-slope.input.section";
import { RoofSlopeVisualSection } from "./sections/roof-slope.visual.section";
import { SummaryVisualSection } from "./sections/summary.visual.section";
import { DaySizeInputSection } from "./sections/day-size.input.section";
import { DaySizeVisualSection } from "./sections/day-size.visual.section";
import { DataPreparer } from "./wrapper/data-preparer";
import { Helmet } from "react-helmet-async";
import { Link, ScrollRestoration } from "react-router-dom";
import { content } from "./content";
import { bookmarkResidential } from "../../../store/slices/cpq/residential";
import { getUUID } from "../../../helpers/memory/get-uuid";

export const CPQResidential = () => {
  const dispatch = useDispatch();
  const { height } = useWindowDimensions();
  const [activeSection, setActiveSection] = useState(1);
  const { locale } = useSelector((state: RootState) => state.localization);
  const uuid = useSelector((state: RootState) => state.cpq.residential.uuid);
  const roofSlope = useSelector(
    (state: RootState) => state.cpq.residential.config.roofSlope
  );

  const price = useSelector(
    (state: RootState) => state.cpq.residential.config.price
  );

  const bookmark = () => {
    if (!uuid) {
      dispatch(bookmarkResidential({ uuid: getUUID() }));
    } else {
      dispatch(bookmarkResidential({ uuid }));
    }
  };

  return (
    <DataPreparer>
      <Helmet>
        <title>Home Solar System</title>
        <meta
          name="description"
          content="Power your home with your own solar system. Get a free quote online."
        />
      </Helmet>
      <ScrollRestoration />
      <Header relative />
      <div className="pt-24 w-full md:max-w-[87.5%] mx-auto">
        <DynamicHeader
          preface={content.h2[locale]}
          postface={`${Intl.NumberFormat(locale, {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          }).format(price.total)} ${
            content.headerPostfaceOr[locale]
          } ${Intl.NumberFormat(locale, {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          }).format((price.total * 1.2) / 24)}${
            content.headerPostface[locale]
          }`}
        >
          {content.h1[locale]}
        </DynamicHeader>
        <div className="w-full max-w-sm md:max-w-full mx-auto">
          <Sticky2xColumns
            title={content.location.title[locale]}
            titleContinued={content.location.titleContinued[locale]}
            stickySide="left"
            className="pt-12"
            topPositionClass="md:top-36 max-h-56 w-full h-full"
            leftChildren={<LocationVisualSection />}
            rightChildren={<LocationInputSection />}
          />
        </div>
        <div className="md:flex relative">
          <div className="flex-1 hidden md:flex relative">
            <div
              className={`sticky w-full h-full top-[150px] pr-0 ltr:md:pr-20 rtl:md:pl-20 overflow-hidden`}
              style={{ maxHeight: `calc(${height}px - 200px)` }}
            >
              <div className="w-full h-full bg-gray-50 rounded-2xl">
                <Transition
                  as={"div"}
                  show={activeSection === 1}
                  enter="ease-in-out transition-opacity duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out transition-opacity duration-250"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={"w-full h-full"}
                >
                  <RoofSlopeVisualSection />
                </Transition>
                <Transition
                  as={"div"}
                  show={activeSection === 2}
                  enter="ease-in-out transition-opacity duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out transition-opacity duration-250"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={"w-full h-full"}
                >
                  <PanelsVisualSection />
                </Transition>
                <Transition
                  as={"div"}
                  show={activeSection === 3}
                  enter="ease-in-out transition-opacity duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out transition-opacity duration-250"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={"w-full h-full"}
                >
                  <DaySizeVisualSection />
                </Transition>
                <Transition
                  as={"div"}
                  show={activeSection === 4}
                  enter="ease-in-out transition-opacity duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out transition-opacity duration-250"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className={"w-full h-full"}
                >
                  <NightSizeVisualSection />
                </Transition>
              </div>
            </div>
          </div>
          <div className="flex-1 max-w-sm mx-auto">
            <Sticky2xColumns
              title={content.roofSlope.title[locale]}
              titleContinued={content.roofSlope.titleContinued[locale]}
              stickySide="left"
              topPositionClass="md:top-36"
              hideStickyOnMD
              onActive={() => setActiveSection(1)}
              leftChildren={<RoofSlopeVisualSection />}
              rightChildren={<RoofSlopeInputSection />}
            />
            <Sticky2xColumns
              title={content.panels.title[locale]}
              titleContinued={content.panels.titleContinued[locale]}
              stickySide="left"
              topPositionClass="md:top-36"
              hideStickyOnMD
              onActive={() => setActiveSection(2)}
              leftChildren={<PanelsVisualSection />}
              rightChildren={<PanelsInputSection />}
            />
            <Sticky2xColumns
              title={content.daySize.title[locale]}
              titleContinued={content.daySize.titleContinued[locale]}
              stickySide="left"
              topPositionClass="md:top-36"
              hideStickyOnMD
              onActive={() => setActiveSection(3)}
              leftChildren={<DaySizeVisualSection />}
              rightChildren={<DaySizeInputSection />}
            />
            <Sticky2xColumns
              title={content.nightSize.title[locale]}
              titleContinued={content.nightSize.titleContinued[locale]}
              stickySide="left"
              topPositionClass="md:top-36"
              hideStickyOnMD
              onActive={() => setActiveSection(4)}
              leftChildren={<NightSizeVisualSection />}
              rightChildren={<NightSizeInputSection />}
            />
          </div>
        </div>
        <SingleColumn
          title={content.summary.title[locale]}
          titleContinued={content.summary.titleContinued[locale]}
        >
          <SummaryVisualSection />
        </SingleColumn>
        <SingleColumn
          title={content.details.title[locale]}
          titleContinued={content.details.titleContinued[locale]}
        >
          <DetailsInputSection />
        </SingleColumn>
        <SingleColumn
          title={content.contact.title[locale]}
          titleContinued={content.contact.titleContinued[locale]}
        >
          <ContactInputSection />
        </SingleColumn>
      </div>
      <div className="py-24 w-full bg-gray-50">
        <div className="px-6 md:px-0 md:flex max-w-sm md:w-[87.5%] md:max-w-screen-md mx-auto">
          <div className="ltr:md:pr-12 rtl:md:pl-12 md:flex-1">
            <h2 className="mb-4 text-2xl font-semibold text-slate-900 leading-tight">
              <span className="text-slate-900">
                {content.finish.title[locale]}
              </span>
              <span className="text-slate-500">
                {content.finish.titleContinued[locale]}
              </span>
            </h2>
            {roofSlope === "flat" && (
              <img
                className="w-full h-60 object-cover rounded-xl"
                alt=""
                src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fflat-house-modern-day.webp?alt=media&token=038b449a-a33d-46d3-8e5b-d0f16213cef8"
              />
            )}
            {roofSlope === "tilted" && (
              <img
                className="w-full h-60 object-cover rounded-xl"
                alt=""
                src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Ftilted-house-modern-day.webp?alt=media&token=82b4ee87-9894-43e8-8659-f4c962f6ea57"
              />
            )}
          </div>
          <div className="w-full max-w-xs mt-4 md:mt-0">
            <p className="text-lg pb-1.5">
              {content.finish.secondaryTitle[locale]}
            </p>
            <p className="font-semibold text-lg">{`${Intl.NumberFormat(locale, {
              style: "currency",
              currency: "USD",
            }).format(price.total)}`}</p>
            <button className="mt-11 py-3 px-6 bg-blue-600 leading-5 text-white rounded-lg min-w-full">
              {content.finish.button[locale]}
            </button>
            <div className="w-full mt-4 pt-4 flex items-center border-t border-gray-200">
              <div className="w-6 flex items-center justify-center">
                <InfoModal
                  linkText={
                    <IoBookmarkOutline
                      size={24}
                      className="text-blue-600 cursor-pointer"
                      onClick={bookmark}
                    />
                  }
                  title={"Bookmark"}
                  children={
                    <div className="flex-1 p-10 bg-gray-100">
                      <p className="font-semibold mb-1.5">Save link below</p>
                      <Link
                        className="hover:underline underline-offset-4"
                        to={`/${locale}/cpq/residential/${uuid}`}
                      >{`www.toptaqa.com/${locale}/cpq/residential/${uuid}`}</Link>
                    </div>
                  }
                />
              </div>
              <p className="ltr:ml-2 rtl:mr-2 text-sm">
                <b className="font-semibold">
                  {content.finish.bookmarkHighlight[locale]}
                </b>
                {content.finish.bookmarkRegular[locale]}
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </DataPreparer>
  );
};
