import { getLocation } from "./location.get";
import { getCatalogue } from "./meta.catalogue.get";
import { getHourly } from "./hourly.get";
import { getOffering } from "./meta.offering.get";
import { getSupportedLocations } from "./meta.supported-locations.get";
import { getDaily } from "./daily.get";
import { getCoupon } from "./coupon.get";
import { getCommercialConfig } from "./config.commercial.get";
import { setCommercialConfig } from "./config.commercial.set";
import { getResidentialConfig } from "./config.residential.get";
import { setResidentialConfig } from "./config.residential.set";

export const api = {
  getLocation,
  getCatalogue,
  getCoupon,
  getDaily,
  getHourly,
  getOffering,
  getSupportedLocations,
  getCommercialConfig,
  setCommercialConfig,
  getResidentialConfig,
  setResidentialConfig,
};
