export const content = {
  h1: {
    en: "Services",
    ar: "خدمات",
    ku: "خزمەتگوزارییەکان",
  },
  h2: {
    en: "A wide variety of professional support to meet your needs",
    ar: "مجموعة متنوعة من الدعم المهني لتلبية احتياجاتك",
    ku: "جۆرەها پشتگیری پیشەیی بۆ دابینکردنی پێداویستییەکانتان",
  },
};
