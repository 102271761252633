export const content = {
  description: {
    en: "Main provider of high quality solar products and services in Iraq.",
    ar: "المزود الرئيسي لمنتجات وخدمات الطاقة الشمسية عالية الجودة في العراق.",
    ku: "دابینکەری سەرەکی بەرهەم و خزمەتگوزاری وزەی خۆر بە کوالێتی بەرز لە عێراق.",
  },
  solutions: {
    en: "Solutions",
    ar: "حلول",
    ku: "چارەسەرەکان",
  },
  commercial: {
    en: "Commercial",
    ar: "تجاري",
    ku: "بازرگانی",
  },
  residential: {
    en: "Residential",
    ar: "سكني",
    ku: "شوێنی نیشتەجێبوون",
  },
  support: {
    en: "Support",
    ar: "الدعم",
    ku: "پشتیوانی",
  },
  getSupport: {
    en: "Get Support",
    ar: "احصل على الدعم",
    ku: "پشتگیرى وەرگره",
  },
  business: {
    en: "Business",
    ar: "اعمال",
    ku: "بزنس",
  },
  about: {
    en: "About Us",
    ar: "معلومات عنا",
    ku: "دەربارەی ئێمە",
  },
  email: {
    en: "Email Us",
    ar: "ارسل لنا عبر البريد الإلكتروني",
    ku: "ئیمەیڵمان بۆ بنێرە",
  },
  copyright: {
    en: "© Copyright 2022 Toptaqa. All rights reserved.",
    ar: "© Copyright 2022 Toptaqa. All rights reserved.",
    ku: "© Copyright 2022 Toptaqa. All rights reserved.",
  },
};
