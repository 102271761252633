export const content = {
  h1: {
    en: "Our Values",
    ar: "قيمنا",
    ku: "بەهاکانمان",
  },
  h2: {
    en: "We work on important real-world problems but have fun while doing so.",
    ar: "نحن نعمل على حل مشكلات مهمة في العالم الحقيقي ولكن نستمتع أثناء القيام بذلك.",
    ku: "ئێمە کار لەسەر کێشە گرنگەکانی جیهانی ڕاستەقینە دەکەین بەڵام لەکاتی ئەنجامدانیدا کاتێکی خۆش بەسەر دەبەین.",
  },
  title1: {
    en: "Create value.",
    ar: "خلق القيمة.",
    ku: "بەها دروست بکە.",
  },
  description1: {
    en: "Solve real problem and create true value. It's more rewarding when what you do matters.",
    ar: "حل مشكلة حقيقية وخلق قيمة حقيقية. يكون الأمر مجزيًا أكثر عندما يكون ما تفعله مهمًا.",
    ku: "چارەسەری کێشەی ڕاستەقینە و دروستکردنی بەهای ڕاستەقینە.  ئەوە پاداشتێکی زیاترە کاتێک ئەوەی تۆ دەیکەیت گرنگە",
  },
  title2: {
    en: "Deliver quality.",
    ar: "تقديم الجودة.",
    ku: "کوالیتی گەیاندن.",
  },
  description2: {
    en: "Even if the beauty of quality is missed with the naked eyes at times.",
    ar: "حتى لو غاب جمال الجودة بالعين المجردة في بعض الأحيان.",
    ku: "تەنانەت ئەگەر لە هەندێک کاتدا جوانی کوالیتی بە چاوی ڕوون لەدەست بدرێت.",
  },
  title3: {
    en: "Keep it simple.",
    ar: "أبقيها بسيطة.",
    ku: "بە سادەیی بیهێڵەرەوە.",
  },
  description3: {
    en: "Simplify til it becomes practical.",
    ar: "تبسيط حتى يصبح عمليًا.",
    ku: "سادە ی تا ئە بيت بە کردار",
  },
  title4: {
    en: "Repay the customer.",
    ar: "السداد للعميل.",
    ku: "پارەی کڕیار بدە رەوە",
  },
  description4: {
    en: "Customers put their trust in us. Repay them by delivering on our promise.",
    ar: "يضع العملاء ثقتهم فينا. رد عليهم بالوفاء بوعدنا.",
    ku: "کڕیاران متمانەی خۆیان بە ئێمە داوە.  بە جێبەجێکردنی بەڵێنەکەمان ئە ی دە ينە وە.",
  },
  title5: {
    en: "Be a teamplayer.",
    ar: "كن لاعب فريق.",
    ku: "یاریزانی تیم بە.",
  },
  description5: {
    en: "As a team we get more done while having more fun. Help and learn from eachother.",
    ar: "كفريق واحد ، ننجز المزيد بينما نحظى بمزيد من المرح. مساعدة وتعلم من بعضنا البعض.",
    ku: "وەک تیمێک کاری زيياتر ئەنجام دەدەین لە هەمان کاتدا کاتێکی خۆشتر بەسەر دەبەین.  یارمەتی یەکتر بدەن و فێربن.",
  },
  title6: {
    en: "Keep developing.",
    ar: "استمر في التطور.",
    ku: "بەردەوام بە لە گەشەکردن.",
  },
  description6: {
    en: "Challenge yourself and learn new things.",
    ar: "تحدى نفسك وتعلم أشياء جديدة.",
    ku: "تەحەدای خۆت بکە و شتی نوێ فێربە.",
  },
};
