import DataSet from "@antv/data-set";
import { View } from "@antv/data-set/lib/view";
import {
  Area,
  Axis,
  Chart,
  Coordinate,
  Interval,
  LineAdvance,
} from "bizcharts";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import wNumb from "wnumb";
import { getArrayWattage } from "../../../../helpers/panel/get.array-wattage";
import { getAzimuth } from "../../../../helpers/solar-geometry/get.azimuth";
import { getDeclination } from "../../../../helpers/solar-geometry/get.declination";
import { getElevation } from "../../../../helpers/solar-geometry/get.elevation";
import { getEoT } from "../../../../helpers/solar-geometry/get.EoT";
import { getHRA } from "../../../../helpers/solar-geometry/get.HRA";
import { getLST } from "../../../../helpers/solar-geometry/get.LST";
import { getLSTM } from "../../../../helpers/solar-geometry/get.LSTM";
import { getTC } from "../../../../helpers/solar-geometry/get.TC";
import { getSModule } from "../../../../helpers/solar-insulation/get.sModule";
import { RootState } from "../../../../store/config";
import { IHourly } from "../../../../types/power/hourly";
import { interpolateBasis } from "d3-interpolate";
import { DateTime } from "luxon";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../../components/swiper.css";
import { Navigation, Pagination } from "swiper";
import colors from "tailwindcss/colors";
import { content } from "../content";

export const CommercialSimulationSection = () => {
  const [monthlyDV, setMonthlyDV] = useState<View>();
  const [dailyDV, setDailyDV] = useState<View>();
  const [paybackDV, setPaybackDV] = useState<View>();
  const kwh_formatter = wNumb({ suffix: " kWh", decimals: 0, thousand: "," });
  const usd_formatter = wNumb({
    prefix: "$",
    suffix: "",
    decimals: 0,
    thousand: " ",
  });

  const location = useSelector((state: RootState) => state.location.data);
  const { locale, direction } = useSelector(
    (state: RootState) => state.localization
  );

  const { moduleOrientation, moduleTilt } = useSelector(
    (state: RootState) => state.cpq.commercial.config
  );

  const monthlyConsumption = useSelector(
    (state: RootState) => state.cpq.commercial.config.consumption
  );
  const uptime = useSelector(
    (state: RootState) => state.cpq.commercial.config.gridUptime
  );
  const priceTotal = useSelector(
    (state: RootState) => state.cpq.commercial.config.price.total
  );
  const panelSelected = useSelector(
    (state: RootState) => state.cpq.commercial.config.panel
  );
  const panelQuantity = useSelector(
    (state: RootState) => state.cpq.commercial.config.panelQty
  );

  const hourly = useSelector((state: RootState) =>
    location?.id ? state.meteorology.hourly[location.id] : null
  );

  useEffect(() => {
    const ds = new DataSet();
    const dv = ds.createView();
    if (!hourly?.data) return;
    if (!panelSelected) return;

    dv.source([...hourly.data]);

    const arrayWattage = getArrayWattage(panelSelected, panelQuantity);
    const consumptionArr = Object.values(monthlyConsumption).map(
      (val) => val / (30.5 * 24)
    );
    const consumptionInterpolator = interpolateBasis(consumptionArr);
    dv.transform({
      type: "map",
      callback(row: IHourly) {
        if (!hourly?.data) return { ...row };
        if (!location?.coordinates?.longitude) return { ...row };
        if (!location?.coordinates?.latitude) return { ...row };
        if (!location?.price?.USD?.commercial) return { ...row };

        // Tilts and angle
        const LT = row.hour;
        const LSTM = getLSTM(3);
        const EoT = getEoT(row.dayOfYear);
        const TC = getTC(location.coordinates.longitude, LSTM, EoT);
        const LST = getLST(LT, TC);
        const declination = getDeclination(row.dayOfYear);
        const HRA = getHRA(location.coordinates.longitude, row.dayOfYear, LT);

        const elevationAngle = getElevation(
          location.coordinates.latitude,
          HRA,
          declination
        );

        const solarAzimuth = getAzimuth(
          declination,
          location.coordinates.latitude,
          elevationAngle,
          HRA,
          LST
        );

        const s_incident = row.dni + row.dif;
        const s_module = getSModule(
          s_incident,
          elevationAngle,
          moduleTilt,
          solarAzimuth,
          moduleOrientation
        );

        const theoretical_output_kwh =
          (s_module / 1000) * (arrayWattage / 1000);

        const practical_output_kwh = theoretical_output_kwh * uptime;
        // COnsumption interpolater
        const hoursInYear =
          DateTime.fromMillis(hourly.data[0].datetime).daysInYear * 24;
        const startDate = DateTime.fromMillis(hourly.data[0].datetime).startOf(
          "year"
        );
        const datetime = DateTime.fromMillis(row.datetime);
        const diff = Math.floor(datetime.diff(startDate, "hours").hours);
        const progress = diff / hoursInYear;
        const consumption_kwh = consumptionInterpolator(progress);
        const kwh_price = location.price.USD.commercial;
        return {
          datetime: row.date,
          bill_savings: practical_output_kwh * kwh_price,
          hour: row.hour,
          consumption_kwh,
          date: row.date,
          month: row.month,
          year: row.year,
          output_practical_kwh: practical_output_kwh,
          output_theoretical_kwh: theoretical_output_kwh,
          dni: row.dni / 1000,
        };
      },
    });

    const dailyDv = ds.createView();
    const monthlyDv = ds.createView();
    const paybackDV = ds.createView();
    const paybackTempDV = ds.createView();
    dailyDv.source([...dv.rows]);
    monthlyDv.source([...dv.rows]);
    paybackTempDV.source([...dv.rows]);

    dailyDv.transform({
      type: "aggregate",
      fields: [
        "output_theoretical_kwh",
        "output_practical_kwh",
        "consumption_kwh",
      ], // 统计字段集
      operations: ["sum", "sum", "sum"], // 统计操作集
      as: [
        "output_theoretical_kwh_daily",
        "output_practical_kwh_daily",
        "consumption_kwh_daily",
      ], // 存储字段集
      groupBy: ["datetime"], // 分组字段集
    });

    monthlyDv.transform({
      type: "aggregate",
      fields: [
        "output_theoretical_kwh",
        "output_practical_kwh",
        "consumption_kwh",
        "bill_savings",
      ], // 统计字段集
      operations: ["sum", "sum", "sum", "sum"], // 统计操作集
      as: [
        "output_theoretical_kwh_monthly",
        "output_practical_kwh_monthly",
        "consumption_kwh_monthly",
        "bill_savings",
      ], // 存储字段集
      groupBy: ["month"], // 分组字段集
    });

    paybackTempDV.transform({
      type: "aggregate",
      fields: ["output_practical_kwh"], // 统计字段集
      operations: ["sum"], // 统计操作集
      as: ["output_practical_kwh"], // 存储字段集
      groupBy: ["year"], // 分组字段集
    });

    const anual_output_kwh = paybackTempDV.rows[0]["output_practical_kwh"];
    const degradation = panelSelected.degradation;
    const paybackDataset = [];
    for (let i = 0; i < 25; i++) {
      const output_kwh = anual_output_kwh * (1 - degradation * i);
      const prevSavings: number =
        i > 0 ? paybackDataset[i - 1].savingsAccumulated : 0;
      const savings = location?.price.USD.commercial
        ? output_kwh * location?.price.USD.commercial
        : 0;

      paybackDataset.push({
        year: i + 1,
        savings: savings,
        savingsAccumulated: prevSavings + savings,
        investment: priceTotal,
      });
    }

    paybackDV.source(paybackDataset);
    setPaybackDV(paybackDV);
    setDailyDV(dailyDv);
    setMonthlyDV(monthlyDv);
  }, [
    uptime,
    priceTotal,
    monthlyConsumption,
    hourly?.data,
    panelQuantity,
    panelSelected,
    location?.coordinates.longitude,
    location?.coordinates.latitude,
    location?.price?.USD?.commercial,
  ]);

  let maxKwhMonthly = 0;
  if (monthlyDV) {
    maxKwhMonthly = Math.max(
      monthlyDV.max("consumption_kwh_monthly"),
      monthlyDV.max("output_practical_kwh_monthly"),
      monthlyDV.max("output_theoretical_kwh_monthly")
    );
  }
  let maxKwhDaily = 0;
  if (dailyDV) {
    maxKwhDaily = Math.max(
      dailyDV.max("consumption_kwh_daily"),
      dailyDV.max("output_kwh_daily")
    );
  }
  let maxPaybackAxis = 0;
  if (paybackDV) {
    maxPaybackAxis = Math.max(
      paybackDV.max("savings"),
      paybackDV.max("savingsAccumulated"),
      paybackDV.max("investment")
    );
  }
  const scaleCfg = {
    savings: {
      tickCount: 12,
      min: 0,
      max: maxPaybackAxis * 1.1,
      alias: "Bill Savings",
      formatter: (v: number) => usd_formatter.to(v),
    },
    savingsAccumulated: {
      tickCount: 12,
      min: 0,
      max: maxPaybackAxis * 1.1,
      alias: "Bill Savings Acc.",
      formatter: (v: number) => usd_formatter.to(v),
    },
    investment: {
      tickCount: 12,
      min: 0,
      max: maxPaybackAxis * 1.1,
      alias: "Investment",
      formatter: (v: number) => usd_formatter.to(v),
    },
    output_theoretical_kwh_daily: {
      tickCount: 12,
      min: 0,
      max: dailyDV ? dailyDV.max("output_theoretical_kwh_daily") * 1.1 : 0,
      alias: "System Production",
      formatter: (v: number) => kwh_formatter.to(v),
    },
    output_practical_kwh_daily: {
      tickCount: 12,
      min: 0,
      max: dailyDV ? dailyDV.max("output_practical_kwh_daily") * 1.1 : 0,
      alias: "System Production",
      formatter: (v: number) => kwh_formatter.to(v),
    },

    consumption_kwh_daily: {
      tickCount: 12,
      min: 0,
      max: maxKwhDaily * 1.1,
      alias: "Consumption",
      formatter: (v: number) => kwh_formatter.to(v),
    },
    output_theoretical_kwh_monthly: {
      tickCount: 12,
      min: 0,
      max: maxKwhMonthly * 1.1,
      alias: "System Production",
      formatter: (v: number) => kwh_formatter.to(v),
    },
    output_practical_kwh_monthly: {
      tickCount: 12,
      min: 0,
      max: maxKwhMonthly * 1.1,
      alias: "Utilised Production",
      formatter: (v: number) => kwh_formatter.to(v),
    },
    consumption_kwh_monthly: {
      tickCount: 12,
      min: 0,
      max: maxKwhMonthly * 1.1,
      alias: "Consumption",
      formatter: (v: number) => kwh_formatter.to(v),
    },
    bill_savings: {
      tickCount: 12,
      min: 0,
      max: monthlyDV ? monthlyDV.max("bill_savings") * 1.1 : 0,
      alias: "Bill Savings (USD)",
      formatter: (v: number) => usd_formatter.to(v),
    },
    datetime: {
      alias: "Date",
      type: "timeCat",
      nice: true,
      mask: "MMM DD",
    },
    date: {
      tickCount: 12,
      alias: "Date",
      type: "timeCat",
      nice: true,
      mask: "MMMM",
    },
    month: {
      tickCount: 12,
      alias: "Month",
      type: "timeCat",
      nice: true,
      mask: "MMMM",
    },
    year: {
      tickCount: 12,
      alias: "Year",
    },
  };

  const grid = {
    line: {
      type: "line",
      style: {
        stroke: colors.gray[300],
        lineWidth: 0.25,
      },
    },
  };

  const line = {
    style: {
      stroke: colors.gray[700],
      lineWidth: 0.25,
    },
  };

  return (
    <>
      <h1 className="pb-10 text-center text-4xl font-medium text-slate-900 leading-tight">
        {content.simulation.title[locale]}
      </h1>
      <Swiper
        pagination
        navigation
        modules={[Pagination, Navigation]}
        slidesPerView={1}
        allowTouchMove={false}
        className="md:rounded-2xl bg-gray-50"
        style={{ direction: "ltr" }}
      >
        <SwiperSlide>
          <div className="flex w-full py-6" style={{ direction: direction }}>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {`~${
                  monthlyDV
                    ? usd_formatter.to(monthlyDV?.sum("bill_savings"))
                    : 0
                }`}
              </p>
              <p className="text-xs text-slate-700">Annual Savings</p>
            </div>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {`~${
                  monthlyDV
                    ? wNumb({ decimals: 0 }).to(
                        priceTotal / monthlyDV?.sum("bill_savings")
                      )
                    : 0
                } years`}
              </p>
              <p className="text-xs text-slate-700">Payback Period</p>
            </div>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {usd_formatter.to(priceTotal)}
              </p>
              <p className="text-xs text-slate-700">Initial Investment</p>
            </div>
          </div>
          <div
            className="px-6 md:flex justify-between"
            style={{ direction: direction }}
          >
            <p>Investment and Bill Savings by Year</p>
            <div className="mt-3 md:mt-0 md:flex justify-start">
              <div className="flex items-center mt-1.5 md:mt-0">
                <div className="bg-blue-600 w-2 aspect-square rounded-full" />
                <p className="ltr:ml-1.5 rtl:mr-1.5 text-xs font-light">
                  Investment
                </p>
              </div>
              <div className="flex items-center md:ml-3 mt-1 md:mt-0">
                <div className="bg-green-500 w-2 aspect-square rounded-full" />
                <p className="ltr:ml-1.5 rtl:mr-1.5 text-xs font-light">
                  Acc. Bill Savings
                </p>
              </div>
              <div className="flex items-center md:ml-3 mt-1 md:mt-0">
                <div className="bg-green-400 w-2 aspect-square rounded-full" />
                <p className="ltr:ml-1.5 rtl:mr-1.5 text-xs font-light">
                  Monthly Bill Savings
                </p>
              </div>
            </div>
          </div>
          <div className="w-full aspect-square md:aspect-video max-h-[480px]">
            <div className="p-6 w-full h-full">
              <Chart
                padding="auto"
                appendPadding={[4, 0, 20, 0]}
                autoFit
                scale={scaleCfg}
                data={paybackDV ? paybackDV.rows : []}
              >
                <Coordinate type="rect" />
                <Axis
                  name="year"
                  line={line}
                  grid={grid}
                  tickLine={{ length: 0 }}
                />
                <Axis
                  name="savingsAccumulated"
                  visible={true}
                  grid={grid}
                  line={line}
                />
                <Axis name="investment" visible={false} />
                <Axis name="savings" visible={false} />
                <LineAdvance
                  shape="smooth"
                  point
                  area
                  position="year*savingsAccumulated"
                  color={colors.green[500]}
                />
                <Interval
                  shape="smooth"
                  point
                  size={4}
                  position="year*savings"
                  color={colors.green[300]}
                />
                <LineAdvance
                  shape="smooth"
                  point
                  position="year*investment"
                  color={colors.blue[500]}
                />
              </Chart>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex w-full py-6 " style={{ direction: direction }}>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {monthlyDV
                  ? usd_formatter.to(monthlyDV.average("bill_savings"))
                  : 0}
              </p>
              <p className="text-xs text-slate-700">Average</p>
            </div>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {monthlyDV
                  ? usd_formatter.to(monthlyDV.max("bill_savings"))
                  : 0}
              </p>
              <p className="text-xs text-slate-700">Maximum</p>
            </div>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {monthlyDV
                  ? usd_formatter.to(monthlyDV?.min("bill_savings"))
                  : 0}
              </p>
              <p className="text-xs text-slate-700">Minimum</p>
            </div>
          </div>
          <div
            className="px-6 md:flex justify-between"
            style={{ direction: direction }}
          >
            <p>Bill Savings by Month</p>
            <div className="mt-3 md:mt-0 md:flex justify-start">
              <div className="flex items-center md:ml-3 mt-1 md:mt-0">
                <div className="bg-green-500 w-2 aspect-square rounded-full" />
                <p className="ltr:ml-1.5 rtl:mr-1.5 text-xs font-light">
                  Monthly Bill Savings
                </p>
              </div>
            </div>
          </div>
          <div className="w-full aspect-square md:aspect-video max-h-[480px]">
            <div className="p-6 w-full h-full">
              <Chart
                padding="auto"
                appendPadding={[4, 0, 20, 0]}
                autoFit
                scale={scaleCfg}
                data={monthlyDV ? monthlyDV.rows : []}
              >
                <Axis
                  name="date"
                  line={line}
                  grid={grid}
                  tickLine={{ length: 0 }}
                />
                <Axis name="bill_savings" line={line} grid={grid} />
                <Interval
                  position="date*bill_savings"
                  size={16}
                  label={"bill_savings"}
                  color={colors.green[500]}
                />
              </Chart>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex w-full py-6" style={{ direction: direction }}>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {wNumb({ suffix: "%", decimals: 0 }).to(uptime * 100)}
              </p>
              <p className="text-xs text-slate-700">Grid Uptime</p>
            </div>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {monthlyDV
                  ? kwh_formatter.to(
                      monthlyDV?.sum("output_theoretical_kwh_monthly")
                    )
                  : 0}
              </p>
              <p className="text-xs text-slate-700">Production /yr</p>
            </div>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {monthlyDV
                  ? kwh_formatter.to(
                      monthlyDV?.sum("output_practical_kwh_monthly")
                    )
                  : 0}
              </p>
              <p className="text-xs text-slate-700">Utilised</p>
            </div>
          </div>
          <div
            className="px-6 md:flex justify-between"
            style={{ direction: direction }}
          >
            <p>Electricity Production by Month</p>
            <div className="mt-3 md:mt-0 md:flex justify-start">
              <div className="flex items-center md:ml-3 mt-1 md:mt-0">
                <div className="bg-gray-200 w-2 aspect-square rounded-full" />
                <p className="ltr:ml-1.5 rtl:mr-1.5 text-xs font-light">
                  System Production
                </p>
              </div>
              <div className="flex items-center md:ml-3 mt-1 md:mt-0">
                <div className="bg-amber-500 border border-amber-600 w-2 aspect-square rounded-full" />
                <p className="ltr:ml-1.5 rtl:mr-1.5 text-xs font-light">
                  Utilised Production
                </p>
              </div>
            </div>
          </div>
          <div className="w-full aspect-square md:aspect-video max-h-[480px]">
            <div className="p-6 w-full h-full">
              <Chart
                padding="auto"
                appendPadding={[4, 0, 20, 0]}
                autoFit
                scale={scaleCfg}
                data={monthlyDV ? monthlyDV.rows : []}
              >
                <Coordinate type="rect" />
                <Axis
                  name="date"
                  line={line}
                  grid={grid}
                  tickLine={{ length: 0 }}
                />
                <Axis
                  name="output_theoretical_kwh_monthly"
                  grid={grid}
                  line={line}
                />
                <Axis name="output_practical_kwh_monthly" visible={false} />
                <Area
                  shape="smooth"
                  position="date*output_theoretical_kwh_monthly"
                  style={{ fillOpacity: 0.75 }}
                  color={`l(270) 0:${colors.gray[200]} 1:${colors.gray[200]}`}
                  size={1}
                />
                <Area
                  shape="smooth"
                  tooltip={false}
                  position="date*output_practical_kwh_monthly"
                  color={colors.amber[500]}
                />
                <LineAdvance
                  shape="smooth"
                  point
                  position="date*output_practical_kwh_monthly"
                  color={colors.amber[600]}
                />
              </Chart>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex w-full py-6" style={{ direction: direction }}>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {monthlyDV
                  ? kwh_formatter.to(monthlyDV?.sum("consumption_kwh_monthly"))
                  : 0}
              </p>
              <p className="text-xs text-slate-700">Consumption /yr</p>
            </div>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {monthlyDV
                  ? kwh_formatter.to(
                      monthlyDV?.sum("output_theoretical_kwh_monthly")
                    )
                  : 0}
              </p>
              <p className="text-xs text-slate-700">Production /yr</p>
            </div>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {monthlyDV && monthlyDV?.sum("consumption_kwh_monthly") > 0
                  ? wNumb({ suffix: "%", decimals: 0 }).to(
                      100 *
                        (monthlyDV?.sum("output_theoretical_kwh_monthly") /
                          monthlyDV?.sum("consumption_kwh_monthly"))
                    )
                  : "N/A"}
              </p>
              <p className="text-xs text-slate-700">% of Consumption</p>
            </div>
          </div>
          <div
            className="px-6 md:flex justify-between"
            style={{ direction: direction }}
          >
            <p>Electricity Production and Consumption by Month</p>
            <div className="mt-3 md:mt-0 md:flex justify-start">
              <div className="flex items-center md:ml-3 mt-1 md:mt-0">
                <div className="bg-blue-600 w-2 aspect-square rounded-full" />
                <p className="ltr:ml-1.5 rtl:mr-1.5 text-xs font-light">
                  Consumption
                </p>
              </div>
              <div className="flex items-center md:ml-3 mt-1 md:mt-0">
                <div className="bg-amber-500 border border-amber-600 w-2 aspect-square rounded-full" />
                <p className="ltr:ml-1.5 rtl:mr-1.5 text-xs font-light">
                  System Production
                </p>
              </div>
            </div>
          </div>
          <div className="w-full aspect-square md:aspect-video max-h-[480px]">
            <div className="p-6 w-full h-full">
              <Chart
                padding="auto"
                appendPadding={[4, 0, 20, 0]}
                autoFit
                scale={scaleCfg}
                data={monthlyDV ? monthlyDV.rows : []}
              >
                <Coordinate type="rect" />
                <Axis
                  name="date"
                  line={line}
                  grid={grid}
                  tickLine={{ length: 0 }}
                />
                <Axis name="consumption_kwh_monthly" visible={false} />
                <Axis
                  name="output_theoretical_kwh_monthly"
                  line={line}
                  grid={grid}
                />
                <Area
                  shape="smooth"
                  tooltip={false}
                  position="date*output_theoretical_kwh_monthly"
                  color={colors.amber[500]}
                />
                <LineAdvance
                  shape="smooth"
                  point
                  position="date*output_theoretical_kwh_monthly"
                  color={colors.amber[600]}
                />
                <LineAdvance
                  shape="smooth"
                  point
                  position="date*consumption_kwh_monthly"
                  color={colors.blue[600]}
                />
              </Chart>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex w-full py-6" style={{ direction: direction }}>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {dailyDV
                  ? kwh_formatter.to(
                      dailyDV?.average("output_practical_kwh_daily")
                    )
                  : 0}
              </p>
              <p className="text-xs text-slate-700">Average</p>
            </div>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {dailyDV
                  ? kwh_formatter.to(dailyDV?.max("output_practical_kwh_daily"))
                  : 0}
              </p>
              <p className="text-xs text-slate-700">Maximum</p>
            </div>
            <div className="flex flex-col flex-1 justify-center items-center">
              <p className="font-medium text-slate-900">
                {dailyDV
                  ? kwh_formatter.to(dailyDV?.min("output_practical_kwh_daily"))
                  : 0}
              </p>
              <p className="text-xs text-slate-700">Minimum</p>
            </div>
          </div>
          <div
            className="px-6 md:flex justify-between"
            style={{ direction: direction }}
          >
            <p>Electricity Production by Day</p>
            <div className="mt-3 md:mt-0 md:flex justify-start">
              <div className="flex items-center md:ml-3 mt-1 md:mt-0">
                <div className="bg-amber-500 border border-amber-600 w-2 aspect-square rounded-full" />
                <p className="ltr:ml-1.5 rtl:mr-1.5 text-xs font-light">
                  System Production
                </p>
              </div>
            </div>
          </div>
          <div className="w-full aspect-square md:aspect-video max-h-[480px]">
            <div className="p-6 w-full h-full">
              <Chart
                padding="auto"
                appendPadding={[4, 0, 20, 0]}
                autoFit
                scale={scaleCfg}
                data={dailyDV ? dailyDV.rows : []}
              >
                <Axis name="datetime" line={line} grid={grid} />
                <Axis
                  name="output_practical_kwh_daily"
                  line={line}
                  grid={grid}
                />
                <LineAdvance
                  shape="smooth"
                  area
                  size={1}
                  position="datetime*output_practical_kwh_daily"
                  color={colors.amber[600]}
                />
              </Chart>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
