import { acosd } from "../math/acosd";
import { cosd } from "../math/cosd";
import { sind } from "../math/sind";

export const getAzimuth = (
  declination: number,
  latitude: number,
  elevationAngle: number,
  HRA: number,
  LST: number
) => {
  const Az = acosd(
    (sind(declination) * cosd(latitude) -
      cosd(declination) * sind(latitude) * cosd(HRA)) /
      cosd(elevationAngle)
  );

  let azimuth = Az;
  if (HRA > 0 || LST > 12) azimuth = 360 - Az;
  return azimuth;
};
