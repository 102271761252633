import { useRef, useState } from "react";
import wNumb, { Instance } from "wnumb";

export const Input = ({
  label,
  value,
  onChange,
  formatter,
  className,
  type = "text",
}: {
  label: string;
  value: any;
  onChange: (val: any) => void;
  formatter?: Instance;
  className?: string;
  type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!inputRef.current?.value) return;
    if (e.key === "Enter") {
      if (formatter instanceof wNumb) {
        onChange(formatter.from(inputRef.current.value));
        inputRef.current.value = formatter.to(
          formatter.from(inputRef.current.value)
        );
      } else {
        onChange(inputRef.current.value);
      }
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!inputRef.current?.value) return;
    if (formatter instanceof wNumb) {
      onChange(formatter.from(inputRef.current.value));
      inputRef.current.value = formatter.to(
        formatter.from(inputRef.current.value)
      );
    } else {
      onChange(inputRef.current.value);
    }
  };

  const handleFocus = () => {
    inputRef.current?.focus();
  };

  return (
    <div
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onClick={handleFocus}
      className={`  ${focus ? "ring-[6px]" : ""} ${className} rounded-xl`}
    >
      <div
        className={`py-2 px-3 flex flex-col ring-1 ${
          focus ? "ring-2 ring-blue-600" : "hover:ring-gray-500"
        } ring-gray-400 rounded-xl`}
      >
        <label className="text-xs font-normal text-slate-500">{label}</label>
        <input
          ref={inputRef}
          defaultValue={
            formatter instanceof wNumb ? formatter.to(value) : value
          }
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          type={type}
          className="outline-none"
        />
      </div>
    </div>
  );
};
