export const content = {
  h1: {
    en: "Energy Storage System",
    ar: "نظام تخزين الطاقة",
    ku: "سیستەمی هەڵگرتنی وزە",
  },
  h2: {
    en: "All-in-One residential solution",
    ar: "حل سكني الكل في واحد",
    ku: "چارەسەرکردنی کێشەکان بە یەکجار",
  },
  heroTitle: {
    en: "Residential",
    ar: "سكني",
    ku: "شوێنی نیشتەجێبوون",
  },
  featureTitle: {
    en: "Product Features",
    ar: "مواصفات المنتج",
    ku: "تای بە تمەندی بە رهە مە کان",
  },
  featureDescription: {
    en: "A complete energy storage system with solar charge controrllers, inverter and batteries.",
    ar: "نظام تخزين طاقة كامل مع أجهزة تحكم في الشحن بالطاقة الشمسية وعاكس وبطاريات.",
    ku: "سیستەمێکی تەواو بۆ هەڵگرتنی وزە بە کۆنتڕۆڵکەری شەحنی خۆر و ئینڤێرتەر و پاتری",
  },
  featureBullet1: {
    en: "Storage capacity 9.6 kWh",
    ar: "سعة التخزين 9.6 كيلو واط ساعة",
    ku: "توانای هەڵگرتن 9.6 کیلۆوات کاتژمێر",
  },
  featureBullet2: {
    en: "Lifetime of 6000 cycles",
    ar: "عمر 6000 دورة",
    ku: "تەمەنی ٦٠٠٠ خول",
  },
  featureBullet3: {
    en: "LiFePO4 battery",
    ar: "بطارية LiFePO4",
    ku: "پاتری LiFePO4",
  },
  featureBullet4: {
    en: "Efficiency of 97.6%",
    ar: "كفاءة %97.6",
    ku: "کارایی %97.6",
  },
  featureBullet5: {
    en: "10 year warranty",
    ar: "ضمان 10 سنوات",
    ku: "گرە نتی 10 ساڵ",
  },
  button: {
    en: "Datasheet",
    ar: "ورقة البيانات",
    ku: "لاپەڕەی داتا",
  },
};
