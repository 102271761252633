import { useState, useEffect } from "react";

type IScreenSize = "sm" | "md" | "lg" | "xl" | "2xl";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  let screenSize: IScreenSize = "2xl"; // 1536
  if (width < 1536) screenSize = "xl";
  if (width < 1280) screenSize = "lg";
  if (width < 1024) screenSize = "md";
  if (width < 768) screenSize = "sm";
  return {
    width,
    height,
    screenSize,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
