import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const SupportHero = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="pt-44 pb-[104px] bg-white">
      <div className="max-w-4xl px-6 md:mx-auto">
        <div className="md:text-center">
          <h1 className="mb-4 text-base text-blue-600 font-medium ltr:tracking-widest uppercase">
            {content.h1[locale]}
          </h1>
          <h2 className="text-3xl md:text-5xl font-bold ltr:tracking-widest mb-4 text-slate-800">
            {content.h2[locale]}
          </h2>
          <p className="max-w-2xl font-medium text-xl md:text-2xl ltr:tracking-wider text-slate-700 md:mx-auto">
            <span className="bg-yellow-300 text-slate-800">
              {content.highlighted[locale]}
            </span>
            {content.p[locale]}
          </p>
        </div>
      </div>
    </div>
  );
};
