import { createListenerMiddleware } from "@reduxjs/toolkit";
import { simulationDataFromHourly } from "../../helpers/datasets/simulation-data.from-hourly";
import { RootState } from "../config";
import {
  setAvgDailyOutputPerPanel,
  setResidentialSimulationData,
} from "../slices/cpq/residential";

// Create the middleware instance and methods
export const residentialSimulationTrigger = createListenerMiddleware();

residentialSimulationTrigger.startListening({
  predicate: (action, currentState, previousState) => {
    // return true when the listener should run
    const listenedAction = [
      "residential/setResidentialPanel",
      "hourly/fetchHourly/fulfilled",
    ];
    if (!listenedAction.includes(action.type)) return false;
    const curr = currentState as RootState;
    const prev = previousState as RootState;

    const locationId = curr.location.data?.id;
    if (!locationId) return false;

    const currPanelWatt = curr.cpq.residential.config.panel?.maxPowerWattage;
    const prevPanelWatt = prev.cpq.residential.config.panel?.maxPowerWattage;
    const panelWattIsChanged = currPanelWatt !== prevPanelWatt;

    const currHourly = curr.meteorology.hourly[locationId]?.data;
    const prevHourly = prev.meteorology.hourly[locationId]?.data;
    const hourlyIsChanged = currHourly !== prevHourly;

    return panelWattIsChanged || hourlyIsChanged;
  },

  effect: async (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    const state = listenerApi.getState() as RootState;

    const locationId = state.location.data?.id;
    const panelWatt = state.cpq.residential.config.panel?.maxPowerWattage;
    const longitude = state.location.data?.coordinates.longitude;
    const latitude = state.location.data?.coordinates.latitude;
    const supplyVoltage = state.location.data?.supplyVoltage;
    const moduleOrientation = state.cpq.residential.config.moduleOrientation;
    const moduleTilt = state.cpq.residential.config.moduleTilt;

    if (!panelWatt) return;
    if (!locationId) return;
    if (!longitude) return;
    if (!latitude) return;
    if (!supplyVoltage) return;

    const hourly = state.meteorology.hourly[locationId].data;
    if (!hourly) return;

    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    const data = simulationDataFromHourly(
      hourly,
      panelWatt,
      longitude,
      latitude,
      supplyVoltage,
      moduleTilt,
      moduleOrientation
    );

    listenerApi.dispatch(
      setResidentialSimulationData({
        data: data.rows,
      })
    );

    listenerApi.dispatch(
      setAvgDailyOutputPerPanel({
        avgDailyOutputPerPanel: data.avgDailyOutputPerPanel,
      })
    );
  },
});
