import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../store/config";
import { IDireciton } from "../../../store/slices/localization";

type StyledProps = {
  direction: IDireciton;
};

const Styled = styled.h1<StyledProps>`
  font-size: 1.0625rem;
  font-weight: 300;
  color: white;
  letter-spacing: ${(props) => (props.direction === "ltr" ? "0.5rem" : "0")};
  text-transform: uppercase;
`;

type Props = {
  children: string;
};

export const HeroH1 = ({ children }: Props) => {
  const direction = useSelector(
    (state: RootState) => state.localization.direction
  );

  return <Styled direction={direction}>{children}</Styled>;
};
