import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const AboutMission = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="py-24 mx-6 md:mx-auto max-w-5xl flex flex-col justify-center items-center text-center">
      <h1 className="mb-5 text-xl ltr:tracking-widest uppercase">
        {content.h1[locale]}
      </h1>
      <h2 className="text-3xl ltr:tracking-widest font-extralight leading-relaxed">
        {content.h2[locale]}
      </h2>
    </div>
  );
};
