import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../config";
import { setPrice, setSystem } from "../slices/cpq/commercial";

// Create the middleware instance and methods
export const commercialPriceTrigger = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
commercialPriceTrigger.startListening({
  matcher: isAnyOf(setSystem),
  effect: (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    const state = listenerApi.getState() as RootState;

    const panel = state.cpq.commercial.config.panel;
    const system = state.cpq.commercial.config.system;

    if (panel) {
      // Prices
      const ARRAY_PRICE_PER_WATT = panel.price.usd;
      const INVERTER_PRICE_PER_WATT = 0;

      const arrayPrice = ARRAY_PRICE_PER_WATT * system.arraySizeW;
      const inverterPrice = INVERTER_PRICE_PER_WATT * system.arraySizeW;

      const totalPrice = arrayPrice + inverterPrice;

      // First panel in the offering list
      const price = {
        array: arrayPrice,
        inverter: inverterPrice,
        total: totalPrice,
      };

      listenerApi.dispatch(setPrice({ price: price }));
    }
  },
});
