export const content = {
  h1: {
    en: "Solar Panels",
    ar: "الألواح الشمسية",
    ku: "پانێڵی خۆر",
  },
  h2: {
    en: "World's most technologically advanced solar panels",
    ar: "الألواح الشمسية الأكثر تقدمًا من الناحية التكنولوجية في العالم",
    ku: "پێشکەوتووترین پانێڵی خۆری جیهان لە ڕووی تەکنەلۆژیاوە",
  },
  heroDescription: {
    en: "Latest technology from Jinko Solar",
    ar: "أحدث التقنيات من Jinko Solar",
    ku: "نوێترین تەکنەلۆجیای کۆمپانیای Jinko Solar",
  },
  featureTitle: {
    en: "Product Features",
    ar: "مواصفات المنتج",
    ku: "تايبە ت مە ندی بە رهە مە کان",
  },
  featureDescription: {
    en: "The most technologically advanced solar panel in the world for a very cost-effective price.",
    ar: "أكثر الألواح الشمسية تقدمًا من الناحية التكنولوجية في العالم بسعر مناسب للغاية.",
    ku: "پێشکەوتووترین پانێڵی خۆر لە ڕووی تەکنەلۆژیاوە لە جیهاندا بە نرخێکی زۆر کەم تێچوون",
  },
  featureBullet1: {
    en: "Latest TOPCon technology",
    ar: "أحدث تقنيات TOPCon",
    ku: "نويترين تە کنە لۆجييای TopCon",
  },
  featureBullet2: {
    en: "Bifacial modules",
    ar: "وحدات ثنائية الوجه",
    ku: "مۆدیۆلی دوو ڕوو",
  },
  featureBullet3: {
    en: "World's highest efficiency at 27.34%",
    ar: "أعلى كفاءة في العالم بنسبة %27.3",
    ku: "بەرزترین کارایی جیهان بە ڕێژەی %27.34",
  },
  featureBullet4: {
    en: "Minimal losses in high temperature",
    ar: "الحد الأدنى من الخسائر في درجات الحرارة المرتفعة",
    ku: "کەمترین زیان لە پلەی گەرمی بەرزدا",
  },
  featureBullet5: {
    en: "30 year warranty",
    ar: "ضمان لمدة 30 سنة",
    ku: "گرەنتی 30 ساڵ",
  },
  button: {
    en: "Datasheet",
    ar: "ورقة البيانات",
    ku: "لاپەڕەی داتا",
  },
};
