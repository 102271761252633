import numeral from "numeral";
import { IoAlertCircleOutline } from "react-icons/io5";

export const ProductSelect = ({
  img,
  title,
  subTitle,
  price,
  pricePrefix,
  priceSuffix,
  selected,
  onClick,
  className,
  imgClassName,
  errorMessage = "",
}: {
  img?: string;
  title: string;
  subTitle?: string;
  price?: number;
  pricePrefix?: string;
  priceSuffix?: string;
  selected: boolean;
  onClick: () => void;
  className?: string;
  imgClassName?: string;
  errorMessage?: string;
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  const error = selected && errorMessage;

  return (
    <div className={`${className}`}>
      <div
        className={`${selected && "ring-[6px]"} ${
          error && "ring-red-200"
        } rounded-xl`}
      >
        <div
          tabIndex={9999}
          className={`ring-1 ${
            selected
              ? "ring-2 ring-blue-600"
              : "ring-gray-400 hover:ring-gray-500"
          } ${
            error && "ring-red-600"
          } p-4 flex items-center justify-between rounded-xl cursor-pointer`}
          onClick={handleClick}
        >
          <div className="flex items-center">
            {img && (
              <img
                src={img}
                alt={title}
                className={`${imgClassName} object-cover w-20 aspect-square rounded-md`}
              />
            )}
            <div className="ltr:ml-2 rtl:mr-2">
              <p className={`${subTitle ? "pt-2" : "py-5"} font-medium`}>
                {title}
              </p>
              <p
                className={
                  subTitle ? "pb-2 mt-1 font-normal text-xs" : "hidden"
                }
              >
                {subTitle}
              </p>
            </div>
          </div>
          {price && (
            <p className="font-light font">{`${pricePrefix}${numeral(
              price
            ).format("0,0")}${priceSuffix}`}</p>
          )}
        </div>
      </div>
      {error && (
        <div className="-mt-1 mb-4 flex items-center  text-red-600">
          <IoAlertCircleOutline className="text-" />
          <span className="ltr:ml-2 rtl:mr-2 text-xs">{errorMessage}</span>
        </div>
      )}
    </div>
  );
};
