import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.carousel.css";
import Slider, { Settings } from "react-slick";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const Carousel = () => {
  const { locale } = useSelector((state: RootState) => state.localization);
  const { width } = useWindowDimensions();
  const [slideIdx, setSlideIdx] = useState(0);
  let centerPadding = `${width}px`;
  if (width < 768) {
    // sm
    centerPadding = "64px";
  } else {
    centerPadding = `${(width - 960) / 2}px`;
  }

  const handleSwipe = (currentSlide: number, nextSlide: number) => {
    setSlideIdx(nextSlide);
  };

  const settings: Settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    centerMode: true,
    adaptiveHeight: true,
    centerPadding,
    beforeChange: handleSwipe,
  };

  return (
    <div className="py-24 bg-gray-100 overflow-hidden">
      <div className="mx-6 md:mx-16 lg:mx-auto">
        <h1 className="uppercase ltr:tracking-widest mb-4 text-center">
          {content.h1[locale]}
        </h1>
        <h2 className="text-3xl font-light ltr:tracking-widest mb-16 text-center">
          {content.h2[locale]}
        </h2>
      </div>
      <Slider {...settings}>
        <div className="p-2">
          <div
            className={`p-3 aspect-square md:flex md:aspect-video rounded-xl bg-gradient-to-br from-red-600 to-indigo-600 justify-center items-center relative overflow-hidden`}
          >
            <div
              className={`absolute left-0 right-0 top-0 bottom-0 bg-black transition-opacity ${
                slideIdx !== 0 ? "opacity-60" : "opacity-0"
              }`}
            />
            <img
              className="w-full md:w-2/3 p-6 object-contain"
              alt="Electricity Need Preview"
              src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2FElectricity%20need%20preview.png?alt=media&token=f88cf095-692a-49a9-b697-8b2094736364"
            />
            <div className="p-4 text-white text-3xl h-fit">
              <p className="font-bold">Electricty Needs</p>
              <p className="mt-3">
                Select your electrical to see your electricity needs
              </p>
            </div>
          </div>
        </div>
        <div className="p-2">
          <div
            className={`p-3 aspect-square md:flex md:aspect-video rounded-xl bg-gradient-to-br from-blue-600 to-indigo-600 justify-center items-center relative overflow-hidden`}
          >
            <div
              className={`absolute left-0 right-0 top-0 bottom-0 bg-black transition-opacity ${
                slideIdx !== 1 ? "opacity-60" : "opacity-0"
              }`}
            />
            <img
              className="w-full md:w-2/3 p-6 object-contain"
              alt="Electricity Need Preview"
              src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fconfiguration%20preview.png?alt=media&token=dd404179-74a3-4317-9a01-2a6755ac75b5"
            />
            <div className="p-4 text-white text-3xl h-fit">
              <p className="font-bold">Customize</p>
              <p className="mt-3">
                Configure a solution that suits your specific needs
              </p>
            </div>
          </div>
        </div>
        <div className="p-2">
          <div
            className={`p-3 aspect-square md:flex md:aspect-video rounded-xl bg-gradient-to-br from-yellow-600 to-red-600 justify-center items-center relative overflow-hidden`}
          >
            <div
              className={`absolute left-0 right-0 top-0 bottom-0 bg-black transition-opacity ${
                slideIdx !== 2 ? "opacity-60" : "opacity-0"
              }`}
            />
            <img
              className="w-full md:w-2/3 p-6 object-contain"
              alt="Electricity Need Preview"
              src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fsimulation%20preview.png?alt=media&token=24823f13-3bca-4172-b98c-5762cffb306c"
            />
            <div className="p-4 text-white text-3xl h-fit">
              <p className="font-bold">Simulate</p>
              <p className="mt-3">
                Simulate how well your system does using satellite data
              </p>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};
