import DataSet from "@antv/data-set";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import wNumb from "wnumb";
import { AppDispatch, RootState } from "../../../../store/config";
import { setConsumption } from "../../../../store/slices/cpq/commercial";
import { Input } from "../../../../components/Input/input.component";
import { content } from "../content";

export const ConsumptionInputSection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { locale } = useSelector((state: RootState) => state.localization);

  const electricityPrice = useSelector(
    (state: RootState) => state.location.data?.price.USD.commercial
  );

  const price = electricityPrice ? electricityPrice : 0;
  const consumption = useSelector(
    (state: RootState) => state.cpq.commercial.config.consumption
  );

  const data = [...consumption].map((val, index) => ({
    consumption: val,
    month: index + 1,
  }));

  const ds = new DataSet();
  const dv = ds.createView().source(data);
  const [openMonthly, setOpenMonthly] = useState(false);

  const handleAvgMonthlyChange = (v: any) => {
    const monthlyArr = Array(12).fill(Number(v));
    dispatch(setConsumption({ consumption: monthlyArr }));
  };

  const handleMonthChange = (monthIdx: number, value: number) => {
    const monthlyArr = [...consumption];
    monthlyArr[monthIdx] = value;
    dispatch(setConsumption({ consumption: monthlyArr }));
  };

  return (
    <>
      <div className="w-full aspect-video min-h-[246px] bg-gray-50 rounded-xl shadow-sm text-sm relative">
        <img
          className="absolute object-cover w-full h-full rounded-xl"
          alt=""
          src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fstock%2Fcredit%20card.jpg?alt=media&token=2571c995-acc7-415b-9002-baae9d205473"
        />
        <div className="p-4 absolute w-full h-full text-white bg-black/50 backdrop-blur-sm rounded-xl">
          <div className="flex flex-col justify-center w-full h-full">
            <div className="flex justify-between py-2 border-gray-200 border-b">
              <p>{content.consumption.labels.monthly[locale]}</p>
              <p>
                {`
            ${wNumb({ prefix: "$", decimals: 0, thousand: " " }).to(
              dv.average("consumption") * price
            )}`}
              </p>
            </div>
            <div className="flex justify-between py-2 border-gray-200 border-b">
              <p>{content.consumption.labels.annual[locale]}</p>
              <p>
                {`
            ${wNumb({ prefix: "$", decimals: 0, thousand: "," }).to(
              dv.sum("consumption") * price
            )}`}
              </p>
            </div>
            <div className="flex justify-between py-2">
              <p>{content.consumption.labels.price[locale]}</p>
              <p>
                {wNumb({ suffix: " $ / kWh", decimals: 3, thousand: "," }).to(
                  price
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Input
        label={content.consumption.labels.input[locale]}
        value={0}
        type="number"
        onChange={(v) => handleAvgMonthlyChange(Number(v))}
        className="mt-6"
      />
      <div className="mt-6 bg-gray-50 rounded-xl overflow-hidden">
        <div
          className="px-4 py-6 flex items-center gap-3 hover:bg-gray-100 cursor-pointer"
          onClick={() => setOpenMonthly(!openMonthly)}
        >
          <input
            type={"checkbox"}
            checked={openMonthly}
            onChange={() => null}
            className="w-6 md:w-4 aspect-square"
          />
          <div className="text-sm">
            <p className="font-semibold">
              {content.consumption.modal.labelBold[locale]}
            </p>
            <p>{content.consumption.modal.labelRegular[locale]}</p>
          </div>
        </div>
        {openMonthly && (
          <div className="px-4 pt-4 pb-6 border-t border-gray-200">
            <p>{content.consumption.modal.title[locale]}</p>
            <Input
              label={content.consumption.modal.inputLabels[0][locale]}
              value={consumption[0]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(0, Number(v))}
            />
            <Input
              label={content.consumption.modal.inputLabels[1][locale]}
              value={consumption[1]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(1, Number(v))}
            />
            <Input
              label={content.consumption.modal.inputLabels[2][locale]}
              value={consumption[2]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(2, Number(v))}
            />
            <Input
              label={content.consumption.modal.inputLabels[3][locale]}
              value={consumption[3]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(3, Number(v))}
            />
            <Input
              label={content.consumption.modal.inputLabels[4][locale]}
              value={consumption[4]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(4, Number(v))}
            />
            <Input
              label={content.consumption.modal.inputLabels[5][locale]}
              value={consumption[5]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(5, Number(v))}
            />
            <Input
              label={content.consumption.modal.inputLabels[6][locale]}
              value={consumption[6]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(6, Number(v))}
            />
            <Input
              label={content.consumption.modal.inputLabels[7][locale]}
              value={consumption[7]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(7, Number(v))}
            />
            <Input
              label={content.consumption.modal.inputLabels[8][locale]}
              value={consumption[8]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(8, Number(v))}
            />
            <Input
              label={content.consumption.modal.inputLabels[9][locale]}
              value={consumption[9]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(9, Number(v))}
            />
            <Input
              label={content.consumption.modal.inputLabels[10][locale]}
              value={consumption[10]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(10, Number(v))}
            />
            <Input
              label={content.consumption.modal.inputLabels[11][locale]}
              value={consumption[11]}
              type="number"
              className="mt-4 bg-white"
              onChange={(v) => handleMonthChange(11, Number(v))}
            />
          </div>
        )}
      </div>
    </>
  );
};
