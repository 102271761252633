import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import IraqFlag from "../../assets/flags/iraq-flag.png";
import KRGFlag from "../../assets/flags/krg-flag.png";
import USAFlag from "../../assets/flags/usa-flag.png";

import { AppDispatch, RootState } from "../../store/config";
import { ILocale, switchLocale } from "../../store/slices/localization";

export const LanguageMenu = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const { locale, direction } = useSelector(
    (state: RootState) => state.localization
  );

  const handleSelect = (newLocale: ILocale) => {
    dispatch(switchLocale(newLocale));
    const baseUrl = location.pathname.slice(4, location.pathname.length);
    if (baseUrl.length > 0) {
      navigate(`/${newLocale}/${baseUrl}`);
    } else {
      navigate(`/${newLocale}`);
    }
  };

  return (
    <Menu as="div" className="relative mx-4">
      <div>
        <Menu.Button className="py-1 flex gap-2 justify-center items-center rounded-md text-white">
          {locale === "en" && (
            <img
              alt="english"
              src={USAFlag}
              className="aspect-square w-8 object-cover rounded-full"
            />
          )}
          {locale === "ku" && (
            <img
              alt="kurdish"
              src={KRGFlag}
              className="aspect-square w-8 object-cover rounded-full"
            />
          )}
          {locale === "ar" && (
            <img
              alt="arabic"
              src={IraqFlag}
              className="aspect-square w-8 object-cover rounded-full"
            />
          )}

          <ChevronDownIcon
            className="h-5 w-5 text-violet-200 hover:text-violet-100"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{
            direction: "ltr",
            left: direction === "rtl" ? 0 : "auto",
            right: direction === "ltr" ? 0 : "auto",
          }}
          className="absolute mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-gray-100" : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => handleSelect("en")}
                >
                  <img
                    alt="english"
                    src={USAFlag}
                    className="mr-4 aspect-square w-8 object-cover rounded-full"
                  />
                  English
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-gray-100" : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm justify-between`}
                  onClick={() => handleSelect("ku")}
                >
                  <img
                    alt="kurdish"
                    src={KRGFlag}
                    className="mr-full aspect-square w-8 object-cover rounded-full"
                  />
                  كوردى
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-gray-100" : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm justify-between`}
                  onClick={() => handleSelect("ar")}
                >
                  <img
                    alt="arabic"
                    src={IraqFlag}
                    className="mr-full aspect-square w-8 object-cover rounded-full"
                  />
                  عربى
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
