export const content = {
  h1: {
    en: "Our Services",
    ar: "خدماتنا",
    ku: "خزمەت گوزاریەکانمان",
  },
  h2: {
    en: "Get the professional support you need in a reliable and timely manner",
    ar: "احصل على الدعم المهني الذي تحتاجه بطريقة موثوقة وفي الوقت المناسب",
    ku: "ئەو پشتگیرییە پیشەییانەی کە پێویستتە بە شێوەیەکی متمانەپێکراو و لە کاتی خۆیدا بەدەستبهێنە",
  },
  pointTitle1: {
    en: "Quality.",
    ar: "جودة.",
    ku: "کواڵیتی",
  },
  pointDecription1: {
    en: "All our services are quality controlled.",
    ar: "تخضع جميع خدماتنا للرقابة على الجودة.",
    ku: "هەموو خزمەتگوزارییەکانمان کوالیتی کۆنترۆڵ دەکرێن",
  },
  pointTitle2: {
    en: "Customer focus.",
    ar: "التركيز على العملاء.",
    ku: "بایەخی کڕیار",
  },
  pointDecription2: {
    en: "We carefuly listen to your needs in order to best meet them.",
    ar: "نحن نستمع بعناية لاحتياجاتك من أجل تلبية احتياجاتك على أفضل وجه.",
    ku: "ئێمە بە وردی گوێ لە داواکاريیە کانت دەگرین بۆ ئەوەی بە باشترین شێوە دابینیان بکەین",
  },
  pointTitle3: {
    en: "Responsiveness.",
    ar: "إستجابة.",
    ku: "وەڵامدانەوەی",
  },
  pointDecription3: {
    en: "Get the support you need in a timely manner.",
    ar: "احصل على الدعم الذي تحتاجه في الوقت المناسب.",
    ku: "ئەو پشتگیرییەی کە پێویستتە لە کاتی خۆیدا بەدەستبهێنە.",
  },
};
