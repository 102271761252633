import { Helmet } from "react-helmet-async";
import { ScrollRestoration } from "react-router-dom";
import { Footer } from "../../common/Footer";
import { Header } from "../../common/Header";
import { Social } from "../../common/Social";
import { SupportHero } from "./Hero";
import { ContactMethods } from "./Methods";

export const Support = () => {
  return (
    <>
      <Helmet>
        <title>Support</title>
        <meta
          name="description"
          content="Contact us through our live chat or email to get your questions about solar in Iraq answered."
        />
      </Helmet>
      <ScrollRestoration />
      <Header />
      <SupportHero />
      <ContactMethods />
      <Social />
      <Footer />
    </>
  );
};
