import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../store/config";
import { getResidentialConfigAction } from "../../../../store/slices/cpq/residential";

export const CPQResidentialLoader = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  let { lang, uuid } = useParams();
  const { pending, error } = useSelector(
    (state: RootState) => state.cpq.residential.getConfigApi
  );

  const loadedUUID = useSelector(
    (state: RootState) => state.cpq.residential.uuid
  );

  useEffect(() => {
    if (uuid) {
      dispatch(getResidentialConfigAction({ uuid }));
    }
  }, [lang, uuid]);

  useEffect(() => {
    if (pending === false && error === null && loadedUUID === uuid) {
      // Loaded without error
      navigate(`/${lang}/cpq/residential`);
    }
  }, [loadedUUID, pending, error]);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      {pending && "Loading"}
    </div>
  );
};
