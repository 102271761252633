import { Link } from "react-router-dom";

type Props = {
  href: string;
  children: string;
  className?: string;
};

export const Navlink = ({ href, children, className }: Props) => {
  return (
    <Link className={`font-medium ltr:tracking-wide ${className}`} to={href}>
      {children}
    </Link>
  );
};
