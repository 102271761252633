export const content = {
  h1: {
    en: "Home Solar",
    ar: "والطاقة المنزلية",
    ku: "وزەی ما لە وە",
  },
  h2: {
    en: "Residential",
    ar: "سكني",
    ku: "شوێنی نیشتەجێبوون",
  },
  headerPostfaceOr: {
    en: "or",
    ar: "أو",
    ku: "یان",
  },
  headerPostface: {
    en: "/mo. for 24mo.",
    ar: "/ شهر.  لمدة 24 شهرًا.",
    ku: " مانانە بۆ 24 مان",
  },
  location: {
    title: {
      en: "Location.",
      ar: "موقع.",
      ku: "شوێن.",
    },
    titleContinued: {
      en: " Where is your home?",
      ar: " اين هو منزلك؟",
      ku: " ماڵت لە کوێيە؟",
    },
    labels: {
      country: {
        en: "Country",
        ar: "دولة",
        ku: "وڵات",
      },
      city: {
        en: "City",
        ar: "مدينة",
        ku: "شار",
      },
      outletVoltage: {
        en: " Home Voltage",
        ar: " الجهد المنزلي",
        ku: " ڤۆڵتیەی ماڵەوە",
      },
    },
  },
  roofSlope: {
    title: {
      en: "Roof Type.",
      ar: "نوع السقف.",
      ku: "جۆری سەقف.",
    },
    titleContinued: {
      en: " What's the roof tilt?",
      ar: " ما هو سقف الميل؟",
      ku: " مەیلەکەی سەقف چییە؟",
    },
    labels: {
      flat: {
        en: "Flat",
        ar: "مسطحة",
        ku: "تەخت",
      },
      tilted: {
        en: "Tilted",
        ar: "مائل",
        ku: "لار",
      },
    },
    modal: {
      labelBold: {
        en: "Why does the roof type need to be known?",
        ar: "لماذا يجب معرفة نوع السقف؟",
        ku: "بۆچی پێویستە جۆری سەقفەکە بزانرێت؟",
      },
      labelRegular: {
        en: "Learn why we need to know your roof type.",
        ar: "تعرف على سبب حاجتنا إلى معرفة نوع السقف الخاص بك.",
        ku: "بزانە بۆچی پێویستە جۆری سەقفەکەت بزانین.",
      },
      title: { en: "Roof Type", ar: "نوع السقف", ku: "جۆری سەقف" },
      p: [
        {
          en: "Your roof type and whether it is tilted determines the mounting structure that we will need for you solar system.",
          ar: "يحدد نوع السقف الخاص بك وما إذا كان مائلًا هيكل التركيب الذي سنحتاجه لنظامك الشمسي.",
          ku: "جۆری سەقفەکەت و ئایا مەیل بووە یان نا، پێکهاتەی دانانی دیاری دەکات کە پێویستمان پێی دەبێت بۆ سیستەمی خۆر.",
        },
        {
          en: "A flat roof furthermore provides a greater flexibility in installing the solar panels in their optimal orientation - pointing toward true south. Flat roofs are therefore more efficient and cost effective than tilted roofs.",
          ar: "علاوة على ذلك ، يوفر السقف المسطح مزيدًا من المرونة في تركيب الألواح الشمسية في اتجاهها الأمثل - مشيرًا نحو الجنوب الحقيقي.  وبالتالي فإن الأسطح المسطحة أكثر كفاءة وفعالية من حيث التكلفة من الأسطح المائلة.",
          ku: "سەرەڕای ئەوەش سەقفێکی تەخت نەرمییەکی زیاتر لە دانانی پانێڵە خۆرەکان بە ئاراستەی گونجاویان دابین دەکات - کە ئاماژە بە باشووری ڕاستەقینە دەکات.  بۆیە سەقفی تەخت کاراتر و کاریگەرترە لە سەقفی مەیل.",
        },
      ],
    },
  },
  panels: {
    title: {
      en: "Solar Panels.",
      ar: "الألواح الشمسية. ",
      ku: "پانێڵی خۆر. ",
    },
    titleContinued: {
      en: " Aesthetics or efficiency?",
      ar: "جماليات أم كفاءة؟ ",
      ku: "جوانکاری یان کارایی؟ ",
    },

    modal: {
      labelBold: {
        en: "Which panel should you get?",
        ar: "أي لوحة يجب أن تحصل عليها؟",
        ku: "کام پانێڵ بەدەست بهێنیت؟",
      },
      labelRegular: {
        en: "View the differences between our panels.",
        ar: "عرض الاختلافات بين لوحاتنا.",
        ku: "جیاوازی نێوان پانێڵەکانمان ببینە.",
      },
      title: { en: "Solar Panels", ar: "الألواح الشمسية", ku: "پانێڵی خۆر" },
      p: [
        {
          en: "Our premium panels are the most high quality and cost efficient panels in the market. They last for a very long time, degrade very slowly over the decades to come, and generate a lot of electricity. It is the best option for you if you do not mind their looks.",
          ar: "لوحاتنا المتميزة هي الألواح الأكثر جودة وفعالية من حيث التكلفة في السوق.  إنها تدوم لفترة طويلة جدًا ، وتتحلل ببطء شديد على مدى العقود القادمة ، وتولد الكثير من الكهرباء.  إنه الخيار الأفضل لك.",
          ku: "پانێڵە  نایابە کانمان بە بەرزترین کوالێتی و کەمترین تئچوبەردەستە لە بازاڕدا.  بۆ ماوەیەکی زۆر درێژ دەمێننەوە، لە دەیەکانی داهاتوودا زۆر بە هێواشی تێکدەچن و کارەبایەکی زۆر بەرهەم دەهێنن.  باشترین بژاردەیە بۆ تۆ .",
        },
        {
          en: "If you care about how the solar panels affect the overall aesthetics of your house, we also offer the Fancy Black panels. These panels are coated in black paint and have an elegant and more discrete look. The quality is comparable to the Premium panels but it is considerably more expensive option.",
          ar: "إذا كنت مهتمًا بكيفية تأثير الألواح الشمسية على جماليات منزلك ، فنحن نقدم أيضًا لوحات BeautyBlack.  هذه الألواح مطلية بطلاء أسود ولها مظهر أكثر أناقة وتميزًا.  الجودة قابلة للمقارنة مع الألواح المتميزة ولكنها خيار أكثر تكلفة بكثير.",
          ku: "ئەگەر گرنگی بەوە دەدەیت کە چۆن پانێڵە خۆرەکان کاریگەرییان لەسەر جوانکاری گشتی ماڵەکەت هەیە، ئێمە پانێڵە جوانیڕەش پێشکەش دەکەین.  ئەم پانێڵانە بە بۆیەی ڕەش ڕوپۆش کراون و دیمەنێکی ڕەسەن و جیاوازتریان هەیە.  کوالیتییەکەی بەراورد دەکرێت بە پانێڵەکانی پریمیۆم بەڵام بژاردەیەکی زۆر گرانترە.",
        },
      ],
    },
  },
  daySize: {
    title: {
      en: "Daytime.",
      ar: "النهار. ",
      ku: "بە رۆژ. ",
    },
    titleContinued: {
      en: " How much electricity do you want?",
      ar: "كم تريد من الكهرباء؟ ",
      ku: "چەند کارەبات دەوێت؟ ",
    },
    labels: {
      chart: {
        en: "Solar Electricity over Full Day",
        ar: "كهرباء شمسية ليوم كامل",
        ku: "کارەبای وزەی خۆر لە ماوەی رۆژێک",
      },
      ampere: {
        en: "Amperes",
        ar: "أمبير",
        ku: "ئەمپێر",
      },
      ampereSuffix: {
        en: " A",
        ar: " أمبير",
        ku: " امپێر",
      },
      hours: {
        en: "Hours",
        ar: "ساعات",
        ku: "کاتژمێر",
      },
      hoursDaily: {
        en: "Hours Daily",
        ar: "ساعات يوميا",
        ku: "کاتژمێرەکان ڕۆژانە",
      },
    },
    modal: {
      labelBold: {
        en: "How much do you need?",
        ar: "كم تريد؟",
        ku: "چەندت پێویستە؟",
      },
      labelRegular: {
        en: "Learn more about how we size your system.",
        ar: "تعرف على المزيد حول كيفية تحديد حجم نظامك",
        ku: "زیاتر بزانە دەربارەی چۆن قەبارەی سیستەمەکەت دەدەین.",
      },
      title: { en: "System Size", ar: "حجم النظام", ku: "قەبارەی سیستەم" },
      p: [
        {
          en: "How big system you need is highly dependent on your electricity consumption. You just need to enter the number of Ampere you want for how many hours per day. Based on this input we ensure that your system produce the amount of electricity that is equivalent to what you have selected.",
          ar: "يعتمد حجم النظام الذي تحتاجه بشكل كبير على استهلاكك للكهرباء.  تحتاج فقط إلى إدخال عدد الأمبير الذي تريده لعدد الساعات في اليوم.  بناءً على هذا الإدخال ، نضمن أن ينتج نظامك كمية الكهرباء التي تعادل ما حددته.",
          ku: "چەند سیستەمێکی گەورەت پێویستە زۆر بەندە بە بەکارهێنانی کارەباکەتەوە.  تەنها پێویستە ژمارەی ئەمپێرە بنووسیت کە دەتەوێت بۆ چەند کاتژمێر لە ڕۆژێکدا.  بە پشتبەستن بەم زانیارییە ئێمە دڵنیا دەبینەوە لەوەی کە سیستەمەکەت ئەو بڕە کارەبایە بەرهەم دەهێنێت کە هاوتایە لەگەڵ ئەوەی هەڵتبژاردووە.",
        },
        {
          en: "It is important to note that your system will produce a total amount of electricity equivalent to your selection. In order to have flexibility over when and how much electricity you want to consume you will need an energy storage system (ESS) which you can add later.",
          ar: "من المهم ملاحظة أن نظامك سينتج كمية إجمالية من الكهرباء تعادل اختيارك.  من أجل التمتع بالمرونة بشأن وقت وكمية الكهرباء التي تريد استهلاكها ، ستحتاج إلى نظام تخزين الطاقة (ESS) الذي يمكنك إضافته لاحقًا.",
          ku: "گرنگە ئاماژە بەوە بکەیت کە سیستەمەکەت کۆی گشتی بڕی کارەبا بەرهەم دەهێنێت کە هاوتایە لەگەڵ هەڵبژاردنەکەت.  بۆ ئەوەی نەرمی و نەرمی هەبێت لەسەر ئەوەی کەی و چەند کارەبا دەتەوێت بەکاربهێنیت پێویستت بە سیستەمی هەڵگرتنی وزە (ESS) دەبێت کە دواتر دەتوانیت زیاد بکەیت.",
        },
      ],
    },
  },
  nightSize: {
    title: {
      en: "Nighttime.",
      ar: "وقت الليل.",
      ku: "بە شەو.",
    },
    titleContinued: {
      en: " How much electricity do you want?",
      ar: " كم تريد من الكهرباء؟",
      ku: "چەنێک کارەبات ەوێت؟",
    },
    labels: {
      chart: {
        en: "Solar Electricity over Full Day",
        ar: "الكهرباء الشمسية على مدار يوم كامل",
        ku: "کارەبا لە ماوای ٢٤ کاتژمێر",
      },
      amperes: {
        en: "Amperes",
        ar: "أمبير",
        ku: "ئەمپێری",
      },
      hours: {
        en: "Hours",
        ar: "ساعات",
        ku: "کاتژمێر",
      },
      ampereSuffix: {
        en: " A",
        ar: " أمبير",
        ku: " امپێر",
      },
      hoursSuffix: {
        en: " Hrs",
        ar: " ساعات",
        ku: " سەعات",
      },
    },
    modal: {
      labelBold: {
        en: "Why should you store energy?",
        ar: "لماذا يجب تخزين الطاقة؟",
        ku: "بۆچی پێویستە وزە هەڵبگریت؟",
      },
      labelRegular: {
        en: "Learn why you need energy storage and how much of it.",
        ar: "اكتشف لماذا تحتاج إلى تخزين الطاقة ومقدارها",
        ku: "بزانە بۆچی پێویستت بە هەڵگرتنی وزە يەو و  وەچەندئک",
      },
      title: {
        en: "Energy Storage System (ESS)",
        ar: "نظام تخزين الطاقة (ESS)",
        ku: "سیستەمی هەڵگرتنی وزە (ESS)",
      },
      p: [
        {
          en: "Your solar system produce electricity when the sun light hits the panels. With an energy storage system (ESS) you can store the electricity and use the generated electricity on demand instead.",
          ar: "ينتج نظامك الشمسي الكهرباء عندما يضرب ضوء الشمس الألواح.  باستخدام نظام تخزين الطاقة (ESS) ، يمكنك تخزين الكهرباء واستخدام الكهرباء المولدة عند الطلب بدلاً من ذلك.",
          ku: "کۆمەڵەی وزەی خۆرەکەت کارەبا بەرهەم دەهێنێت کاتێک تیشکی خۆر بەر پانێڵەکان دەکەوێت.  بە سیستەمی هەڵگرتنی وزە (ESS) دەتوانیت کارەبا هەڵبگریت و لەبری ئەوە کارەبای بەرهەم هێنراو لەسەر داواکاری بەکاربهێنیت.",
        },
        {
          en: "ESS provides you with flexibility of when to use your generated electricity. 0% flexibility means that you store none of the generated electricity and have to either use it directly when it's produced or let it go to waste. 100% flexibility means that you can store all of the electricity that is generated during a day and use it whenever you want. Once you store electricity it will be stored until you consume it.",
          ar: "يوفر لك ESS مرونة في وقت استخدام الكهرباء المولدة.  تعني المرونة 0٪ أنك لا تخزن أيًا من الكهرباء المولدة وعليك إما استخدامها مباشرة عند إنتاجها أو تركها تذهب سدى.  تعني المرونة بنسبة 100٪ أنه يمكنك تخزين كل الكهرباء التي يتم توليدها خلال اليوم واستخدامها وقتما تشاء.  بمجرد تخزين الكهرباء ، سيتم تخزينها حتى تستهلكها.",
          ku: "ESS نەرمیتان بۆ دابین دەکات کە کەی کارەبای بەرهەم هێنراوتان بەکاربهێنن.  0% نەرمی واتە هیچ کام لە کارەبای بەرهەم هێنراو هەڵناگریت و دەبێت یان ڕاستەوخۆ بەکاری بهێنیت کاتێک بەرهەم دێت یان با بەفیڕۆ بچێت.  100% نەرمی واتە دەتوانیت هەموو ئەو کارەبایەی کە لە ماوەی ڕۆژێکدا دروست دەبێت هەڵبگریت و هەرکاتێک بتەوێت بەکاری بهێنیت.  کاتێک کارەبا هەڵدەگریت تاوەکو بەکاری دەهێنیت.",
        },
        {
          en: "Storing electricity is important since our consumption of electricity is rarely perfectly aligned with the strength of the sun. If you have an energy storage system you can store excess electricity during the hours when the sun is the strongest instead of letting it go to waste. The stored electricity can then be used during night hours, in the evening, or or perhaps the next day.",
          ar: "يعد تخزين الكهرباء أمرًا مهمًا نظرًا لأن استهلاكنا للكهرباء نادرًا ما يتماشى تمامًا مع قوة الشمس.  إذا كان لديك نظام لتخزين الطاقة ، فيمكنك تخزين الكهرباء الزائدة خلال الساعات التي تكون فيها الشمس أقوى بدلاً من تركها تذهب سدى.  يمكن بعد ذلك استخدام الكهرباء المخزنة خلال ساعات الليل أو في المساء أو ربما في اليوم التالي.",
          ku: "هەڵگرتنی کارەبا گرنگە بەو پێیەی بەکارهێنانی کارەبامان بە دەگمەن بە تەواوی لەگەڵ هێزی خۆردا یەکدەگرێتەوە.  ئەگەر سیستەمی هەڵگرتنی وزەت هەیە دەتوانیت کارەبای زیادە لەو کاتژمێرانەدا هەڵبگریت کە خۆر بەهێزترینە لەبری ئەوەی ڕێگەی پێبدەیت بەفیڕۆ بدات.  پاشان دەتوانرێت کارەبای هەڵگیراو لە کاتژمێرەکانی شەودا، لە ئێواراندا، یان ڕەنگە ڕۆژی دواتردا بەکاربهێنرێت.",
        },
        {
          en: "Energy storage is the most expensive part of a home solar system, however. Depending on how much electricity you use during the hours when the sun is up compared to when the sun is down you may go for a lower flexibility which reduces the overall system cost for you.",
          ar: "ومع ذلك ، فإن تخزين الطاقة هو أغلى جزء من نظام الطاقة الشمسية المنزلية.  اعتمادًا على استخدامه عند غروب الشمس مقارنةً بوقت غروب الشمس ، قد تحصل على قدر أقل من المرونة مما يقلل التكلفة الإجمالية للنظام بالنسبة لك.",
          ku: "هەڵگرتنی وزە گرانترین بەشە لە سیستەمی وزەی خۆر بۆ ماڵ، بەڵام.  کەوتۆتەوەسەربەکارهئنانی لەو کاتانەی کە خۆر بە دەرەوەيە بەراورد بەو کاتانەی خۆر ئاوا دەبێت لەوانەیە بچیتە سەر نەرمییەکی کەمتر کە تێچووی گشتی سیستەمەکە بۆ تۆ کەمدەکاتەوە.",
        },
        {
          en: "The more similar your electricity consumption is to the sun's strength - the less flexibility you need and the lower cost your system will have. You can explore how much electricity you will have for different flexibility levels in the simulated chart. The chart shows how much electricity you can use directly from the sun and how much you can store and use at other hours of the day.",
          ar: "كلما كان استهلاكك للكهرباء أكثر تشابهًا مع قوة الشمس - كلما قلت المرونة التي تحتاجها وقلت التكلفة التي سيتحملها نظامك.  يمكنك استكشاف كمية الكهرباء التي ستحصل عليها لمستويات المرونة المختلفة في الرسم البياني المحاكى.  يوضح الرسم البياني مقدار الكهرباء التي يمكنك استخدامها مباشرة من الشمس وكم يمكنك تخزينها واستخدامها في ساعات أخرى من اليوم.",
          ku: "تا بەکارهێنانی کارەباکەت زیاتر هاوشێوەی هێزی خۆر بێت - کەمتر پێویستت بە نەرمی و تێچووی سیستەمەکەت کەمتر دەبێت.  دەتوانیت لە چارتی ھاوشێوەکراودا بزانیت کە چەند کارەبات دەوئت  بۆ ئاستی نەرمی جیاواز.  لە هێڵکارییەکەدا دەرکەوتووە کە دەتوانیت چەند کارەبایەک ڕاستەوخۆ لە خۆرەوە بەکاریبهێنیت و چەند دەتوانیت لە کاتژمێرەکانی تری ڕۆژدا هەڵیبگریت و بەکاریبهێنیت.",
        },
      ],
    },
  },
  summary: {
    title: {
      en: "Summary.",
      ar: "ملخص.",
      ku: "پوختە.",
    },
    titleContinued: {
      en: " Your custom solar system.",
      ar: "نظامك الشمسي المخصص",
      ku: "کۆمەڵەی وزەی خۆری تایبەت بە خۆت",
    },
    system: {
      panels: {
        en: "Solar Panels",
        ar: "الألواح الشمسية",
        ku: "پانێڵی خۆر",
      },
      quantity: {
        en: "Quantity",
        ar: "كمية",
        ku: "بڕ",
      },
      ess: {
        en: "Energy Storage",
        ar: "تخزين الطاقة",
        ku: "هەڵگرتنی وزە",
      },
      battery: {
        en: "Battery size",
        ar: "حجم البطارية",
        ku: "قەبارەی پاتری",
      },
      inverter: {
        en: "Inverter",
        ar: "العاكس",
        ku: "ئینڤێرتەر",
      },
      installation: {
        en: "Installation",
        ar: "التركيب",
        ku: "دامەزراندن",
      },
      free: { en: "(free)", ar: "(مجانا)", ku: "(بئبەرامبەر)" },
      total: {
        en: "Total",
        ar: "مجموع",
        ku: "گشتی",
      },
    },
    selection: {
      title: {
        en: "Selection",
        ar: "اختيار",
        ku: "هەڵبژاردن",
      },
      panel: {
        en: "Panel",
        ar: "لوحة",
        ku: "پانێڵ",
      },
      daytime: {
        en: "Daytime",
        ar: "النهار",
        ku: "بە رۆژ",
      },
      nighttime: {
        en: "Nighttime",
        ar: "وقت الليل",
        ku: "بە شەو",
      },
      ampere: {
        en: "Ampere",
        ar: "أمبير",
        ku: "ئەمپێری",
      },
      hours: {
        en: "Hours",
        ar: "ساعات",
        ku: "کاتژمێر",
      },
    },
  },
  details: {
    title: {
      en: "Details.",
      ar: "تفاصيل.",
      ku: "ووردەکاریەکان.",
    },
    titleContinued: {
      en: " Enter your details.",
      ar: "أدخل التفاصيل الخاصة بك",
      ku: "وردەکارییەکانتان بنووسن.",
    },
    labels: [
      {
        en: "Full name",
        ar: "الاسم بالكامل",
        ku: "ناوی تەواو",
      },
      {
        en: "Phone number",
        ar: "رقم الهاتف",
        ku: "ژمارەی تەلەفۆن",
      },
      {
        en: "Address",
        ar: "تبوك",
        ku: "ناونیشان",
      },
      {
        en: "Email",
        ar: "بريد إلكتروني",
        ku: "بريد إلكتروني",
      },
    ],
  },
  contact: {
    title: {
      en: "Contact.",
      ar: "اتصال.",
      ku: "پەیوەندی.",
    },
    titleContinued: {
      en: " How do you want us to contact you?",
      ar: "كيف تريد ان نتصل بك او نتواصل معك؟",
      ku: "چۆن دەتەوێت پەیوەندیت پێوە بکەین؟",
    },
  },
  finish: {
    title: {
      en: "Your Home Solar System.",
      ar: "نظام الطاقة الشمسية في منزلك.",
      ku: "کۆمەڵەی وزەی خۆری ماڵەکەت.",
    },
    titleContinued: {
      en: " Customized to your needs.",
      ar: " مخصصة لاحتياجاتك",
      ku: " بەپێی پێداویستییەکانتان دروستکراوە.",
    },
    secondaryTitle: {
      en: "Home Solar",
      ar: "الصفحة الرئيسية للطاقة الشمسية",
      ku: "وزەی ماڵەوە",
    },
    button: {
      en: "Place Order",
      ar: "مكان الامر",
      ku: "داواکاری دابنێ",
    },
    bookmarkHighlight: {
      en: "Save configuration.",
      ar: "حفظ التكوين",
      ku: "ڕێکخستنەکان پاشەکەوت بکە",
    },
    bookmarkRegular: {
      en: " Get a link and come back later.",
      ar: "احصل على رابط وارجع لاحقًا.",
      ku: "لینکێک وەربگرە و دواتر وەرەوە.",
    },
  },
};
