export const content = {
  h1: {
    en: "Electrical",
    ar: "الكهرباء",
    ku: "کارەبایی",
  },
  h2: {
    en: "Safe and Secure",
    ar: "آمن ومضمون",
    ku: "سەلامەت و پارێزراو",
  },
  p: {
    en: "Whether you need a minor electrical issue resolved or more extensiverepairs, our electricians are able to serve your needs. Getprofessional help with adequate preparation and care, to avoidlife-threatening injury or substantial property damage. Make thesafe and smart choice and leave electrical work to ourprofessionals.",
    ar: "سواء كنت بحاجة إلى حل مشكلة كهربائية بسيطة أو المزيد من الإصلاحات الإضافية ، فإن كهربائيين لدينا قادرون على تلبية احتياجاتك. احصل على مساعدة احترافية في الإعداد والرعاية المناسبة ، لتجنب الإصابة التي تهدد الحياة أو الأضرار الجسيمة في الممتلكات. اتخذ خيارًا آمنًا وذكيًا واترك الأعمال الكهربائية لمهنيينا.",
    ku: "جا پێویستت بە چارەسەرکردنی کێشەیەکی بچووکی کارەبا بێت یان چاککردنەوەی بەرفراوانتر، کارەباچیەکانمان توانای خزمەتکردنی پێداویستییەکانتان هەیە.  یارمەتی پیشەیی وەربگرە لەگەڵ ئامادەکاری و چاودێرییەکی گونجاو، بۆ ئەوەی لە برینداربوونی مەترسیدار بۆ سەر ژیان یان زیانگەیاندن بە موڵک و ماڵی زۆر دوور بکەویتەوە.  هەڵبژاردنی سەلامەت و زیرەک بکە و کاری کارەبایی بۆ پیشەگەرەکانمان بەجێبهێڵە.",
  },
};
