import { useEffect, useRef } from "react";
import {
  IoChatbubblesOutline,
  IoTicketOutline,
  IoTimerOutline,
} from "react-icons/io5";
import useVisibility from "../../hooks/useVisibility";
import { Tawk } from "../../common/Tawk";
import { CouponModal } from "./coupon.modal";
import { content } from "./content";
import { useSelector } from "react-redux";
import { RootState } from "../../store/config";

type Props = {
  preface: string;
  postface: string;
  children?: JSX.Element | string | null;
};

export const DynamicHeader: React.FC<Props> = ({
  preface,
  postface,
  children,
}) => {
  const [isVisible, ref] = useVisibility<HTMLDivElement>(-132, 15);
  const chatRef = useRef<any>(null);

  const { locale } = useSelector((state: RootState) => state.localization);

  const openChat = () => {
    chatRef?.current?.showWidget();
    chatRef?.current?.maximize();
  };

  const onChatStarted = (chat: any) => {
    chat.showWidget();
  };

  const onChatEnded = (chat: any) => {
    chat.hideWidget();
  };

  const tawkLoaded = (chat: any) => {
    chat.hideWidget();
  };

  useEffect(() => {
    if (chatRef.current && !chatRef.current.isChatHidden) {
      chatRef.current.hideWidget();
    }
  }, [chatRef]);

  return (
    <>
      <Tawk
        ref={chatRef}
        onChatStarted={onChatStarted}
        onChatEnded={onChatEnded}
        onLoad={tawkLoaded}
      />
      <div
        ref={ref}
        className="px-6 md:px-0 mx-auto md:mx-0 max-w-sm md:max-w-none"
      >
        <h2 className="text-lg text-red-700">{preface}</h2>
        <h1 className="text-5xl leading-tight font-semibold">{children}</h1>
        <p className="mt-6 text-sm font-numbers">{postface}</p>
      </div>
      <div
        className={`h-[50px] fixed left-0 w-full z-10 ${
          isVisible ? "-top-[100%]" : "top-0"
        } transition-all duration-500`}
      >
        <div
          className={`md:h-[50px] w-full py-2.5 bg-white border-b border-gray-200`}
        >
          <div className="md:flex justify-between items-center md:max-w-[87.5%] mx-4 md:mx-auto">
            <p className="text-lg md:text-xl font-semibold">{children}</p>
            <p className="text-sm md:text-lg md:font-semibold font-numbers">
              {postface}
            </p>
          </div>
        </div>
        <div
          className={`h-[50px] w-full py-[12.5px] bg-white border-b border-gray-200`}
        >
          <div className="flex md:justify-end items-stretch md:max-w-[87.5%] px-4 md:px-0 md:mx-auto text-sm scrollbar-hide overflow-x-auto">
            <div className="flex items-center">
              <IoChatbubblesOutline size={24} />
              <p
                className="ltr:ml-1.5 rtl:mr-1.5 ltr:mr-5 rtl:ml-5 hover:underline cursor-pointer whitespace-nowrap"
                onClick={openChat}
              >
                {content.chat[locale]}
              </p>
            </div>
            <div className="flex items-center">
              <IoTicketOutline size={24} />
              <CouponModal />
            </div>
            <div className="h-6 mx-5 border-l border-gray-300" />
            <div className="flex items-center">
              <IoTimerOutline size={24} />
              <p className="ltr:ml-1.5 rtl:mr-1.5 whitespace-nowrap">
                <b className="font-semibold">{content.deliveryTime[locale]}</b>
                {content.deliveryText[locale]}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
