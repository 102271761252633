import { combineReducers } from "redux";
import { couponSlice } from "./coupon";
import { cpq } from "./cpq";
import { localizationSlice } from "./localization";
import { locationSlice } from "./location";
import { meta } from "./meta";
import { meteorology } from "./meteorology";

const reducers = combineReducers({
  coupon: couponSlice.reducer,
  cpq,
  localization: localizationSlice.reducer,
  meteorology,
  meta,
  location: locationSlice.reducer,
});

export default reducers;
