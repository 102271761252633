import { combineReducers } from "redux";
import { catalogueSlice } from "./catalogue";
import { offeringSlice } from "./offering";
import { supportedLocationsSlice } from "./supported-locations";

export const meta = combineReducers({
  catalogue: catalogueSlice.reducer,
  offering: offeringSlice.reducer,
  supportedLocations: supportedLocationsSlice.reducer,
});
