import { asind } from "../math/asind";
import { cosd } from "../math/cosd";
import { sind } from "../math/sind";

export const getElevation = (
  latitude: number,
  HRA: number,
  declination: number
) => {
  const elevation = asind(
    sind(declination) * sind(latitude) +
      cosd(declination) * cosd(latitude) * cosd(HRA)
  );

  return elevation;
};
