import DataSet from "@antv/data-set";
import { Chart, Axis, LineAdvance, Tooltip, Annotation } from "bizcharts";
import * as nouislider from "nouislider";
import { useDispatch, useSelector } from "react-redux";
import colors from "tailwindcss/colors";
import wNumb from "wnumb";
import { AppDispatch, RootState } from "../../../../store/config";
import {
  setNightSizeAmperes,
  setNightSizeHours,
} from "../../../../store/slices/cpq/residential";
import { InfoModal } from "../../../../components/InfoModal";
import { Slider } from "../../../../components/Slider";
import { content } from "../content";

export const NightSizeInputSection = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { locale, direction } = useSelector(
    (state: RootState) => state.localization
  );

  const { amperes, hours } = useSelector(
    (state: RootState) => state.cpq.residential.config.nightSize
  );
  const systemSizeFormatted = new Intl.NumberFormat("en", {
    style: "decimal",
    minimumFractionDigits: 1,
  }).format(amperes);

  const simulationAvgHour = useSelector(
    (state: RootState) => state.cpq.residential.simulationAvgHour
  );

  const handleAmperesChange = (values: (string | number)[]) => {
    const updatedVal = Math.round(Number(values[0]));

    dispatch(setNightSizeAmperes({ amperes: updatedVal }));
  };

  const handleHoursChange = (values: (string | number)[]) => {
    const updatedVal = Math.round(Number(values[0]));

    dispatch(setNightSizeHours({ hours: updatedVal }));
  };

  const ds = new DataSet();
  const dv = ds.createView();
  dv.source(simulationAvgHour);

  let max =
    simulationAvgHour.length > 0 && Number(dv.max("total"))
      ? Math.max(Number(dv.max("total")), amperes * 1.1)
      : 10;

  const formatter_A = wNumb({ decimals: 1, suffix: " A", thousand: "," });
  const scaleCfg = {
    inflexible: {
      alias: "Inflexible (direct usage)",
      min: 0,
      max: max * 1.05,
      formatter: (v: number) =>
        typeof v === "number" ? formatter_A.to(v) : null,
    },
    flexible: {
      alias: "Flexible (battery stored)",
      min: 0,
      max: max * 1.05,
      formatter: (v: number) =>
        typeof v === "number" ? formatter_A.to(v) : null,
    },
    total: {
      alias: "Total Electricity",
      min: 0,
      max: max * 1.05,
      formatter: (v: number) =>
        typeof v === "number" ? formatter_A.to(v) : null,
    },
    potentialUsage: {
      alias: "Battery (can be used)",
      min: 0,
      max: max * 1.05,
      formatter: (v: number) =>
        typeof v === "number" ? formatter_A.to(v) : null,
    },
    hour: {
      type: "linear",
      alias: "Hour",
      min: 0,
      max: 23,
      tickCount: 12,
      formatter: (v: number) =>
        v === 12 ? `12pm` : v < 12 ? `${v}am` : `${v - 12}pm`,
    },
    date: {
      alias: "Date",
    },
  };

  const grid = {
    line: {
      type: "line",
      style: {
        stroke: colors.gray[300],
        lineWidth: 0.25,
      },
    },
  };

  const line = {
    style: {
      stroke: colors.gray[700],
      lineWidth: 0.25,
    },
  };

  return (
    <>
      <div className="p-4 w-full aspect-video min-h-[246px] bg-gray-50 rounded-xl shadow-sm">
        <div className="w-full h-full md:pb-5" style={{ direction: "ltr" }}>
          <p
            className="text-xs text-gray-900 pb-1"
            style={{ direction: direction }}
          >
            {content.nightSize.labels.chart[locale]}
          </p>
          <Chart autoFit scale={scaleCfg} data={dv} pure>
            <Axis
              name="date"
              grid={grid}
              line={line}
              visible={true}
              tickLine={{ length: 0 }}
            />
            <Axis name="inflexible" visible={false} />
            <Axis name="flexible" visible={false} />
            <Axis name="total" visible={false} />
            <Axis name="potentialUsage" visible={false} />
            <LineAdvance
              area
              position={`hour*potentialUsage`}
              size={0}
              tooltip={false}
              style={{ fillOpacity: 1 }}
              color={`l(270) 0:${colors.gray[200]} 1:${colors.gray[200]}`}
            />
            <LineAdvance
              shape="smooth"
              area
              position={`hour*total`}
              style={{ fillOpacity: 0.5 }}
              color={`l(270) 0:${colors.blue[300]} 1:${colors.blue[300]}`}
              size={0}
            />
            <LineAdvance
              shape="smooth"
              area
              position={`hour*inflexible`}
              style={{ fillOpacity: 1 }}
              color={`l(270) 0:${colors.yellow[200]} 1:${colors.yellow[200]}`}
              size={0}
            />
            <LineAdvance
              shape="line"
              position={`hour*potentialUsage`}
              size={3}
              tooltip={false}
              style={{ fillOpacity: 1 }}
              color={colors.blue[300]}
            />
            <LineAdvance
              shape="line"
              position={`hour*flexible`}
              size={3}
              style={{ fillOpacity: 1 }}
              color={colors.blue[500]}
            />
            <Annotation.Line
              start={["min", amperes]}
              end={["max", amperes]}
              text={{ content: `${systemSizeFormatted} Ampere`, offsetY: -6 }}
              style={{
                lineWidth: 0,
              }}
            />
            <Tooltip shared />
          </Chart>
        </div>
      </div>
      <div className="mt-6 px-4 py-6 bg-gray-50 rounded-xl overflow-hidden">
        {direction === "ltr" && (
          <Slider
            label={content.nightSize.labels.amperes[locale]}
            value={amperes}
            className="mt-4"
            onSet={handleAmperesChange}
            pipsMode={nouislider.PipsMode.Steps}
            options={{
              connect: "lower",
              direction: "ltr",
              start: amperes,
              range: {
                min: [0],
                "25%": [4],
                "50%": [8],
                "75%": [20],
                max: [40],
              },
            }}
            suffix={content.nightSize.labels.ampereSuffix[locale]}
            decimals={0}
          />
        )}
        {direction === "rtl" && (
          <Slider
            label={content.nightSize.labels.amperes[locale]}
            value={amperes}
            className="mt-4"
            onSet={handleAmperesChange}
            pipsMode={nouislider.PipsMode.Steps}
            options={{
              connect: "lower",
              direction: "rtl",
              start: amperes,
              range: {
                min: [0],
                "25%": [4],
                "50%": [8],
                "75%": [20],
                max: [40],
              },
            }}
            suffix={content.nightSize.labels.ampereSuffix[locale]}
            decimals={0}
          />
        )}
      </div>
      <div className="mt-6 px-4 py-6 bg-gray-50 rounded-xl overflow-hidden">
        {direction === "ltr" && (
          <Slider
            label={content.nightSize.labels.hours[locale]}
            value={hours}
            className="mt-4"
            onSet={handleHoursChange}
            pipsMode={nouislider.PipsMode.Steps}
            options={{
              connect: "lower",
              direction: "ltr",
              start: hours,
              range: {
                min: [0],
                "25%": [6],
                "50%": [12],
                "75%": [18],
                max: [24],
              },
            }}
            suffix={content.nightSize.labels.hoursSuffix[locale]}
            decimals={0}
          />
        )}
        {direction === "rtl" && (
          <Slider
            label={content.nightSize.labels.hours[locale]}
            value={hours}
            className="mt-4"
            onSet={handleHoursChange}
            pipsMode={nouislider.PipsMode.Steps}
            options={{
              connect: "lower",
              direction: "rtl",
              start: hours,
              range: {
                min: [0],
                "25%": [6],
                "50%": [12],
                "75%": [18],
                max: [24],
              },
            }}
            suffix={content.nightSize.labels.hoursSuffix[locale]}
            decimals={0}
          />
        )}
      </div>
      <InfoModal
        linkText={content.nightSize.modal.labelBold[locale]}
        description={content.nightSize.modal.labelRegular[locale]}
        title={content.nightSize.modal.title[locale]}
        boxClassName="mt-5"
        img="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fstock%2Fnight-sky.jpg?alt=media&token=c08e4c84-c94d-4c31-b80e-6c8e56030e3d"
        type="box"
      >
        <div className="p-6 md:px-20 md:pb-20">
          <p>{content.nightSize.modal.p[0][locale]}</p>
          <p className="mt-3">{content.nightSize.modal.p[1][locale]}</p>
          <p className="mt-3">{content.nightSize.modal.p[2][locale]}</p>
          <p className="mt-3">{content.nightSize.modal.p[3][locale]}</p>
          <p className="mt-3">{content.nightSize.modal.p[4][locale]}</p>
        </div>
      </InfoModal>
    </>
  );
};
