import { Helmet } from "react-helmet-async";
import { ScrollRestoration } from "react-router-dom";
import { Footer } from "../../common/Footer";
import { Header } from "../../common/Header";
import { Consulting } from "./Consulting";
import { DataAPI } from "./DataAPI";
import { Electrical } from "./Electrical";
import { ServicesHero } from "./Hero";
import { ServicesPointers } from "./Pointers";

export const Services = () => {
  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta
          name="description"
          content="Professional solar consulting and electrical services."
        />
      </Helmet>
      <ScrollRestoration />
      <Header autoChange />
      <ServicesHero />
      <ServicesPointers />
      <Consulting />
      <Electrical />
      <DataAPI />
      <Footer />
    </>
  );
};
