import reducers from "./slices";
import { configureStore } from "@reduxjs/toolkit";
import { locationFetchedListener } from "./middleware/location.fetched.listener";
import { residentialAvgHourlySimulationTrigger } from "./middleware/residential.avg-hourly-simulation.trigger";
import { locationChangeListener } from "./middleware/location.change.listener";
import { residentialSimulationTrigger } from "./middleware/residential.simulation.trigger";
import { offeringFetchedListener } from "./middleware/offering.fetched.listener";
import { residentialSystemTrigger } from "./middleware/residential.system.trigger";
import { residentialPriceTrigger } from "./middleware/residential.prices.trigger";
import { commercialRoofMaxPanelListener } from "./middleware/commercial.roof-max-panel.trigger";
import { commercialPriceTrigger } from "./middleware/commercial.prices.trigger";
import { commercialSimulationTrigger } from "./middleware/commercial.simulation.trigger";
import { commercialSystemTrigger } from "./middleware/commercial.system.trigger";
import { residentialBookmarkListener } from "./middleware/residential.bookmark.listener";
import { commercialBookmarkListener } from "./middleware/commercial.bookmark.listener";
import { residentialConfigLoadListener } from "./middleware/residential.config.load.listener";
import { commercialConfigLoadListener } from "./middleware/commercial.config.load.listener";

const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: true,
      serializableCheck: true,
    })
      .prepend(locationChangeListener.middleware)
      .prepend(locationFetchedListener.middleware)
      .prepend(residentialAvgHourlySimulationTrigger.middleware)
      .prepend(residentialSimulationTrigger.middleware)
      .prepend(offeringFetchedListener.middleware)
      .prepend(residentialSystemTrigger.middleware)
      .prepend(residentialPriceTrigger.middleware)
      .prepend(commercialRoofMaxPanelListener.middleware)
      .prepend(commercialSystemTrigger.middleware)
      .prepend(commercialPriceTrigger.middleware)
      .prepend(commercialSimulationTrigger.middleware)
      .prepend(residentialBookmarkListener.middleware)
      .prepend(commercialBookmarkListener.middleware)
      .prepend(residentialConfigLoadListener.middleware)
      .prepend(commercialConfigLoadListener.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { store };
