import { useRef } from "react";
import { useSelector } from "react-redux";
import { Tawk } from "../../../common/Tawk";
import { RootState } from "../../../store/config";
import { content } from "./content";

export const ContactMethods = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  const chatRef = useRef<any>(null);

  const startLiveChat = () => {
    chatRef?.current?.maximize();
  };

  return (
    <>
      <Tawk ref={chatRef} />
      <div>
        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div className="flex justify-center md:justify-end items-end relative p-16 bg-gray-100">
            <div className="max-w-md text-center ">
              <dt>
                <p className="text-3xl leading-10 font-semibold text-slate-900 ">
                  {content.card[0].p[locale]}
                  <span className="bg-yellow-300 text-slate-800">
                    {content.card[0].highlight[locale]}
                  </span>
                </p>
              </dt>
              <dd
                className="mt-2 text-xl ltr:tracking-wider text-blue-500 uppercase cursor-pointer"
                onClick={startLiveChat}
              >
                {content.card[0].action[locale]}
              </dd>
            </div>
          </div>
          <div className="flex justify-center md:justify-start items-end relative p-16 bg-white">
            <div className="max-w-md text-center ">
              <dt>
                <p className="text-3xl leading-10 font-semibold text-slate-900">
                  {content.card[1].p[locale]}
                  <span className="bg-yellow-300 text-slate-800">
                    {content.card[1].highlight[locale]}
                  </span>
                </p>
              </dt>
              <dd className="mt-2 text-xl ltr:tracking-wider text-blue-500 uppercase cursor-pointer">
                <a href="mailto:info@toptaqa.com">
                  {content.card[1].action[locale]}
                </a>
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </>
  );
};
