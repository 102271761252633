type Props = {
  size: number;
  className?: string;
};

export const CheckIcon = ({ size, className }: Props) => {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 100 100">
      <g
        id="44.-Check"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(2.000000, 2.000000)"
          stroke="#222F3E"
          strokeWidth="4"
        >
          <path
            d="M48,96 C74.509668,96 96,74.509668 96,48 C96,21.490332 74.509668,0 48,0 C21.490332,0 0,21.490332 0,48 C0,74.509668 21.490332,96 48,96 Z"
            id="Layer-1"
          ></path>
          <polyline
            id="Layer-2"
            points="27.7058857 47.0210276 42.0345786 61.4826208 67.9945661 35.4382535"
          ></polyline>
        </g>
      </g>
    </svg>
  );
};
