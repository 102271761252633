export const content = {
  h1: {
    en: "Our inspiration",
    ar: "إلهامنا",
    ku: "ئیلهام بخشمان بە",
  },
  h2: {
    en: "West Meets East",
    ar: "الغرب يلتقي الشرق",
    ku: "ڕۆژئاوا لەگەڵ ڕۆژهەڵات یەکدەگرێتەوە",
  },
  p: {
    en: "With roots in both Sweden and Iraq, we draw inspiration from both countries and cultures - a fusion of the best of two different worlds. Our quality and sustainability values are directly derived from Sweden while maintaining the Iraqi spirit of resourcefullnes and practicality.",
    ar: "مع جذورنا في كل من السويد والعراق ، نستمد الإلهام من كلا البلدين والثقافات - مزيج من أفضل ما في عالمين مختلفين. تُستمد قيم الجودة والاستدامة لدينا مباشرة من السويد مع الحفاظ على الروح العراقية المتمثلة في الموارد الكاملة والتطبيق العملي.",
    ku: "بە ڕەگ و ڕیشەی هەردوو وڵاتی سوید و عێراق، ئێمە ئیلهام لە هەردوو وڵات و کولتوورەکە وەردەگرین - تێکەڵەیەک لە باشترینەکانی دوو جیهانی جیاواز.  بەهاکانی کوالیتی و بەردەوامیمان ڕاستەوخۆ لە سویدەوە وەرگیراون لە هەمان کاتدا ڕۆحی عێراقی لە سەرچاوە و پراکتیکیدا دەپارێزین.",
  },
};
