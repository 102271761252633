import { useDispatch, useSelector } from "react-redux";
import wNumb from "wnumb";
import { AppDispatch, RootState } from "../../../../store/config";
import { setPanelQty } from "../../../../store/slices/cpq/commercial";
import { InfoModal } from "../../../../components/InfoModal";
import { Slider } from "../../../../components/Slider";
import { content } from "../content";

export const ConfigurationInputSection = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { locale, direction } = useSelector(
    (state: RootState) => state.localization
  );

  const { system, panelQty } = useSelector(
    (state: RootState) => state.cpq.commercial.config
  );

  const onQtyUpdate = (values: (string | number)[]) => {
    const updatedVal = Number(values[0]);

    dispatch(setPanelQty({ qty: updatedVal }));
  };

  const formatter_kwp = wNumb({ decimals: 0, suffix: " kWp", thousand: "," });

  return (
    <>
      <div className="mt-6 px-4 py-6 bg-gray-50 rounded-xl flex justify-between shadow-sm">
        <p>{content.configuration.labels.systemSize[locale]}</p>
        <p className="font-medium">{formatter_kwp.to(system.arraySizeW)}</p>
      </div>
      <div className="mt-6 px-4 py-6 bg-gray-50 rounded-xl overflow-hidden">
        {direction === "ltr" && (
          <Slider
            label={content.configuration.labels.input[locale]}
            value={panelQty}
            onUpdate={onQtyUpdate}
            decimals={0}
            options={{
              connect: "lower",
              direction: "ltr",
              start: panelQty,
              range: {
                min: [0],
                "25%": [10],
                "50%": [30],
                "75%": [100],
                max: [450],
              },
            }}
            suffix=" pcs"
          />
        )}
        {direction === "rtl" && (
          <Slider
            label={content.configuration.labels.input[locale]}
            value={panelQty}
            onUpdate={onQtyUpdate}
            decimals={0}
            options={{
              connect: "lower",
              direction: "rtl",
              start: panelQty,
              range: {
                min: [0],
                "25%": [10],
                "50%": [30],
                "75%": [100],
                max: [450],
              },
            }}
            suffix=" pcs"
          />
        )}
      </div>
      <InfoModal
        linkText={content.configuration.modal.labelBold[locale]}
        description={content.configuration.modal.labelRegular[locale]}
        title={content.configuration.modal.title[locale]}
        boxClassName="mt-5"
        img="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fassets%2Fpanels-rooftop.webp?alt=media&token=9b980968-5a82-4f2e-8423-bde22cb78d74"
        type="box"
      >
        <div className="p-6 md:px-20 md:pb-20">
          <p>{content.configuration.modal.p[0][locale]}</p>
          <p className="mt-3">{content.configuration.modal.p[1][locale]}</p>
        </div>
      </InfoModal>
    </>
  );
};
