import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../store/config";
import { IDireciton } from "../../../store/slices/localization";

type StyledProps = {
  direction: IDireciton;
};

const Styled = styled.h2<StyledProps>`
  font-size: 2rem;
  font-weight: 500;
  color: white;
  letter-spacing: ${(props) => (props.direction === "ltr" ? "0.1rem" : "0")};
`;

type Props = {
  children: string;
};

export const HeroH2 = ({ children }: Props) => {
  const direction = useSelector(
    (state: RootState) => state.localization.direction
  );

  return <Styled direction={direction}>{children}</Styled>;
};
