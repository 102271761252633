import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { IoFlash, IoLocationSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/config";
import { content } from "../content";

export const LocationVisualSection = () => {
  const { locale } = useSelector((state: RootState) => state.localization);
  const location = useSelector((state: RootState) => state.location.data);

  const { isLoaded } = useJsApiLoader({
    id: "d7d5f3673b6525d3",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
      ? process.env.REACT_APP_GOOGLE_MAP_API_KEY
      : "",
  });

  if (!location) return null;
  if (!process.env.REACT_APP_GOOGLE_MAP_API_KEY) return null;

  const mapProps = {
    center: {
      lat:
        location?.coordinates.latitude === null
          ? 1
          : location?.coordinates.latitude,
      lng:
        location?.coordinates.longitude === null
          ? 1
          : location?.coordinates.longitude,
    },
    zoom: 11,
  };

  const options: google.maps.MapOptions = {
    draggable: false,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    mapId: "d7d5f3673b6525d3",
  };
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  return (
    <div className="w-full h-full aspect-video rounded-2xl overflow-hidden relative bg-gray-50 shadow-sm z-[0]">
      {isLoaded && (
        <GoogleMap
          center={mapProps.center}
          zoom={mapProps.zoom}
          mapContainerStyle={containerStyle}
          options={options}
          mapContainerClassName="rounded-2xl overflow-hidden"
        ></GoogleMap>
      )}
      <div className="absolute p-2 md:p-4 flex justify-center items-center rounded left-6 bottom-10 right-6 text-xs md:text-sm text-white font-medium bg-black/30 backdrop-blur-xl max-w-md mx-auto">
        <IoLocationSharp size={24} />
        <p className="ml-2 capitalize">{`${location.city}, ${location.country}`}</p>
      </div>
    </div>
  );
};
