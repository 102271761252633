import { useSelector } from "react-redux";
import { RootState } from "../../../../store/config";

export const SegmentVisualSection = () => {
  const segment = useSelector(
    (state: RootState) => state.cpq.commercial.config.segment
  );

  let src = "";
  switch (segment) {
    case "commercial building":
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fcommercial.webp?alt=media&token=01c5ca82-36d1-4cc0-ba9e-ea692a8de009";
      break;
    case "industrial":
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Findustrial.webp?alt=media&token=92ab6744-6a69-40d2-88ee-f278c12802c5";
      break;
    case "warehouse":
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fwarehouse.webp?alt=media&token=0db45154-4b89-4684-a4cb-926b789cd1f0";
      break;
    case "governmental":
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fgovernmental.webp?alt=media&token=80c7aa38-e7e1-40a2-b3fc-a3031c702b22";
      break;
    case "agriculture":
      src =
        "https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2F1080-608%2Fagriculture.webp?alt=media&token=25fdcce0-8250-4920-b5fc-906ed828a25c";
      break;
    default:
      break;
  }
  return (
    <img
      alt=""
      className="w-full h-full aspect-video rounded-2xl object-cover shadow"
      src={src}
    />
  );
};
