import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/config";
import {
  IRoofSlop,
  setRoofSlope,
} from "../../../../store/slices/cpq/residential";
import { InfoModal } from "../../../../components/InfoModal";
import { ProductSelect } from "../../../../components/ProductSelect";
import { content } from "../content";

export const RoofSlopeInputSection = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { locale } = useSelector((state: RootState) => state.localization);

  const roofSlope = useSelector(
    (state: RootState) => state.cpq.residential.config.roofSlope
  );

  const handleRoofSlopeChange = (values: IRoofSlop[]) => {
    const updatedVal = values[0];
    dispatch(setRoofSlope({ slope: updatedVal }));
  };

  return (
    <>
      <ProductSelect
        title={content.roofSlope.labels.flat[locale]}
        subTitle={""}
        selected={roofSlope === "flat"}
        onClick={() => handleRoofSlopeChange(["flat"])}
        className="mt-6"
      />
      <ProductSelect
        title={content.roofSlope.labels.tilted[locale]}
        subTitle={""}
        selected={roofSlope === "tilted"}
        onClick={() => handleRoofSlopeChange(["tilted"])}
        className="mt-5"
      />
      <InfoModal
        linkText={content.roofSlope.modal.labelBold[locale]}
        description={content.roofSlope.modal.labelRegular[locale]}
        title={content.roofSlope.modal.title[locale]}
        boxClassName="mt-5"
        img="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Fstock%2Fiphone-measure-app-icon.jpeg?alt=media&token=1a4ea769-d09d-4f38-8882-f5f46256d6a6"
        type="box"
      >
        <div className="p-6 md:px-20 md:pb-20">
          <p>{content.roofSlope.modal.p[0][locale]}</p>
          <p className="mt-3">{content.roofSlope.modal.p[1][locale]}</p>
        </div>
      </InfoModal>
    </>
  );
};
