export const content = {
  products: {
    en: "Products",
    ar: "منتجات",
    ku: "بەرھەم",
  },
  services: {
    en: "Services",
    ar: "خدمات",
    ku: "دەربارەی ئێمە",
  },
  abuotus: {
    en: "About Us",
    ar: "من نحن",
    ku: "دەربارەی ئێمە",
  },
  support: {
    en: "Support",
    ar: "الدعم",
    ku: "پشتگیری",
  },
  configure: {
    en: "Configure Now",
    ar: "إبدأ الآن",
    ku: "ئێستا ڕێکبخە",
  },
};
