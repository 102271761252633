import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../config";
import {
  setCommercialPanel,
  setPanelQty,
  setSystem,
} from "../slices/cpq/commercial";

// Create the middleware instance and methods
export const commercialSystemTrigger = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
commercialSystemTrigger.startListening({
  matcher: isAnyOf(setCommercialPanel, setPanelQty),
  effect: (action, listenerApi) => {
    // Can cancel other running instances
    listenerApi.cancelActiveListeners();

    const state = listenerApi.getState() as RootState;

    const supplyVoltage = state.location.data?.supplyVoltage;
    const panel = state.cpq.commercial.config.panel;
    const panelQty = state.cpq.commercial.config.panelQty;

    if (supplyVoltage && panel && panelQty) {
      // Array Size
      const panelWatt = panel.maxPowerWattage;
      const arraySizeW = panelQty * panelWatt;

      // First panel in the offering list
      const system = {
        panelWatt: panel.maxPowerWattage,

        arraySizeW: arraySizeW,
      };

      listenerApi.dispatch(setSystem({ system }));
    }
  },
});
