import DataSet from "@antv/data-set";
import { IHourly } from "../../types/power/hourly";
import { getAzimuth } from "../solar-geometry/get.azimuth";
import { getDeclination } from "../solar-geometry/get.declination";
import { getElevation } from "../solar-geometry/get.elevation";
import { getEoT } from "../solar-geometry/get.EoT";
import { getHRA } from "../solar-geometry/get.HRA";
import { getLST } from "../solar-geometry/get.LST";
import { getLSTM } from "../solar-geometry/get.LSTM";
import { getTC } from "../solar-geometry/get.TC";
import { getSModule } from "../solar-insulation/get.sModule";

export const simulationDataFromHourly = (
  hourly: IHourly[],
  panelWatt: number,
  longitude: number,
  latitude: number,
  supplyVoltage: number,
  moduleTilt: number,
  moduleOrientation: number
) => {
  // Create data set
  const ds = new DataSet();
  const dv = ds.createView();
  dv.source(hourly);
  dv.transform({
    type: "map",
    callback(row: IHourly, idx) {
      if (!panelWatt) return { ...row };
      if (!longitude) return { ...row };
      if (!latitude) return { ...row };
      if (!supplyVoltage) return { ...row };

      // Tilts and angle
      const LT = row.hour;
      const LSTM = getLSTM(3);
      const EoT = getEoT(row.dayOfYear);
      const TC = getTC(longitude, LSTM, EoT);
      const LST = getLST(LT, TC);
      const declination = getDeclination(row.dayOfYear);
      const HRA = getHRA(longitude, row.dayOfYear, LT);

      const elevationAngle = getElevation(latitude, HRA, declination);
      const solarAzimuth = getAzimuth(
        declination,
        latitude,
        elevationAngle,
        HRA,
        LST
      );
      const s_incident = row.dni + row.dif;
      const s_module = getSModule(
        s_incident,
        elevationAngle,
        moduleTilt,
        solarAzimuth,
        moduleOrientation
      );

      const output_ampere = (s_module * panelWatt) / (1000 * supplyVoltage);

      return {
        date: row.date,
        datetime: row.date,
        year: row.year,
        month: row.month,
        hour: row.hour,
        output: output_ampere,
      };
    },
  });

  return {
    rows: [...dv.rows],
    avgDailyOutputPerPanel: dv.sum("output") / 365,
  };
};
