export const content = {
  h1: {
    en: "How it works",
    ar: "كيف تعمل",
    ku: "چۆن کاردەکات",
  },
  h2: {
    en: "We will support you throughout the process",
    ar: "سوف ندعمك طوال العملية",
    ku: "ئێمە بە درێژایی پرۆسەکە پشتگیریتان دەکەین",
  },
  firstStepTitle: {
    en: "Customize Solution",
    ar: "تخصيص الحل",
    ku: "چارەسەرەکە بە خواستی خۆت دابنێ",
  },
  firstStepContent: {
    en: "Our online configurators will guide your through the process of customizing the solar system to your needs.",
    ar: "ستوجهك أدوات التهيئة عبر الإنترنت خلال عملية تخصيص النظام الشمسي وفقًا لاحتياجاتك.",
    ku: "ڕێکخەرەکانی ئۆنلاینمان ڕێنماییتان دەکەن لە پرۆسەی ڕێکخستنی وزە ی خۆر بەپێی پێداویستییەکانتان.",
  },
  secondStepTitle: {
    en: "Support",
    ar: "الدعم",
    ku: "يارمە تيدان",
  },
  secondStepContent: {
    en: "Our solar experts will contact you to go through your solution with you. If everything looks fine you will get a firm quote for the solution.",
    ar: "سيتصل بك خبراء الطاقة الشمسية لدينا لمعرفة الحل معك. إذا كان كل شيء يبدو جيدًا ، فستحصل على عرض أسعار ثابت للحل.",
    ku: "شارەزایانمان لە وزەی خۆردا پەیوەندیت پێوە دەکەن بۆ ئەوەی لەگەڵتدا بن لە کاتی چارەسەری کئشە کانت.  ئەگەر هەموو شتێک بە باشی بەڕئوە بچئت ئەوا کۆتێکی پتەو بۆ چارەسەرەکە بەدەست دەهێنیت.",
  },
  thirdStepTitle: {
    en: "Payment",
    ar: "دفع",
    ku: "پارەدان",
  },
  thirdStepContent: {
    en: "We will help you to find a suitable payment option to finance your solar system.",
    ar: "سنساعدك في العثور على خيار دفع مناسب لتمويل نظامك الشمسي.",
    ku: "ئێمە یارمەتیت دەدەین بۆ دۆزینەوەی بژاردەیەکی گونجاو بۆ پارەدان بۆ دابینکردنی دارایی وزەی تيشکی خۆر",
  },
  forthStepTitle: {
    en: "Installation",
    ar: "تثبيت",
    ku: "جيگيرکردن",
  },
  forthStepContent: {
    en: "Our installers will come and install your system. The installation takes a day or two after which your system is ready to go.",
    ar: "سيأتي المثبتون لدينا ويقومون بتثبيت نظامك. يستغرق التثبيت يومًا أو يومين ، وبعد ذلك يصبح نظامك جاهزًا للعمل.",
    ku: "دامەزرێنەرەکانمان دێن و سیستەمەکەت دادەمەزرێنن.  دامەزراندنەکە یەک دوو ڕۆژ دەخایەنێت و دوای ئەوە سیستەمەکەت ئامادەیە بۆ کارپيکردن.",
  },
  fifthStepTitle: {
    en: "Done",
    ar: "فعله",
    ku: "جيبە جئکرا",
  },
  fifthStepContent: {
    en: "Enjoy your solar system and electricity. Our installers will guide you on how to track your electricity production through our apps.",
    ar: "استمتع بنظامك الشمسي والكهرباء. سيرشدك عامل التركيب لدينا إلى كيفية تتبع إنتاج الكهرباء من خلال تطبيقاتنا.",
    ku: "چێژ لە وزە ی خۆر و کارەباکە ت وەربگرە.  دامەزرێنەرانمان ڕێنماییتان دەکەن لەسەر چۆنیەتی بەدواداچوون بۆ بەرهەمهێنانی کارەباتان لە ڕێگەی ئەپەکانمانەوە.",
  },
};
