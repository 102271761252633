import { useSelector } from "react-redux";
import { RootState } from "../../../store/config";
import { HeroBackdrop } from "./backdrop.component";
import { content } from "./content";
import { HeroH1 } from "./h1.component";
import { HeroH2 } from "./h2.component";

export const AboutHero = () => {
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <div className="h-screen overflow-hidden relative overscroll-none flex justify-center items-center">
      <img
        className="absolute w-full h-full object-cover"
        src="https://firebasestorage.googleapis.com/v0/b/toptaqa-6c44d.appspot.com/o/images%2Ferbil-city-center.jpg?alt=media&token=fc12b5d2-df39-42f1-8bd0-7f34b91007a8"
        alt="Iraq"
      />
      <HeroBackdrop />
      <section className="w-full md:flex-grow md:flex justify-center px-6 xl:px-0">
        <div className="w-full h-full max-w-screen-lg justify-center flex flex-grow flex-col gap-4 items-start">
          <div className="ml-2 relative flex flex-col gap-2 max-w-screen-md">
            <HeroH1>{content.h1[locale]}</HeroH1>
            <HeroH2>{content.h2[locale]}</HeroH2>
          </div>
        </div>
      </section>
    </div>
  );
};
