import { router } from "./routes";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store/config";
import { RouterProvider } from "react-router-dom";
import { switchLocale } from "./store/slices/localization";
const GA4_ID = "G-5G6FDDP26J";
ReactGA.initialize(GA4_ID);

export const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { locale, direction } = useSelector(
    (state: RootState) => state.localization
  );

  useEffect(() => {
    const acceptedLangs = ["en", "ar", "ku"];
    const lang = window.location.pathname.slice(1, 3);

    if (!acceptedLangs.includes(lang)) {
      window.location.href = "/en";
    }

    if (lang === locale) {
      // Same lang and locale
      return;
    }
    // Set Language
    if (lang === "ar" && locale !== "ar") {
      dispatch(switchLocale("ar"));
    } else if (lang === "kr" && locale !== "ku") {
      dispatch(switchLocale("ku"));
    }
  }, []);

  useEffect(() => {
    const html = document.documentElement as HTMLElement;
    html.dir = direction;
    html.lang = locale;
    html.style.fontFamily =
      direction === "ltr" ? "Montserrat, system-ui" : "Nunito, system-ui";
  }, [locale, direction]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return <RouterProvider router={router} />;
};
