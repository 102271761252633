import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { RootState } from "../../store/config";
import { content } from "./content";
import { Navlink } from "./navlink.component";

type Props = {
  transparent: boolean | undefined;
  className?: string;
};
export const NavDesktop: React.FC<Props> = ({
  transparent = false,
  className = "",
}) => {
  const { lang } = useParams();
  const { locale } = useSelector((state: RootState) => state.localization);

  return (
    <nav className={`${className} relative`}>
      <div className="flex items-center">
        <Navlink
          href={`/${lang}/products`}
          className={`px-4 py-2 mx-2 opacity-90 hover:opacity-100 ${
            transparent ? "text-white" : "text-black"
          }`}
        >
          {content.products[locale]}
        </Navlink>
        <Navlink
          href={`/${lang}/services`}
          className={`px-4 py-2 mx-2 opacity-90 hover:opacity-100 ${
            transparent ? "text-white" : "text-black"
          }`}
        >
          {content.services[locale]}
        </Navlink>
        <Navlink
          href={`/${lang}/about`}
          className={`px-4 py-2 mx-2 opacity-90 hover:opacity-100 ${
            transparent ? "text-white" : "text-black"
          }`}
        >
          {content.abuotus[locale]}
        </Navlink>
        <Navlink
          href={`/${lang}/support`}
          className={`px-4 py-2 mx-2 opacity-90 hover:opacity-100 ${
            transparent ? "text-white" : "text-black"
          }`}
        >
          {content.support[locale]}
        </Navlink>
        <Link
          to={`/${lang}/cpq/residential`}
          className="mx-2 px-4 py-2.5 flex justify-center items-center bg-blue-600 text-white rounded-full hover:bg-blue-500 hover:text-white text-sm uppercase ltr:tracking-wide"
        >
          {content.configure[locale]}
        </Link>
      </div>
    </nav>
  );
};
