import { getEoT } from "./get.EoT";
import { getLST } from "./get.LST";
import { getLSTM } from "./get.LSTM";
import { getTC } from "./get.TC";

// Hour Angle (HRA)
export const getHRA = (longitude: number, dayOfYear: number, LT: number) => {
  const LSTM = getLSTM(3);
  const EoT = getEoT(dayOfYear);
  const TC = getTC(longitude, LSTM, EoT);
  const LST = getLST(LT, TC);
  const HRA = 15 * (LST - 12);

  return HRA;
};
