type Props = {
  size: number;
  className?: string;
};

export const SolarSystemIcon = ({ size, className }: Props) => {
  return (
    <svg viewBox="0 0 64 64" width={size} height={size} className={className}>
      <g id="Outline">
        <path d="M55.64,32.23A1.06,1.06,0,0,0,55,32H52V27h1a1,1,0,0,0,1-1V22.62c1.43-1.36.48-3.3,1.85-4.59,4.26-3.87,1.73-11.44-4-12A7,7,0,0,0,44,13a7,7,0,0,0,2.22,5.11A2.55,2.55,0,0,1,47,20a4,4,0,0,0,1,2.62V26a1,1,0,0,0,1,1h1v5H31a1,1,0,0,0-.71.29l-5,5A1,1,0,0,0,25,38h0v4h2V39H48V60H41v2H61a1,1,0,0,0,1-1V38a1,1,0,0,0-.36-.77ZM52,25H50V23h2Zm1-5a1.92,1.92,0,0,1-.27,1h-.32l.75-2.14A4.36,4.36,0,0,0,53,20Zm-5.42-3.35A5,5,0,0,1,51.6,8c4.08.42,5.91,5.8,2.87,8.56a4.66,4.66,0,0,0-1,1.45s1.69-4.77,1.55-4.62a2.44,2.44,0,0,0-3.77-2,.43.43,0,0,1-.46,0,2.44,2.44,0,0,0-3.77,2c-.15-.05,1.54,4.48,1.54,4.56A4.23,4.23,0,0,0,47.58,16.65ZM49.27,21c-.39-.6-.2-1.37-.39-2l.71,2ZM49,13.3a.42.42,0,0,1,.64-.23,2.38,2.38,0,0,0,2.68,0,.41.41,0,0,1,.64.23L51,19ZM48.64,37H28.41l3-3H52.24ZM60,60H50V38.47l5-4.17,5,4.17Z" />
        <rect x="50" y="2" width="2" height="2" />
        <rect
          x="42.93"
          y="4.93"
          width="2"
          height="2"
          transform="translate(8.68 32.81) rotate(-45.01)"
        />
        <rect x="40" y="12" width="2" height="2" />
        <rect
          x="42.93"
          y="19.07"
          width="2"
          height="2"
          transform="translate(-1.33 36.94) rotate(-45)"
        />
        <rect
          x="57.07"
          y="19.07"
          width="2"
          height="2"
          transform="translate(2.82 46.95) rotate(-45.01)"
        />
        <rect x="60" y="12" width="2" height="2" />
        <rect
          x="57.07"
          y="4.93"
          width="2"
          height="2"
          transform="translate(12.82 42.8) rotate(-45)"
        />
        <path d="M16,8h4a1,1,0,0,0,.89-1.45l-2-4a1,1,0,0,0-1.78,0l-2,4A1,1,0,0,0,16,8Zm2-2.76.38.76h-.76Z" />
        <path d="M7.2,13.23a1,1,0,0,0,1.69.19l2.46-3.15a1,1,0,0,0,.15-1,1,1,0,0,0-.74-.63L6.38,7.78a1,1,0,0,0-1.1,1.41Zm1.58-2.92-.47.6-.37-.77Z" />
        <path d="M3.23,22.6l4.36,1a1,1,0,0,0,1.19-1.22l-1-3.88A1,1,0,0,0,6.19,18L2.8,20.87A1,1,0,0,0,3.23,22.6Zm3-2.07.19.74-.83-.18Z" />
        <path d="M16,29.63A1,1,0,0,0,15.76,28l-3.65-1.63a1,1,0,0,0-1,.09,1,1,0,0,0-.44.87l.2,4.47a1,1,0,0,0,1.63.73Zm-2.52-.51-.66.54,0-.85Z" />
        <path d="M25.51,32.13a1,1,0,0,0,.53-.91l-.11-4.47a1,1,0,0,0-1.47-.86l-3.53,1.88a1,1,0,0,0-.11,1.7l3.64,2.59A1,1,0,0,0,25.51,32.13ZM23.3,28.77l.67-.35,0,.85Z" />
        <path d="M28.81,17a1,1,0,0,0-.61.76l-.7,3.94a1,1,0,0,0,1.27,1.13l4.29-1.28a1,1,0,0,0,.31-1.76l-3.59-2.66A1,1,0,0,0,28.81,17Zm1.76,3.21-.82.24.13-.75Z" />
        <path d="M26.77,12.79a1,1,0,0,0,1.67-.3l1.64-4.16A1,1,0,0,0,28.88,7L24.57,8.19a1,1,0,0,0-.48,1.63Zm.72-3.33-.32.79-.51-.56Z" />
        <path d="M18,26a8,8,0,0,0,8-8C25.6,7.4,10.4,7.4,10,18A8,8,0,0,0,18,26Zm0-14c7.93.25,7.93,11.75,0,12C10.07,23.75,10.07,12.25,18,12Z" />
        <path d="M51.06,49.34A1,1,0,0,0,52,50h3.24l-3.88,3.23,1.28,1.54,6-5A1,1,0,0,0,58,48H54.76l3.88-3.23-1.28-1.54-6,5A1,1,0,0,0,51.06,49.34Z" />
        <path d="M13,44H3a1,1,0,0,0-1,1V55a1,1,0,0,0,1,1H7v4H5v2h6V60H9V56h4a1,1,0,0,0,1-1V45A1,1,0,0,0,13,44ZM7,54H4V51H7Zm0-5H4V46H7Zm5,5H9V51h3Zm0-5H9V46h3Z" />
        <path d="M27,44H17a1,1,0,0,0-1,1V55a1,1,0,0,0,1,1h4v4H19v2h6V60H23V56h4a1,1,0,0,0,1-1V45A1,1,0,0,0,27,44ZM21,54H18V51h3Zm0-5H18V46h3Zm5,5H23V51h3Zm0-5H23V46h3Z" />
        <path d="M37,56h4a1,1,0,0,0,1-1V45a1,1,0,0,0-1-1H31a1,1,0,0,0-1,1V55a1,1,0,0,0,1,1h4v4H33v2h6V60H37Zm0-10h3v3H37Zm0,5h3v3H37Zm-2,3H32V51h3Zm0-5H32V46h3Z" />
        <rect x="17" y="32" width="2" height="2" />
        <rect x="17" y="36" width="2" height="2" />
        <rect x="17" y="40" width="2" height="2" />
        <rect
          x="19.92"
          y="31.76"
          width="2"
          height="2"
          transform="translate(-9.94 9.13) rotate(-20.01)"
        />
        <rect
          x="21.29"
          y="35.52"
          width="2"
          height="2"
          transform="translate(-11.15 9.83) rotate(-20.01)"
        />
        <rect
          x="22.66"
          y="39.28"
          width="2"
          height="2"
          transform="translate(-12.35 10.52) rotate(-20.01)"
        />
        <rect
          x="14.08"
          y="31.76"
          width="2"
          height="2"
          transform="translate(-20.86 35.72) rotate(-69.99)"
        />
        <rect
          x="12.71"
          y="35.52"
          width="2"
          height="2"
          transform="translate(-25.29 36.92) rotate(-70.01)"
        />
        <rect
          x="11.34"
          y="39.28"
          width="2"
          height="2"
          transform="translate(-29.73 38.09) rotate(-69.99)"
        />
      </g>
    </svg>
  );
};
